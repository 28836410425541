import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <p>close</p>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

export default function(props){
  const listing = props.userPack.myListing
  const listings=props.userPack.listings
  const title=listing.vehicle&&listing.vehicle.brand&&listing.vehicle.model&&listing.vehicle.year?listing.vehicle.brand+" "+listing.vehicle.model+" "+listing.vehicle.year:"Incompleto"

  const handleClone=()=>{
    props.handleOpen("cloneSelection",listing,listings)
  }
  const handleNew=()=>{
    console.log("Clone Dialog",listings)
    props.handleOpen("newListing",listing,listings)
  }
    return(
        <>
          <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Crear una publicación!"}</DialogTitle>
            <DialogContent>
              <Grid container justify="center">
                <Grid item xs={"auto"}>
                  <Button onClick={handleClone} color="secondary" value={"clonar"}>
                    Clonar
                  </Button>
                  <Button onClick={handleNew} color="secondary" value={"nueva"}>
                    Nueva
                  </Button>

                </Grid>
              </Grid>

            </DialogContent>
          </Dialog>
        </>
    );
}