import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router";

//hsla(203, 100%, 0%, 0.84)
const useStyles = makeStyles(theme => ({
    footer:{
      backgroundColor:"#231f20",
      color:"white",
      padding:"15px",
      zIndex:"2000"
    },
    footerCell:{
      borderStyle:"inline",
      borderWidth:"1px"
    }
}));

const BigBlock = (props)=>{
  const handleHelp = (e)=>{
    e.preventDefault()
    if(!props.userPack.user.is_superuser){props.userPack.getHelp()}
    
  }
  const handleList = (e)=>{
    e.preventDefault()
    props.userPack.dialog.handleOpen("list")
  }
  const handleBook = ()=>{
    window.location.href = '/listings'
  }
  const handleAbout = (e)=>{
    e.preventDefault()
  }
  const handleOwnerTools = (e)=>{
    e.preventDefault()
  }
  return(
    <>
     <Grid container>
  
        <Grid item xs={12} md={4}>
          <Grid container>
           <Grid item xs={12}>
              <Typography variant="h5" className="signatureHeader">
              Carly
                </Typography>
              </Grid>
            
            <Grid item xs={12} >
            <a href="" style={{color:"white"}} onClick={handleAbout}>
              <Typography className="signatureItem" variant="body1">
                Acerca de Carly
                </Typography>
                </a>
            </Grid>
            <Grid item xs={12}>
            <a href="" style={{color:"white"}} onClick={handleHelp} >
              <Typography  className="signatureItem" variant="body1">
              Ayuda
                </Typography>
                </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container>
           <Grid item xs={12}>

              <Typography variant="h5" className="signatureHeader">
                Explora
                </Typography>
              </Grid>
            
            <Grid item xs={12} >
            <Link to='/listings' color='inherit' style={{color:"white !important"}}>
              <p className="signatureItem white" variant="body1" style={{color:"white !important"}}>
                Reserva un carro
                </p>
                </Link>
            </Grid>
            <Grid item xs={12}>
            <a href="" style={{color:"white"}} onClick={handleHelp}>
              <Typography className="signatureItem" variant="body1">
                Pide ayuda
                </Typography>
                </a>
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} md={4}>
          <Grid container>
           <Grid item xs={12}>
              <Typography variant="h5" className="signatureHeader">
                Alojamiento
                </Typography>
              </Grid>
            
            <Grid item xs={12} >
            <a href="" style={{color:"white"}} onClick={handleList}>
              <Typography className="signatureItem" variant="body1">
                Publica tu carro
                </Typography>
                </a>
            </Grid>
            <Grid item xs={12}>
            <a href="" style={{color:"white"}} onClick={handleOwnerTools}>
              <Typography className="signatureItem" variant="body1">
                Herramientas para dueños
                </Typography>
                </a>
            </Grid>
          </Grid>
        </Grid>


      </Grid>
    </>
  )
}
const Signature = (props) =>{
    const classes = useStyles(props);
    const handleMedia=(e)=>{
      e.preventDefault()
    }
    return(
      <footer className={classes.footer}>
              <Grid container alignItems="center" justify="center" spacing={6}>
                <Grid item xs={6} md={8}>
                  <BigBlock userPack={props.userPack}/>
                </Grid>
                  <Grid item xs={6} md={4}>
                    <Grid container space={2}>
                      <Grid item xs={2}>
                      <a href="" style={{color:"white"}} onClick={handleMedia}>
                      <FacebookIcon/>
                      </a>
                      </Grid>
                      <Grid item xs={2}>
                      <a href="" style={{color:"white"}} onClick={handleMedia}>
                      <TwitterIcon/>
                      </a>
                        </Grid>
                        <Grid item xs={2}>
                        <a href="" style={{color:"white"}} onClick={handleMedia}>
                        <InstagramIcon/>
                        </a>
                        </Grid>
                        <Grid item xs={2}>
                        <a href="" style={{color:"white"}} onClick={handleMedia}>
                        <YouTubeIcon/>
                        </a>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                <Typography variant="caption">
                  ©2020 Carly MarketPlace
                  </Typography>
                </Grid>
  
            </Grid>
      </footer>
    );
};

export default Signature;