import React from 'react';
import DateDownShift from './DateDownShift';
import MainDownShift from './MainDownShift';
import Filters from './Filters';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from "@reach/router";
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FloatingBox from '../maincomponents/FloatingBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chat from '../maincomponents/Chat';
import CurrencyItem from '../maincomponents/CurrencyItem';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    backgroundColor:"transparent",
    color:"black",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    color: 'inherit',
    marginTop:"9px"
  },
 
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleList = ()=>{
    props.userPack.dialog.handleOpen("list")
  }
  const handleLogin=()=>{
    props.userPack.dialog.handleOpen("login")
  }
  const handleHelp=()=>{
    handleMenuClose()
    props.userPack.getHelp()
    //props.userPack.dialog.handleOpen("getVerified")
  }
  const getVerified=()=>{
    handleMenuClose()
    props.userPack.dialog.handleOpen("getVerified")
  }
  const handleRegister=()=>{
    props.userPack.loginElements.setRedirectUri('https://'+window.location.host)
    props.userPack.dialog.handleOpen("register")
  }
  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const handleLogout = () => {
    handleClose()
    props.userPack.logout();
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorChat, setAnchorChat] = React.useState(null);
  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const handleChat = event => {
    setAnchorChat(event.currentTarget);
  };
  const handleNotification = event => {
    setAnchorNotification(event.currentTarget);
  };
  const handleClick = event => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
    setAnchorNotification(null);
    setAnchorChat(null);
  };
  let notifications
  let notificationAmount = 0
  let chatAmount = 0
  let supportChats = []
  if(props.userPack.user.supportChats){
    supportChats=props.userPack.user.supportChats
    // console.log("new support chat",supportChats)
  }
  if(props.userPack.supportChats){
    supportChats=supportChats.concat(props.userPack.supportChats)
    // console.log("new support chat",supportChats)
  }
  if(props.userPack.notifications && props.userPack.notifications.length>0){
    notifications = []
    let selfNotifications =[]
    let notificationsRaw = props.userPack.notifications.filter(c=>!c.closed)
    notificationsRaw.forEach(notification => {
      if(notification.customer.id==notification.listing.owner.id){
        console.log("encontrado varon")
        notification.self = true
        selfNotifications.forEach(selfNotification => {
          if(notification.id==selfNotification){
            notifications.push(notification)
          }
        });
        selfNotifications.push(notification.id)
        
      }
    });
    notificationAmount = notifications.length
    //console.log("notifications test",props.userPack.userId,props.userPack.notifications[0].messages[props.userPack.notifications.length-1].sender )
    chatAmount = props.userPack.notifications.filter(c=>
      (c.messages[c.messages.length-1]&&c.messages[c.messages.length-1].sender!=props.userPack.userId)
    ).length
    //console.log(chatAmount,"chatAmount")
  }else{
    notifications = []
  }
  const handleCurrency=(value)=>{
    props.userPack.setCurrency(value)
    setAnchorEl2(null)
  }
  const handleSupportChat=(e)=>{
    return
  }

  const open2 = Boolean(anchorEl2);
  const id = open2 ? 'simple-popover' : undefined;

  const openChat = Boolean(anchorChat);
  const idChat = openChat ? 'simple-popover' : undefined;

  const openNotification = Boolean(anchorNotification);
  const idNotification = openNotification ? 'simple-popover' : undefined;

  const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;

  const menuId = 'search-account-menu';
  const renderMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ><Link to="/dashboard" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      </Link>
      <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Link>
      <MenuItem onClick={getVerified}>Mi cuenta</MenuItem>
      {props.userPack.logged?<MenuItem onClick={handleHelp}>Ayuda</MenuItem>:null}
      {props.userPack.logged?<MenuItem onClick={handleLogout}>Logout</MenuItem>:null}
      
      
    </Popover>
  );
  const CurrencySelection = (props)=>{
    return(<CurrencyItem handleCurrency={handleCurrency} symbol={props.symbol} label={props.label}/>)
  }
  const ChatItem = (props)=>{
    const dateFormat = require('dateformat');
    let startDate 
    let endDate 
    let empty 
    let name
    let pic
    let booking
    name="CARLY SUPPORT"
    pic="https://sdtultrasound.com/images/support-icon.png"
    const openChat=()=>{
      try{
        props.handleClose()
        props.userPack.switchChat({active:true,dialogId:props.booking.id,type:props.type})}
        
      catch(e){console.log(e)}
    }
    const aceptar =()=>{
      props.userPack.bookingAPI(props.booking.id,{"type":"notification","action":"accepted"})
    }
    

    if(props.type==="support"){
      if(props.booking.supportChat){
        booking = props.booking.supportChat
      } else{
        booking = props.booking
      }
      const messages = props.booking.supportChat?props.booking.supportChat.messages:props.booking.messages
      empty= messages.length===0
      return(<>
        <Grid container onClick={openChat}>

        
        <Grid item xs="auto">
        <Avatar alt="Remy Sharp" src={pic} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
        </Grid>
        <Grid item xs="auto" style={{width:"220px"}} >
        <p><span style={{fontWeight:"bold"}}>{name}</span></p>
        {empty?
          <p>Pregúntanos! Estamos aquí para ti</p>:
          <p>{messages[messages.length-1].text}</p>
        
          }
        </Grid>
        <Grid container justify="center" style={{marginTop:"8px"}}>
          <Grid item xs="auto">
          </Grid>
        </Grid>
        </Grid>
</>)
    }else{
      startDate = new Date(props.booking.startDate);
      endDate = new Date(props.booking.endDate);
      startDate = dateFormat(startDate, "mmmm dS");
      endDate = dateFormat(endDate, "mmmm dS");
      empty= props.booking.messages.length===0
      const isOwner = props.booking.listing.owner.id===props.userPack.user.id
      try{
        if(isOwner){
          name = props.booking.customer.first_name+" "+props.booking.customer.last_name
          pic=props.booking.customer.profilePicture.url
        }else{
          name = props.booking.listing.owner.first_name+" "+props.booking.listing.owner.last_name
          pic=props.booking.listing.owner.profilePicture.url
        }       
      }catch (exception){
        console.log("exceotiub")
        pic="https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"
      }
    return(<>
            <Grid container onClick={openChat}>

            
            <Grid item xs="auto">
            <Avatar alt="Remy Sharp" src={pic} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
            </Grid>
            <Grid item xs="auto" style={{width:"220px"}} >
            <p><span style={{fontWeight:"bold"}}>{name}</span></p>
            {empty?
              <p>Inicia una conversacion con {isOwner?"tu cliente":"el dueño"}</p>:
              <p>{props.booking.messages[props.booking.messages.length-1].text}</p>
            
              }
            </Grid>
            <Grid container justify="center" style={{marginTop:"8px"}}>
              <Grid item xs="auto">
              </Grid>
            </Grid>
            </Grid>
    </>)
    }
  }
  const NotificationItem = (props)=>{
    const [confirmation,setConfirmation]=React.useState("")
    const handleConfirmation=(e)=>{
      setConfirmation(e.target.value)
    }
    const handleEnter=(e)=>{
      if(e.key==="Enter"){
        sendConfirmation()
      }
    }
    const dateFormat = require('dateformat');
    let startDate = new Date(props.booking.startDate);
    let endDate = new Date(props.booking.endDate);
    startDate = dateFormat(startDate, "mmmm dS");
    endDate = dateFormat(endDate, "mmmm dS");
    const sendConfirmation =()=>{
      props.userPack.bookingAPI(props.booking.id,{"action":"payment","message":confirmation})
    }
    const aceptar =()=>{
      props.userPack.bookingAPI(props.booking.id,{"action":"accepted","message":"qloq wa waw wawa"})
    }
    const rechazar =()=>{
      props.userPack.bookingAPI(props.booking.id,{"action":"rechazar","message":"qloq wa waw wawa"})
    }
    const received =()=>{
      props.userPack.bookingAPI(props.booking.id,{"action":"received","message":"qloq wa waw wawa"})
    }
    const decline =()=>{
      props.userPack.bookingAPI(props.booking.id,{"action":"decline","message":"qloq wa waw wawa"})
    }
    const id = props.booking.id
    let codeOwner = "awaitingPayment"
    let codeCustomer = "awaitingPayment"
    let confirmationNumber
    if(props.booking.closed){
      return(
        <>
        </>
      )
    }
    if(!props.booking.acceptedByOwner){
      codeOwner = "needAcceptance"
    }else if(props.booking.delivered){
      codeOwner = "delivered"
      codeCustomer = "enjoy"
    }
    if(props.booking.payments){
      props.booking.payments.forEach(payment => {
        if(payment.confirmed){
          codeCustomer = "pago"
          codeOwner = "pago"
        }
        else if(payment.confirmationNumber && !payment.declined){
          confirmationNumber=payment.confirmationNumber
          codeCustomer = "awaitingOwnerPaymentConfirmation"
          codeOwner = "awaitingOwnerPaymentConfirmation"
        }
      });
    }
    if((props.booking.listing.owner.id===props.userPack.user.id)){


      switch(codeOwner){
        case 'pago':
          return(
            <>
            <Grid container key={props.booking.id}>

            
            <Grid item xs="auto" key={props.booking.id}>
            <Avatar key={props.booking.id} alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
            </Grid>
            <Grid key={props.booking.id} item xs="auto" style={{width:"220px"}}>
            <p key={props.booking.id}> <span style={{fontWeight:"bold"}} key={props.booking.id}>Felicidades. Su reservación ha sido exitosamente confirmada</span></p>
            </Grid>
            <Grid key={props.booking.id} container justify="center" style={{marginTop:"8px"}}>
              <Grid key={props.booking.id} item xs="auto">
              </Grid>
            </Grid>
            </Grid>
          </>)
        case 'awaitingPayment':
          return(
            <>
            <Grid container >

            
            <Grid item xs="auto">
            <Avatar alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
            </Grid>
            <Grid item xs="auto" style={{width:"220px"}}>
            <p>Esperando confirmación de pago de <span style={{fontWeight:"bold"}}>{props.booking.customer.first_name+" "+props.booking.customer.last_name}</span></p>
            <CircularProgress color="secondary" style={{marginTop:"10px",marginBottom:"10px"}}/>
            <div style={{marginTop:"15px !important"}}><p ><span style={{fontWeight:"bold"}}>TOTAL: </span><span style={{fontWeight:"bold",color:"green"}}>{selectedSymbol}{Math.ceil(props.booking.totalCost)}</span></p></div>
            </Grid>
            <Grid container justify="center" style={{marginTop:"8px"}}>
              <Grid item xs="auto">
              </Grid>
            </Grid>
            </Grid>
          </>
          )
          case 'awaitingOwnerPaymentConfirmation':
            return(
              <>              
              <Grid container >
              <Grid item xs="auto">
              <Avatar alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
              </Grid>
              <Grid item xs="auto" style={{width:"220px"}}>
              <p><span style={{fontWeight:"bold"}}>Confirmación de pago recibida!</span>{" Número de confirmación: "+confirmationNumber}</p>
              <div style={{marginTop:"15px !important"}}><p ><span style={{fontWeight:"bold"}}>TOTAL: </span><span style={{fontWeight:"bold",color:"green"}}>{selectedSymbol}{Math.ceil(props.booking.totalCost)}</span></p></div>
              </Grid>
              <Grid container justify="center" style={{marginTop:"8px"}}>
                <Grid item xs="auto">
                  <Button onClick={received} variant="contained" color="secondary">Recibido</Button>
                  <Button onClick={decline} variant="outlined" color="secondary">Incorrecto</Button>
                </Grid>
              </Grid>
              </Grid>
            </>
              )
          case 'needAcceptance':
            return(
              <>
              <Grid container >
  
              
              <Grid item xs="auto">
              <Avatar alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
              </Grid>
              <Grid item xs="auto" style={{width:"220px"}}>
              <p><span style={{fontWeight:"bold"}}>{props.booking.customer.first_name+" "+props.booking.customer.last_name}</span> solicita reservar su {props.booking.listing.vehicle.brand+" "+props.booking.listing.vehicle.model+" "+props.booking.listing.vehicle.year+" (ID="+props.booking.listing.id+")"}</p>
              <p><span style={{fontSize:"14px"}}>desde {startDate} hasta {endDate} </span></p>
              <div style={{marginTop:"15px !important"}}><p ><span style={{fontWeight:"bold"}}>TOTAL: </span><span style={{fontWeight:"bold",color:"green"}}>{selectedSymbol}{Math.ceil(props.booking.totalCost)}</span></p></div>
              </Grid>
              <Grid container justify="center" style={{marginTop:"8px"}}>
                <Grid item xs="auto">
                  <Button onClick={aceptar} variant="contained" color="secondary">Aceptar</Button>
                  <Button onClick={rechazar} variant="outlined" color="secondary">Rechazar</Button>
                </Grid>
              </Grid>
              </Grid>
            </>
            )
      }

    }else{
      switch(codeCustomer){
        case 'pago':
          return(
            <>
            <Grid container >

            
            <Grid item xs="auto">
            <Avatar alt="Remy Sharp" src={props.booking.listing.mainPicture.url} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
            </Grid>
            <Grid item xs="auto" style={{width:"220px"}}>
            <p> <span style={{fontWeight:"bold"}}>Felicidades. Su reservación ha sido exitosamente confirmada</span></p>
            </Grid>
            <Grid container justify="center" style={{marginTop:"8px"}}>
              <Grid item xs="auto">
              </Grid>
            </Grid>
            </Grid>
          </>)
        case 'awaitingPayment':
          return(
            <>
            <Grid container >
            <Grid item xs="auto">
            <Avatar alt="Remy Sharp" src={props.booking.listing.mainPicture.url} style={{marginLeft:"8px",marginRight:"8px",width:"55px",height:"55px"}} />
            </Grid>
            <Grid item xs="auto" style={{width:"220px"}}>
            <p><span style={{fontWeight:"bold"}}>Excelente Eleccion!</span></p>
            {/* <p>requerimos la confirmación del pago para completar la reservación de su {props.booking.listing.vehicle.brand+" "+props.booking.listing.vehicle.model+" "+props.booking.listing.vehicle.year+" (ID="+props.booking.listing.id+")"}</p> */}
            <p><span style={{fontSize:"14px"}}> </span></p>
            <div style={{marginTop:"15px !important"}}><p ><span style={{fontWeight:"bold"}}>TOTAL: </span><span style={{fontWeight:"bold",color:"green"}}>{selectedSymbol}{Math.ceil(props.booking.totalCost)}</span></p></div>
            <Grid container justify="center" style={{marginTop:"8px"}}>
              <Grid item xs="auto" >
              <p>Favor digitar número de confirmación de su depósito para finalizar su reservacion de su {props.booking.listing.vehicle.brand+" "+props.booking.listing.vehicle.model+" "+props.booking.listing.vehicle.year+" (ID="+props.booking.listing.id+")"}</p>
              </Grid>
            </Grid>
            </Grid>

            <Grid container justify="center" style={{marginTop:"8px"}}>
              <Grid item xs="auto">
                <input placeholder="Número de confirmación" value={confirmation} onChange={handleConfirmation} onKeyPress={handleEnter}/>
                <Button onClick={sendConfirmation} variant="contained" color="secondary">Enviar</Button>
              </Grid>
            </Grid>

            </Grid>
          </>
          )
          case 'awaitingOwnerPaymentConfirmation':
            return(
              <>
              <Grid container>
  
              
                <Grid item xs="auto">
                <Avatar alt="Remy Sharp" src={props.booking.listing.mainPicture.url} style={{marginLeft:"0px",marginRight:"0px",width:"55px",height:"55px",position:"relative",left:"10px"}} />
                </Grid>
                <Grid item xs="auto" style={{width:"220px"}}>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                    <p style={{position:"relative",left:"18px"}}>Esperando confirmación de que el dueño recibió su pago</p>
                    </Grid>
                    <Grid item xs="auto">
                    <CircularProgress color="secondary" style={{marginTop:"10px",marginBottom:"10px"}}/>
                    </Grid>
                  </Grid>
                
                
                </Grid>
                </Grid>
            </>
            )
      }
    }
  }
      


  const mobileMenuId = 'search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      
      {props.userPack.logged?   <Link to="/dashboard" style={{color:"inherit"}}>   <MenuItem>
          <p>Dashboard</p>
      </MenuItem>
      </Link>:null}

      <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem>
          <p>Mi perfil</p>
      </MenuItem>
      </Link>
      <MenuItem onClick={getVerified}>
          <p>Mi cuenta</p>
      </MenuItem>
      <MenuItem onClick={handleSupportChat}>
          <p>Ayuda</p>
      </MenuItem>
      {props.userPack.logged?<MenuItem onClick={handleLogout}><p>Logout</p></MenuItem>:null}
    </Menu>
  );
  let marginTitle
  if(props.userPack.width<600){
    marginTitle="-1px"
  }else if(props.userPack.isMobile){
    marginTitle="0px"
  }else{marginTitle="-7px"}
  return (
    
    props.userPack.dialog.open?null:(
      <>

    <div className={props.white?"":classes.grow} style={{marginBottom:"8px"}}>

        <Toolbar>
          <Grid container>
            <Grid item xs="auto" style={{position:"relative",left:"20px",top:"4px"}}>
              <Link to="/" style={{color:"inherit"}}>
              <Typography className={props.userPack.logged?classes.title:""} style={{position:"relative",bottom:marginTitle}} variant="h4" noWrap>
                Carly
              </Typography>
              </Link>
          </Grid>
          <Grid item xs={9} md={10} >
            <Grid container spacing={0}>
              <Grid item xs={8} >
              <Grid container alignItems="center" justify="center">
              <Grid item xs="auto">
              <div style={{minWidth:"180px",position:"relative",top:"5px"}}>
              <MainDownShift label="Búsqueda"/>
              </div>
              </Grid>
              <Grid item xs="auto">
            <div style={{maxWidth:"140px",position:"relative",top:"11px"}}>
            <DateDownShift 
          label={"Desde"} book={props.userPack.book} current="start" value={props.userPack.book.myBooking.startDate}
          userPack={props.userPack}
            />
            </div>
            </Grid>
            <Grid item xs="auto">
            <div style={{maxWidth:"140px",position:"relative",top:"11px"}}>
            <DateDownShift 
          label={"Hasta"} book={props.userPack.book} value={props.userPack.book.myBooking.endDate} 
          userPack={props.userPack}
            />
            </div>
              </Grid>

            </Grid>
              </Grid>
              <Grid item xs={4}>
              <Grid container alignItems="flex-end" justify="flex-end">
                <Grid item xs="auto" style={{position:"relative",top:"7px"}}>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
              
             <IconButton
                             edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                color="inherit"
                onClick={handleClick}>
                  
                {selectedSymbol}
               {/* <AttachMoneyIcon/> */}
               
             </IconButton>
             <Popover
              id={id}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            {props.userPack.elements.currency.symbols.map((symbol,index)=>(
              <CurrencySelection label={symbol.value} key={index} symbol={symbol.label}/>
            ))}
             </Popover>

              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleList}
                >
              <Typography variant="button" display="block">
                Publica
              </Typography>
                </IconButton>


              {props.userPack.logged
              ?
              <>
              <IconButton onClick={handleChat}aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={chatAmount} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>

              <IconButton onClick={handleNotification} aria-label="show 17 new notifications" color="inherit">
             
                <Badge badgeContent={notificationAmount} color="secondary">

                  <NotificationsIcon />
                </Badge>
                
              </IconButton>
              <>
            {props.userPack.user && props.userPack.user.profilePicture?
            
            <Avatar onClick={handleProfileMenuOpen} alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{width:"35px",height:"35px",position:"relative",left:"9px",top:"9px"}}/>
              :
              <AccountCircle onClick={handleProfileMenuOpen} style={{position:"relative",left:"11px",top:"12px"}}/>
              }
            </>
            
            </>
              :
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleRegister}
                >
              <Typography variant="button" display="block">
                Registrate
              </Typography>
              </IconButton>

              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleLogin}
                >
              <Typography variant="button" display="block">
                Login
              </Typography>
              </IconButton>
              </>
                }
              

            </div>
            
              <div className={classes.sectionMobile} style={{position:"relative", left:props.userPack.logged?"15px":"0px"}}>

           <IconButton
                           edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                color="inherit"
                onClick={handleClick}>
                {selectedSymbol}
             {/* <AttachMoneyIcon/> */}
 
        </IconButton>

            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                color="inherit"
                onClick={handleList}
              >
            <Typography variant="button" display="block">
              Publica
            </Typography>
              </IconButton>
              {props.userPack.logged
              ?
              <>
              <IconButton onClick={handleChat} aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={chatAmount} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <Popover
            style={{width:"300px !important"}}
              id={idChat}
              open={openChat}
              anchorEl={anchorChat}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >          
            <Grid container spacing={1} style={{width:"320px",marginTop:"14px",marginBottom:"14px"}}>
            {supportChats.map((symbol,index)=>(
                 <>
                 <div className="notificationItem" key={symbol.id+3800} style={{width:"100%"}}>
              <ChatItem type="support" handleClose={handleClose} key={symbol.id} booking={symbol} userPack={props.userPack} selectedSymbol={selectedSymbol} />
              <div key={symbol.id+38000} className="divider"></div>
              </div>
              </>
            ))}   
               {notifications.filter(c=>c.payments[0].confirmed).map((symbol,index)=>(
                 <>
                 <div className="notificationItem" key={symbol.id+3800} style={{width:"100%"}}>
              <ChatItem handleClose={handleClose} key={symbol.id} booking={symbol} userPack={props.userPack} selectedSymbol={selectedSymbol} />
              <div key={symbol.id+38000} className="divider"></div>
              </div>
              </>
            ))}     
            </Grid>
            </Popover> 
            <IconButton onClick={handleNotification} aria-label="show 17 new notifications" color="inherit">

              <Badge badgeContent={notificationAmount} color="secondary">
                <NotificationsIcon />
              </Badge>
              
            </IconButton>
            <Popover
            style={{width:"300px !important"}}
              id={idNotification}
              open={openNotification}
              anchorEl={anchorNotification}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >         
            <Grid container spacing={1} style={{width:"320px",marginTop:"14px",marginBottom:"14px"}}>
               {notifications.map((symbol,index)=>(
                 <>
                 <div className="notificationItem" key={symbol.id+3800} style={{width:"100%"}}>
              <NotificationItem key={symbol.id} booking={symbol} userPack={props.userPack} selectedSymbol={selectedSymbol} />
              <div key={symbol.id+38000} className="divider"></div>
              </div>
              </>
            ))}          
            </Grid> 
            </Popover>
            <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleMobileMenuOpen}
                >
             {props.userPack.user && props.userPack.user.profilePicture?
            
            <Avatar alt="Remy Sharp" src={props.userPack.user.profilePicture?props.userPack.user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} style={{width:"35px",height:"35px"}}/>
              :<AccountCircle/>}
              </IconButton>
                </>
              :
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleRegister}
                >
              <Typography variant="button" display="block">
                Registrate
              </Typography>
              </IconButton>

              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleLogin}
                >
              <Typography variant="button" display="block">
                Login
              </Typography>
              </IconButton>
              </>
                }
                
              </div>
            </Grid>
            </Grid>
          </Grid>
                </Grid>
            </Grid>
            
           
          </Grid>
        </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </div>

    <Filters userPack={props.userPack}/>
    </>
  ));
}