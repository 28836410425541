import React from 'react';
import MySelect from '../../MySelect';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

 export default function(props){
    
      const handleChange = name => event => {

        props.userPack.updateMyUser([name],event.target.value)
      };

    const margin=props.userPack.width<700?"0px":"70px"
    return(
      <>
      <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
      <Grid container justify="center" style={{marginTop:"5px"}}>
          <Grid item xs={12}>
            <h1 style={{color:"white"}}>Función temporalmente deshabilitada</h1>
          
          </Grid>
          </Grid>
</div>
{props.userPack.width>350?
      <Grid container justify="center">
        <Grid item xs="auto">
        <p style={{position:"absolute",bottom:"30px",right:props.userPack.width<760?props.userPack.width/4+"px":props.userPack.width/5+"px",color:"white"}} className="helpText">Los campos con * son obligatorios</p>
        </Grid>
      </Grid>

        :null}
      </>
    );
}

