import React from 'react';
import Grid from '@material-ui/core/Grid';
import ChildIcon from '@material-ui/icons/ChildCare';
import GasIcon from '@material-ui/icons/LocalGasStation';
import PerformanceIcon from '@material-ui/icons/EvStation';
import GpsIcon from '@material-ui/icons/GpsFixed';
import SunroofIcon from '@material-ui/icons/WbSunny';
import UsbIcon from '@material-ui/icons/Usb';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Typography from '@material-ui/core/Typography';
import EventSeatIcon from '@material-ui/icons/EventSeat';

export default function(props){
    let icon="";
    let label="";
    switch(props.label){
        case "child":
            icon = <ChildIcon/>
            label = "Asiento para niños"
            break
            case "seats":
                icon = <EventSeatIcon/>
                break    
                case "performance":
                    icon = <PerformanceIcon/>
                    label = "Rendimiento"
                    break    
                case "gas":
                    icon = <GasIcon/>
                    label = "Combustible"
                    break       
                    case "bluetooth":
                        icon = <BluetoothIcon/>
                        label = "Bluethooth"
                        break      
                        case "usb":
                            icon = <UsbIcon/>
                            label = "Entrada USB"
                            break     
                            case "gps":
                                icon = <GpsIcon/>
                                label = "GPS"
                                break    
                                case "sunroof":
                                    icon = <SunroofIcon/>
                                    label = "Sunroof"
                                    break   
                                case "doors":
                                    icon = <img style={{width:"22px",height:"19px"}} src="../img/dooricon.png"/>
                                    break   
    }
    return(
        <>
                <Grid item xs={6}>
            <Grid container style={{marginTop:"6px",marginBottom:"6px"}}>
                <Grid item xs="auto">
                    {icon}
                </Grid>
                <Grid item xs="auto">
                    <Typography style={{marginLeft:"6px"}}>
                        {props.value?props.value:label}
                    </Typography>
                    
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}