import React, { Component } from 'react';
import BigNavBar from './listingscomponents/BigNavBar';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MediaCard from './listingscomponents/MediaCard';
import MyMaps from './listingscomponents/MyMaps';
import SmallNavBar from './listingscomponents/SmallNavBar';
import Filters from './listingscomponents/Filters';
import MapIcon from '@material-ui/icons/Map';
import TuneIcon from '@material-ui/icons/Tune';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { IconButton, Button } from '@material-ui/core';
import Chat from './maincomponents/Chat';
import RoomIcon from '@material-ui/icons/Room';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Signature from './maincomponents/Signature';
import PrietoPopOver from './maincomponents/PrietoPopOver';

const getUrl = window.location;
let neoUrl
if(getUrl.host.includes(":3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
}else{
  neoUrl = getUrl.host
}

const baseUrl = getUrl.protocol+ "//" + neoUrl +":8181/";
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


//const AnyReactComponent = ({ text }) => <div style={{color:"black" }}>{text}</div>;
const AnyReactComponentOG = (props) => {
  return( 
    <>
        <RoomIcon style={{fontSize:"35px",color:"red",position:"relative",bottom:"25px",right:"10px"}}/>

 </>
  )
}
const FloatingObj = (props) => {
  const handleSwitchMap=()=>{
    let newFilters = props.userPack.filters
    newFilters.map=!newFilters.map
    props.userPack.filterMethods.handleFilters(newFilters)
  }
  return( 
    <>
    {props.userPack.filters.map?
    <>
    <Grid item xs="auto" style={{backgroundColor:"transparent",position:"fixed",zIndex:"1500",bottom:(props.height/2)-40+"px",right:(props.width/3)-30+"px" }}>
        <IconButton onClick={handleSwitchMap}>
        <DoubleArrowIcon style={{fontSize:"35px",color:"black"}}/>
        </IconButton>
        </Grid>
        </>
        :
        <>
            <Grid item xs="auto" style={{backgroundColor:"transparent",position:"fixed",zIndex:"1500",bottom:(props.height/2)-40+"px",right:"0px" }}>
        <IconButton onClick={handleSwitchMap}>
        <ArrowBackIosIcon style={{fontSize:"35px",color:"black"}}/>
        </IconButton>
        </Grid>
        </>
        }
 </>
  )
}
const AnyReactComponent = (props) => {
  const hovered = props.hovered && props.element.id===props.hovered
  return(
    <>
    <Grid item xs="auto" style={{position:"relative",bottom:hovered?"29px":"0px"}}>
      <Grid container>
        <Grid item xs={12}>
          { hovered?
          <p className="hoveredElement" style={{padding:"5px",fontSize:"16px"}}>CLICK ME
          </p>
          
          :null}
        <GpsFixedIcon className="hoveredItem" id={props.element.id} onMouseLeave={props.handleHoverOut} onMouseEnter={props.handleHoverIn} onClick={props.handleHoverIn} style={{fontSize:"32px",color:"blue",position:"relative",bottom:"28px",right:"15px"}}/>
   
        </Grid>

      </Grid>
    </Grid>
 </>
  )
}


const Body = (props) => {
  const [hovered,setHovered]=React.useState(null)
  const handleHovered = (id)=>{
    setHovered(id)
  }
  let marginTop=""
  if(props.width>960){
    marginTop="89px"
  }
  else {marginTop="0px"}
  const marginLeft=props.width*2/3-10+"px"
  const floatingMap=(props.width/2)-300+"px"
  const handleMap=(e)=>{
    e.preventDefault()
    props.methods.switchFloating()
  }
  const handleFilters=(e)=>{
    e.preventDefault()
    let newFilters = props.userPack.filters
    newFilters.active = true
    console.log(newFilters)
    props.userPack.filterMethods.handleFilters(newFilters)
  }
  const handleHoverIn=(e)=>{
    e.preventDefault()
    setHovered(parseInt(e.target.id))
  }
  const handleHoverOut=(e)=>{
    setHovered(null)
    e.preventDefault()
  }
  return(

    <Grid container style={{marginBottom:"30px",marginTop:marginTop,transition:"all 2s ease-in !important"}}>
      
      {props.floating?
      <AppBar  style={{position:"fixed",backgroundColor:"white"}}>
          <Grid item xs="auto" style={{position:"fixed",top:"5px",left:"5px",zIndex:"9999"}}>
            <Button onClick={handleMap}variant="contained" color="secondary">Salir</Button>
          </Grid>
      <Grid item xs={12} style={{zIndex:"100"}}>
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key:"AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places" }}
            defaultCenter={{lat:props.elements.latitude,lng:props.elements.longitude}}//{{lat:props.book.latitude,lng:props.book.longitude}}
            defaultZoom={13}
            onClick={(e)=>{console.log("clicked",e  )}}
          >
           <AnyReactComponentOG
              lat={props.elements.latitude}
              lng={props.elements.longitude}
              text="My Marker"/>
            {props.results.map(element=>(
                          <AnyReactComponent hovered={hovered} handleHovered={handleHovered} handleHoverIn={handleHoverIn} handleHoverOut={handleHoverOut} key={element.id}
                          lat={element.owner.deliveryAddressList[0].xCoordinate}
                          lng={element.owner.deliveryAddressList[0].yCoordinate}
                          element={element}
                          text="My Marker"
                        />
            ))}
          </GoogleMapReact>
        </div>
        
        </Grid> 
        </AppBar>
        :
        null}
      
      <Grid item xs={12} md={props.userPack.filters.map?8:12}>
        <MediaCard handleHovered={handleHovered} results={props.results} width={props.width} userPack={props.userPack}/>
      </Grid>
        
          {props.width>960?
          <>
                      <Grid item md={4}>
            <FloatingObj userPack={props.userPack} width={props.width} height={props.height}/>
          {props.userPack.filters.map?

           <AppBar style={{position:"fixed",top:"72px",left:marginLeft,backgroundColor:"white"}}>
             {/* 
             <MyMaps 
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places"
             loadingElement={<div style={{ height: `100%` }} />}
             containerElement={<div style={{ height: props.height-81.5+"px" }} />}
             mapElement={<div style={{ height: `100%` }} />}
             />*/}
                   <div style={{ height: '100vh', width: '100%' }}>
                   
                     <GoogleMapReact
                       bootstrapURLKeys={{ key:"AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places" }}
                       defaultCenter={{lat:props.elements.latitude,lng:props.elements.longitude}}//{{lat:props.book.latitude,lng:props.book.longitude}}
                       defaultZoom={13}
                       onClick={(e)=>{console.log("clicked",e  )}}
                     >
                       
           <AnyReactComponentOG
             lat={props.elements.latitude}
             lng={props.elements.longitude}
             text="My Marker"
           />
           {props.results.map(element=>( 
                         <AnyReactComponent hovered={hovered} handleHovered={handleHovered} handleHoverIn={handleHoverIn} handleHoverOut={handleHoverOut} key={element.id}
                         lat={element.owner.deliveryAddressList[0].xCoordinate}  hovered={hovered} handleHovered={handleHovered}
                         lng={element.owner.deliveryAddressList[0].yCoordinate}
                         element={element}
                         text="My Marker"
                       />
           ))}
                     </GoogleMapReact>
                   </div>
                   {/* 
             <MyMap
             zooom={5}
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places"
             loadingElement={<div style={{ height: `100%` }} 
             />}/>
             />*/}

           </AppBar>
          
            :null}
          </Grid>
            </>
            
          :
          <div className="floatingIcons" style={{width:"190px",height:"40px"}}>
            <Grid item xs={12}>
              <Grid container justify="center" style={{marginTop:"6px"}}>
              <a href="" style={{color:"black"}} onClick={handleMap} >
                <Grid item xs={6}>
                  <Grid container spacing={0} justify="center">
                    <Grid item xs={6} style={{position:"relative",left:"8px"}}>
                    <MapIcon />
                    </Grid>
                    <Grid item xs={6} style={{position:"relative",top:"2px",left:"22px"}}>
                    <Typography variant="body2" >Mapa</Typography>
                      </Grid>
                    
                   
                  </Grid>

                </Grid>
                {/* <Grid item xs={3}>
                  
                </Grid> */}
                </a>
                <div style={{width:"15px"}}/>
                <Grid item xs={6}>
                <a href=""  style={{color:"black"}} onClick={handleFilters}>
                  <Grid container justify="center" spacing={1}>
                  
                  <Grid item xs="auto">
                  <TuneIcon/>
                  
                </Grid>
                <Grid item xs="auto">
                <Typography variant="body2" style={{marginTop:"0px",position:"relative",top:"2px"}}>Filtros</Typography>
                </Grid>
               
                  </Grid>
                  </a>
                </Grid>

              </Grid>
            
            </Grid>
            
          </div>
          }
          
        </Grid>
    
  );
};
/*          <MyMaps
          
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `700px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/> */
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

class Listings extends Component {
  
  state={fetched:this.props.fetched,floating:false,results:this.props.userPack.elements.results}

  componentDidMount(){
  }
  switchFloating=()=>{
    this.setState({floating:!this.state.floating})
  }

  constructor(props){
    
    super(props);
    if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
      props.userPack.dialog.handleOpen("register")
      //this.props.userPack.loginElements.handleRegister()
    }
  }
    render() {
      
        return (
          <React.Fragment>
            
            {this.props.userPack.width>960
            ?
            <AppBar elevation={0} color="inherit">
              <BigNavBar userPack={this.props.userPack} getResults={this.getResults}/>
            </AppBar>
            :
            <AppBar elevation={0} position="static"  style={{ background: 'transparent', boxShadow: 'none'}}>
              <SmallNavBar color="inherit" userPack={this.props.userPack} getResults={this.getResults}/>
            </AppBar>
            
            }
             
            {this.props.fetched?
            <>
            <Body floating={this.state.floating} elements={this.props.userPack.elements}
            methods={{switchFloating:this.switchFloating}} userPack={this.props.userPack}
            book={this.props.userPack.book} results={this.props.results} width={this.props.userPack.width} height={this.props.userPack.height}/>
            </>
            :
            <Grid container justify="center" style={{marginTop:"160px",marginBottom:"160px"}}>
              <Grid item xs="auto">
               <CircularProgress color="secondary"/>
              </Grid>
            </Grid>
            }
            <Signature userPack={this.props.userPack}/>
            </React.Fragment>
  );
}
}
 
export default Listings;