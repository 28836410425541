import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

const STATES = require('./data/states');
const brands=
['AC', 'Acura', 'Arola', 'Aixam', 'Mega', 'Alfa Romeo', 'Alpine', 'Alpine-Renault', 
'Alvis', 'AMC', 'Renault', 'Eagle', 'Anadol', 'Ariel', 'ARO', 'Artega', 'Asia', 
'Aston Martin', 'Lagonda', 'Audi', 'Autobianchi', 'DKW', 'Auto-Union', 'Auverland',
 'Bentley', 'Bitter', 'BMW', 'Bond', 'Lloyd', 'Borgward', 'Goliath', 'Bristol', 
 'Austin', 'Morris', 'Riley', 'Wolseley', 'MG', 'Vanden Plas', 'Princess',
 'Austin-Healey', 'Mini', 'Rover', 'Bugatti', 'Buick', 'Cadillac', 'Caterham', 
 'Checker', 'Chevrolet', 'Chrysler', 'Imperial', 'Talbot', 'Simca', 'Citro?', 'DS'
, 'Cizeta', 'Dacia', 'Daewoo', 'Saehan', 'DAF', 'Volvo', 'Daihatsu', 'Daimler',
'Datsun', 'Delahaye', 'DeLorean', 'DeSoto', 'De Tomaso', 'Dodge', 'RAM', 'Donkervoort',
 'Edsel', 'e.GO Mobile', 'Eicher Polaris', 'Elva', 'Facel Vega', 'Ferrari', 'Fiat', 
 'Ford', 'Bertone', 'Fisker', 'Karma', 'GMC', 'Freightliner', 'FSO', 'GAZ', 'Geo', 
 'Gilbern', 'Ginetta', 'Glas', 'BMW-Glas', 'GTA', 'Gumpert', 'Healey', 'Nash-Healey', 
 'Heinkel', 'Hennessey', 'Hillman', 'Hindustan', 'Holden', 'Honda', 'Hudson', 'Humber', 
 'Hummer', 'Hyundai', 'Genesis', 'Trabant', 'IFA', 'AWZ', 'Wartburg', 'Barkas', 'Infiniti', 
 'Innocenti', 'Invicta', 'Isdera', 'Iso', 'Isotta Fraschini', 'Isuzu', 'Iveco', 'Jaguar', 
 'Jeep', 'Jensen', 'JPX', 'Kia', 'Koenigsegg', 'KTM', 'Lada', 'Lamborghini', 'Lancia', 'Land Rover',
  'Lexus', 'Ligier', 'Lincoln', 'Lotus', 'LTI', 'LEVC', 'LuAZ', 'Lynx', 'Mahindra', 'Marcos', 'Marlin',
   'Maruti', 'Maserati', 'Mastretta', 'Deutsch-Bonnet', 'Ren? Bonnet', 'Matra', 'Mazda', 'Mazzanti', 'McLaren', 'Mercedes', 'Maybach', 'Mercury', 'Merkur',
'Messerschmitt', 'FMR', 'Mia', 'Micro', 'Mitsubishi', 'Monica', 'Monteverdi', 'Morgan', 'Moskwitch',
'Nash', 'Rambler', 'Naza', 'Nissan', 'Noble', 'NSU', 'Oldsmobile', 'Oltcit', 'Opel', 'Packard', 'Pagani',
 'Panhard', 'Panoz', 'Panther', 'Peel', 'Perodua', 'Peugeot', 'PGO', 'Piaggio', 'Plymouth', 'Polestar', 
 'Pontiac','As?na', 'Porsche', 'Premier', 'Proton', 'Puma', 'Ranger', 'Reliant', 'Rolls-Royce', 'Saab',
  'Saleen', 'Samsung', 'San', 'Santa Matilde', 'Santana', 'Saturn','Scion', 'Seat', 'SSC', 'Singer', 
  'Skoda', 'smart', 'Spyker', 'SsangYong', 'Standard', 'Triumph', 'Studebaker', 'Subaru', 'Sunbeam',
   'Sunbeam-Talbot', 'Suzuki','Tata', 'Tatra', 'Tazzari', 'Tesla', 'Tornado', 'Toyota', 'TVR', 'UAZ',
    'Vauxhall', 'Venturi', 'Volkswagen', 'Westfield', 'Wiesmann', 'Zastava', 'ZAZ', 'Zil']
var StatesField = createClass({

	displayName: 'StatesField',
	propTypes: {
		label: PropTypes.string,
		searchable: PropTypes.bool,
	},
	getDefaultProps () {
		return {
			label: 'States:',
			searchable: true,
		};
	},
	getInitialState () {
		return {
			country: 'AU',
			disabled: false,
			searchable: this.props.searchable,
			selectValue: 'new-south-wales',
			clearable: true,
			rtl: false,
		};
	},
	clearValue (e) {
		this.select.setInputValue('');
	},
	switchCountry (e) {
		var newCountry = e.target.value;
		this.setState({
			country: newCountry,
			selectValue: null,
		});
	},
	updateValue (newValue) {
		if(this.props.name==="brand"){
			this.props.fetchModels(newValue.label)
			this.props.updateMyListing(this.props.name,newValue.label)
			return
		}
		this.props.updateMyListing(this.props.name,newValue)
	},
	focusStateSelect () {
		this.select.focus();
	},
	toggleCheckbox (e) {
		let newState = {};
		newState[e.target.name] = e.target.checked;
		this.setState(newState);
	},
	render () {
		let options =[]
		if(this.props.name==="brand"){
			brands.map(brand=>{
				options.push({value:brand,label:brand})
			})
			
		}else{
			let raw=[]
			this.props.options.map(option=>{
				let existing=false
				raw.forEach(rawElement => {
					if(rawElement===option.model){
						existing=true;
					}
				});
				if(!existing){raw.push(option.model)}
			})
			let unique = [...new Set(raw)]; 
			unique.map(model=>{
				
				options.push({value:model,label:model})
			})
		}

		return (
			<>
			<div className="selectionBox" style={{width:"100%",paddingTop:"10px"}}>
				<Select
					menuContainerStyle={{'zIndex': 999}}
					id="state-select"
					placeholder="Seleccione..."
					ref={(ref) => { this.select = ref; }}
					onBlurResetsInput={false}
					onSelectResetsInput={false}
					options={options}
					simpleValue
					clearable={this.state.clearable}
					name="selected-state"
					disabled={this.state.disabled}
					value={this.props.value}
					onChange={this.updateValue}
					rtl={this.state.rtl}
					searchable={this.state.searchable}
					
				/>
			</div>
			</>
		);
	}
});


export default StatesField;