import React from 'react';  
import Grid from '@material-ui/core/Grid';
import {makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  body: {
    paddingTop:"75px",
    paddingLeft:"20px",
    paddingRight:"20px",
  }
}))

const Loader = (props) => {
    const classes = useStyles(props);
    const handleLogin=()=>{
      props.userPack.dialog.handleOpen("login")
    }
    return(
      <>
      <AppBar color="inherit" elevation={0}>
      <NavBar userPack={props.userPack}/>
      </AppBar>
      <Grid style={{backgroundColor:"white"}} container spacing={3} className={classes.body} justify="center" alignItems="center" alignContent="center">

          <Grid item xs={"auto"} style={{marginTop:"140px"}}>
          {props.userPack.logged?<CircularProgress color="secondary" />:<div><p>No has iniciado sesion</p><a onClick={handleLogin}>Click aqui para iniciar sesion</a></div>}
          
          </Grid> 
     
      </Grid>

      </>
    );
  };

export default Loader;