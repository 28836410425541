import React from 'react';
import Grid from '@material-ui/core/Grid'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CommentSection from '../listingscomponents/CommentSection';
export default function(props){
    const isMobile=props.width<960?true:false
    return(
<>{
  props.bookingHistory.length===0?
<>
<Grid container spacing={2} style={{marginBottom:"8px",marginTop:"8px"}}>
<Grid item xs={12} style={{marginTop:"15px"}}>
            <h1 className="secondTitle" >Críticas:</h1>
          </Grid>
          <Grid item xs={12}>
          Aún no ha recibido ninguna
          </Grid>
          </Grid>
</>
  :
<Grid container spacing={2} style={{marginBottom:"8px",marginTop:"8px"}}>
<Grid item xs={12} style={{marginTop:"15px"}}>
            <h1 className="secondTitle" >Críticas recibidas</h1>
          </Grid>
<Grid item xs={12}>
  <Grid container>
    <Grid item xs="auto">
      <Box component="fieldset" borderColor="transparent">
        <Rating value={4} readOnly/>
      </Box>
    </Grid>
    <Grid item xs="auto">
      <Typography style={{fontWeight:"bold", color:"black", marginTop:"3px",marginLeft:"8px"}}>
        4.1 de 5
      </Typography>
    </Grid>
    <Grid item xs={12} style={{marginBottom:"15px"}}>
      <Typography style={{color:"black",marginLeft:"4px"}}>
          15 clientes han evaluado
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <CommentSection isMobile={isMobile} width={props.width}/>
    </Grid>
  </Grid>
</Grid>
</Grid>
}
        
</>)}