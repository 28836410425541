import React from 'react';
import MySelect from '../../MySelect';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

 export default function(props){
    
      const handleChange = name => event => {

        props.userPack.updateMyUser([name],event.target.value)
      };

    let landmark = ""
    let line1 = ""
    let line2 = ""
    let city = ""
    let state = ""
    let zipcode = ""
    let country = ""

    if(props.userPack.user.homeAddress){
        landmark=props.userPack.user.homeAddress.landmark
        line1=props.userPack.user.homeAddress.line1
        line2=props.userPack.user.homeAddress.line2?props.userPack.user.homeAddress.line2:""
        city=props.userPack.user.homeAddress.city
        state=props.userPack.user.homeAddress.state.name
        zipcode=props.userPack.user.homeAddress.zipcode
        country=props.userPack.user.homeAddress.state.country
    }
    
    const margin=props.userPack.width<700?"0px":"70px"
    return(
      <>
      <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
      <Grid container style={{marginTop:"5px"}}>
          <Grid item xs={12}>
          <TextField
          style={{width:"100%",color:"white"}}
              required
              autoFocus
              id="standard-required"
              label="Dirección (línea 1)"
              margin="normal"
              value={line1}
              onChange={handleChange('homeLine1')}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
              />
               </Grid>
          
          </Grid>
          <Grid item xs={12}>
          <TextField
          style={{width:"100%",color:"white"}}
              id="standard-required"
              label="Dirección (línea 2)"
              margin="normal"
              value={line2}
              onChange={handleChange('homeLine2')}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
              />
          </Grid>
          <Grid container>
            <Grid item xs={5}>
            <TextField
            style={{width:"100%",color:"white"}}
            required
                id="standard-required"
                label="Ciudad"
                margin="normal"
                value={city}
                onChange={handleChange('homeCity')}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Grid>
            <Grid item xs={4}>
            <TextField
            style={{width:"100%",color:"white"}}
                required
                id="standard-required"
                label="Estado/Provincia"
                margin="normal"
                value={state}
                onChange={handleChange('homeState')}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Grid>
            <Grid item xs={3}>
            <TextField
            style={{width:"100%",color:"white"}}
                id="standard-required"
                label="Código postal"
                margin="normal"
                value={zipcode}
                onChange={handleChange('homeZipcode')}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Grid>
          </Grid>


</div>
{props.userPack.width>350?
      <Grid container justify="center">
        <Grid item xs="auto">
        <p style={{position:"absolute",bottom:"30px",right:props.userPack.width<760?props.userPack.width/4+"px":props.userPack.width/5+"px",color:"white"}} className="helpText">Los campos con * son obligatorios</p>
        </Grid>
      </Grid>

        :null}
      </>
    );
}

