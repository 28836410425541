import React from 'react';
import Grid from '@material-ui/core/Grid';
import NavBar from './maincomponents/NavBar';
import AppBar from '@material-ui/core/AppBar';
import MyAvatar from './maincomponents/MyAvatar';
import Critica from './maincomponents/Critica';
import Signature from './maincomponents/Signature';

const dateFormat = require('dateformat');

const CheckedLine = (props)=>{
  return(
    <>
    <Grid container style={{marginTop:"15px"}}>
      <Grid item xs={6}>
        <Grid container justify="flex-start">
        <h1 className="secondTitle">{props.mainLabel}</h1>
        </Grid>
      </Grid>
      
      <Grid item xs={6}>
        <Grid container justify="flex-end">
          {props.verified?
          <h1 className="secondTitle">Sí</h1>
            :
            <a href="">
            <h1 className="secondTitle">{props.viewUser?'No':'Verificar'}</h1>
            </a>
            }
          
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}
const Perfil = (props) => {
  let user
  let viewUser
  if(props.viewUser){
    user=props.viewUser
    viewUser=true
  }else{
    user=props.userPack.user
    viewUser=false
  }
  let padding=props.userPack.width<800?"15px":"12rem"
  if(props.userPack.width>1000){padding="16rem"}
  if(props.userPack.width>1150){padding="25rem"}
    return(
      <>
      <Grid container id="background" style={{backgroundImage:"url('../media/perfilportada.jpg')",backgroundRepeat:"no-repeat",backgroundSize:"cover", backgroundPosition:"center", width:"100%",minHeight:"200px",height:props.userPack.height/3+"px"}}>
        
        <AppBar elevation={0} position="static"  style={{ background: 'transparent', boxShadow: 'none',color:"white"}}>
          <NavBar userPack={props.userPack} width={props.width} white={true}/>
        </AppBar>
      </Grid>
      <Grid container>
          <Grid item xs={12} style={{position:"relative",bottom:"55px"}}>
            {console.log(user.profilePicture,"profile")}
            <MyAvatar pic={user.profilePicture?user.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"}/>
          </Grid>
          <Grid container style={{position:"relative",bottom:"55px"}}>
            <Grid container justify="center">
              <Grid item xs="auto">
                <h1>{user.first_name?user.first_name+" "+user.last_name:"No definido"}</h1>
              </Grid>
            </Grid>
              <Grid container justify="center">
                <Grid item xs="auto">
                  <h1 className="helpText">{"Miembro desde: "+dateFormat(user.date_joined, "dd-mmm-yyyy")}</h1>
                </Grid>
              </Grid>
              <Grid container style={{paddingLeft:padding,paddingRight:padding,marginTop:"40px"}}>
          <Grid item xs={12}>
            <h1 className="helpText">Información verificada</h1>
          
          </Grid>
          <Grid item xs={12} style={{marginTop:"10px"}}>
            <CheckedLine viewUser={viewUser} mainLabel="Correo electrónico" verified={user.confirmedEmail}/>
            <CheckedLine viewUser={viewUser} mainLabel="Facebook" verified={user.confirmedFacebook}/>
            <CheckedLine viewUser={viewUser} mainLabel="Google" verified={user.confirmedGoogle}/>
          </Grid>
          <Grid item xs={12} style={{marginTop:"20px"}}>
            <Grid container justify="center">
              <Grid item xs="auto">
              <p className="helpText">Aumenta la confianza en otros usuarios de Carly al verificar tus datos</p>
              </Grid>
            </Grid>
            
          
          </Grid>

          <Critica width={props.userPack.width} bookingHistory={[]}/>
        </Grid>
              


          </Grid>
      </Grid>
      <Signature userPack={props.userPack}/>
      </>
    );
  };

export default Perfil;