import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MultiSelect from '../../MultiSelect';
import { Button, IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
const banks = [{name:"Banco Popular Dominicano",abr:"BPD",url:"img/popular.jpg"},
{name:"Banco BHD León",abr:"BHD",url:"img/banreservas.png"},
{name:"Banreservas",abr:"Banreservas",url:"img/bhd.png"}
]

const Logo = (props)=>{
    const selectBank=()=>{
        props.selectBank(props.banco.abr)
    }
    let selected=false
    props.banco.abr===props.currentBank?
    selected=true
    :selected=false
    const [step,setStep]=React.useState(1)

    const [accountNumber,setAccountNumber]=React.useState("")
    const [titular,setTitular]=React.useState("")
    const [error,setError]=React.useState(null)
    const handleAccountNumber = e =>{
        setAccountNumber(e.target.value)
    }
    const handleTitular = e =>{
        setTitular(e.target.value)
    }
    const cancel=()=>{
        setError(null)
        setStep(1)
        setAccountNumber("")
        setTitular("")
        props.selectBank(null)
    }
    const save=(e)=>{
        if(titular.length<7){
            setError("Digite el nombre completo del dueño de la cuenta")
        }else{
            let newBanks = props.user.bankAccounts
            newBanks.push({id:null,bankName:props.banco.name,bankAbr:props.banco.abr,ownerName:titular,owner:props.user.id,accountNumber:accountNumber})
            props.updateMyUser("bankAccounts",newBanks)
            cancel()
        }
    }

    const next=(e)=>{
        if(accountNumber.length<7){
            setError("Digite un número de cuenta válido")
        }else{
            setError(null)
            setStep(2)
        }
    }
    return(<>
    {!selected?
    <Grid item xs="auto">
        <a href="#" onClick={selectBank}>
        <img src={props.banco.url} style={{width:"50px",height:"50px"}}>
        </img>
        </a>
    </Grid>
    :
    <Grid item xs="auto">
        <Grid container spacing={2}>
            <Grid item xs="auto">
                <a href="#" onClick={selectBank}>
                <img src={props.banco.url} style={{width:"50px",height:"50px"}}>
                </img>
                </a>
            </Grid>
            <Grid item xs="auto" style={{width:"170px"}}>
                <Grid container>
                <Grid item xs={12}>
                    {step===1?
                                    <TextField style={{width:"100%"}}
                                    id="outlined-multiline-static"
                                    label={"Número de cuenta"}
                                    onChange={handleAccountNumber}
                                    value={accountNumber}
                                    multiline
                                    margin="normal"
                                    variant="outlined"
                                />
                    :
                    <TextField style={{width:"100%"}}
                    id="outlined-multiline-static"
                    label={"Titular"}
                    onChange={handleTitular}
                    value={titular}
                    multiline
                    margin="normal"
                    variant="outlined"
                />
                    }
                </Grid>
                    <Grid item xs={12}>
                    <Grid container justify="center">
                <Grid item xs="auto">
                    <Typography variant="caption" style={{color:"red"}}>
                        {error}
                    </Typography>
                </Grid>
            </Grid>
                    </Grid>
                </Grid>

                </Grid>
            <Grid item xs="auto" style={{position:"relative",top:step===1?"10px":"-3px"}}>
                {step===1?
                            <Button variant="outlined" color="secondary" onClick={next}>
                            Continuar
                        </Button>
                :
                <>
                <Grid container>
                    <Grid item xs={12}>
                    <Button variant="outlined" color="secondary" onClick={save}>
                Guardar
            </Button>
                    </Grid>
                    <Grid item xs={12}>
                    <Button variant="outlined" onClick={cancel}>
                            Cancelar
                        </Button>
                        </Grid>
                </Grid>


                        </>
                }
            </Grid>

        </Grid>
    </Grid>

    }
    </>
    )
}

const MyBank = (props)=>{
    //console.log("My ACCCOUNTS Banks",props.selectedBanks,props.banco)
    const url = banks.filter(c=>c.abr===props.banco.bankAbr)[0].url
    let selectedTemp = false
    if(props.selectedBanks){
        props.selectedBanks.forEach(bank => {
            if(bank.id===props.banco.id){
                selectedTemp=true
            }
        });
    }
    let [selected,setSelected] = React.useState(selectedTemp)
    const handleClick=()=>{
        if (selected){
            console.log("Old Banks",props.selectedBanks)
            let newBanks = props.selectedBanks.filter(c=>c.id!==props.banco.id)
            console.log("New Banks",newBanks)
            props.updateMyListing("bankAccounts",newBanks)
            setSelected(prev=>!prev)
            return
        }
        let newBanks = props.selectedBanks
        let myBanco = props.banco
        console.log(myBanco.id,"este es el id del banco")
        //myBanco.id = null
        newBanks.push(props.banco)
        props.updateMyListing("bankAccounts",newBanks)
        setSelected(prev=>!prev)
    }
    const handleDelete=(e)=>{
        console.log("deleting action")
        let newBanks = [...props.allAccounts].filter(c=>c.id!==props.banco.id)
        props.updateMyUser("bankAccounts",newBanks)
        e.preventDefault()
        return
    }

    return(<>
    
        <Grid item xs="auto" onClick={handleClick} id={props.banco.id} style={{width:"150px",maxHeight:"200px",marginRight:"20px"}}>
            <Grid container >
                <Grid item xs="auto">
                        <img src={url} style={{width:"75px",height:"75px"}}>
                        </img>
                </Grid>
                <Grid container justify="flex-end">
                    
                    <Grid item xs={1}>
                        <Grid container style={{position:"relative",right:"4px",bottom:"30px"}}>
                        <Checkbox style={{position:"relative",bottom:"50px"}}
                            checked={selected}
                            value="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <a onClick={handleDelete}>
                        <DeleteForeverIcon style={{position:"relative",bottom:"55px",left:"8px"}}/>
                        </a>
                        </Grid>


                        
                    </Grid> 
                </Grid>

                <Grid container style={{position:"relative",bottom:"50px"}}>
                    <Grid item xs={12} style={{fontWeight:"700"}}>
                        {props.banco.ownerName}
                    </Grid>
                    <Grid item xs={12} style={{color:"blue"}}>
                        {props.banco.accountNumber}
                    </Grid>
                </Grid>
            </Grid>


        </Grid>
    </>)
}
export default function(props){
    const handleChange = name => event => {
        props.userPack.updateMyListing([name],event.target.value)
    };
    const features = []
    props.userPack.elements.features.forEach(feature => {
        features.push({value:feature.id,label:feature.label,name:feature.name})
    });
    const [existingBanks,setBanks] = React.useState(null)
    const [currentBank,setCurrentBank] = React.useState(null)
    const selectBank=(bankAbr)=>{
        setCurrentBank(bankAbr)
    }
    const addBank=(bank)=>{
        let newExistingBanks = existingBanks.push(existingBanks)
        setBanks(newExistingBanks)
    }
    
    return(
        <>
        <div style={{paddingLeft:"15px",paddingRight:"15px",backgroundColor:"white",overflowY:"auto",overflowX:"hidden"}}>
            <p style={{paddingBottom:"10px",paddingTop:"15px"}}>Elija las caracteristicas que posee su vehículo</p>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12}>
                <MultiSelect value={props.userPack.myListing.features} updateMyListing={props.userPack.updateMyListing} options={features}/>
                </Grid>
                <Grid item xs={12} style={{marginTop:"3px"}}>
                    <p>Haz destacar tu post con una buena descripcion!</p>
                </Grid>
                <Grid item xs={12} style={{marginTop:"5px"}}>
                    <TextField style={{width:"100%"}}
                        id="outlined-multiline-static"
                        label="Descripción"
                        onChange={handleChange('additionalDetails')}
                        value={props.userPack.myListing.additionalDetails}
                        multiline
                        rows="6"
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop:"0px",marginBottom:"0px"}}>
                    {!props.userPack.user.bankAccounts.length==0?<p>Mis cuentas de para recibir pagos</p>:null}
                </Grid>
                <Grid item xs={12}>
                <Grid container justify="flex-start">
                {props.userPack.user.bankAccounts.map((banco,index)=>(
                    <MyBank banco={banco} banks={banks} 
                    selectedBanks={props.userPack.myListing.bankAccounts} 
                    allAccounts={props.userPack.user.bankAccounts}
                     key={index} updateMyUser={props.userPack.updateMyUser} updateMyListing={props.userPack.updateMyListing}
                     />
                ))}
                </Grid>
                </Grid>

                <Grid container style={{position:"relative",bottom:"25px",left:"4px"}}>

                    <Grid item xs={12} style={{marginTop:"8px",marginBottom:"5px"}}>
                    <p>Agregar cuenta:</p>
                    </Grid>
                    
                    <Grid container justify="flex-start" spacing={3} style={{marginBottom:"15px"}}>
                    {banks.map((banco,index)=>(
                        <Logo banco={banco} addBank={addBank} selectBank={selectBank}
                        existingBanks={existingBanks} 
                        currentBank={currentBank} user={props.userPack.user}
                        key={index} updateMyUser={props.userPack.updateMyUser}/>
                    ))}
                    
                    </Grid>
                </Grid>
            </Grid>
            
            </div>
            </>
    );
}

