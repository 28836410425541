import React, { Component } from 'react';
import NavBar from './maincomponents/NavBar';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Signature from './maincomponents/Signature';
import CircularProgress from '@material-ui/core/CircularProgress';
////////////////////

const getUrl = window.location;

class MakeMoney extends Component {

    state = {
      dialogData:{},
      openDialog:false
     }
    constructor(props){
      super(props)
      if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
        console.log("positivo dotol")
        props.userPack.dialog.handleOpen("register")
        //this.props.userPack.loginElements.handleRegister()
      }

    }
    // componentDidUpdate(){

    // }
    newListing = ()=>{
      this.setState({dialogData:{action:"newListing"}});
      this.setState({openDialog:true});
    }
    handleCloseDialog = ()=>{
      this.setState({openDialog:false});
    }

    handleOpen=()=>{
      console.log("USer exist?",this.props.userPack.user)
      if(this.props.userPack.logged && this.props.userPack.user.verified){
        this.props.userPack.ulteriorMethods.removeUlteriorMotive();
        this.props.userPack.dialog.handleOpen("newListing",this.props.userPack.myListing,this.props.userPack.listings)
      }
      window.localStorage.setItem('ulteriorMotive','list')
      this.props.userPack.ulteriorMethods.setUlteriorMotive("list");
      this.props.userPack.dialog.handleOpen("needPowers")
    }
    render() {
      const mainMarginTop =this.props.userPack.height<600?"0px":"90px"
      const paddingMobile=this.props.userPack.width<600?"10px":"0px"
      const height = this.state.height+"px"
      const width = (this.state.width>600)?(this.state.width-0.4*this.state.width)+ "px":(this.state.width-0.05*this.state.width)+ "px";
        return (
      <React.Fragment>
       <div id="background" style={{backgroundImage:"url('../makemoney.jpg')", backgroundRepeat:"no-repeat",backgroundPosition:"center", backgroundSize:"cover",width:"100%",height:this.props.userPack.height}}>
         <div style={{opacity:"1 !important"}}>
        <AppBar elevation={0} position="static" style={{ background: 'transparent', boxShadow: 'none',color:"white !important"}}>
          <NavBar userPack={this.props.userPack} width={this.props.userPack.width} style={{color:"white"}} white={true}/>
        </AppBar>

        <div style={{color:"rgb(255, 255, 255)"}}>
        <Grid container justify="center" style={{marginTop:mainMarginTop,paddingRight:paddingMobile,paddingLeft:paddingMobile}}>
         {!this.props.userPack.dialog.open?
         <>
            <Grid item xs="auto">
                <h1 className="secondTitle white">Haz que tu carro</h1>
            </Grid> 
        
        <Grid container justify="center">
            <Grid item xs="auto">
                <h1 className="mainTitle" style={{textShadow: "0 0 3px #FF0000"}}>TRABAJE para ti!</h1>
            </Grid> 
        </Grid> 
        <Grid container justify="center" style={{marginTop:"10px"}}> 
            <Grid item xs="auto" style={{fontWeight:"1000 !important"}}>
                <Button variant="outlined" color="secondary" onClick={this.handleOpen}>Publica tu carro!</Button>
            </Grid>
        </Grid>
        <Grid container justify="center" style={{marginTop:"40px"}}> 
            <Grid item xs="auto">
                <p className="captionTitle">Descubre las grandes posibilidades</p>
            </Grid>
        </Grid>
        </>
        :<CircularProgress color="secondary" />
        
        }
        </Grid> 
        </div>
      </div>
      </div>
      <Signature userPack={this.props.userPack}/>
      {/*<Grid item xs={12} style={{position:"fixed",bottom:"0"}}fontSize="large" color="inherit">
    <ArrowDownwardIcon/></Grid>*/}
        
      
    </React.Fragment>
  );}}
 
export default MakeMoney;