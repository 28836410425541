import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from "@reach/router";
import Grid from '@material-ui/core/Grid';
import LoginModal from '../maincomponents/LoginModal';
import CurrencyItem from '../maincomponents/CurrencyItem';
import Popover from '@material-ui/core/Popover';
import Filters from './Filters';


const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    backgroundColor:"transparent",
    color:"black",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    color: 'inherit'
  },
 
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const id = open2 ? 'simple-popover' : undefined;
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);
  const [register, setRegister] = React.useState(false);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }
  const handleClick = event => {
    setAnchorEl2(event.currentTarget);
  };
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleRegister = (e) => {
    handleClose();
    e.preventDefault()
    
    setRegister(true);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl2(null);
    setAnchorEl(null);
    setOpen(false);
    setRegister(false);
  };
  const handleSwitch = () => {
    setRegister(prev => !prev);
  };
  const handleLogout = () => {
    handleClose()
    props.userPack.logout();
  };
  const handleLogin=(e)=>{
    handleClose();
    e.preventDefault()
    
    props.userPack.dialog.handleOpen("login")
  }
  const handleHelp=()=>{
    handleMenuClose()
    props.userPack.getHelp()
    //props.userPack.dialog.handleOpen("getVerified")
  }
  const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;

  const rate = props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]
  const handleCurrency=(value)=>{
    props.userPack.setCurrency(value)
    setAnchorEl2(null)
  }
  const CurrencySelection = (props)=>{
  
  return(<CurrencyItem handleCurrency={handleCurrency} symbol={props.symbol} label={props.label}/>)
  }
  const menuId = 'search-account-menu';
  const renderMenu = (
    <>
    {props.userPack.logged?
    <>
    <Menu
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    id={menuId}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isMenuOpen}
    onClose={handleMenuClose}
  >
    <Link to="/dashboard" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      </Link>
      <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Link>
      <Link to="/account" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Link>
      <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleLogin}>Login</MenuItem>
      </Link>
      <Link to="/account" style={{color:"inherit"}}>
      <MenuItem onClick={handleRegister}>Registrarme</MenuItem>
      </Link>

      <a style={{color:"black !important"}}>
      <MenuItem onClick={handleHelp}>Ayuda</MenuItem>
      </a>
      {props.userPack.logged?<MenuItem onClick={handleLogout}>Logout</MenuItem>:null}
  </Menu>
  </>:
      <>
      <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
       <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleLogin}>Login</MenuItem>
      </Link>
      <Link to="/account" style={{color:"inherit"}}>
      <MenuItem onClick={handleRegister}>Registrarme</MenuItem>
      </Link>
 <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleLogin}>Login</MenuItem>
      </Link>
      <Link to="/account" style={{color:"inherit"}}>
      <MenuItem onClick={handleRegister}>Registrarme</MenuItem>
      </Link>

      <a style={{color:"black !important"}}>
      <MenuItem onClick={handleHelp}>Ayuda</MenuItem>
      </a>
      {props.userPack.logged?<MenuItem onClick={handleLogout}>Logout</MenuItem>:null}
    </Menu>

      </>}
    
    </>
  );

  const mobileMenuId = 'search-account-menu-mobile';
  const renderMobileMenu = (
    <>
    {props.userPack.logged?
    <>
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
     
      <Link to="/dashboard" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      </Link>
      <Link to="/profile" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Link>
      <Link to="/account" style={{color:"inherit"}}>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Link>
      <a style={{color:"black !important"}}>
      <MenuItem onClick={handleHelp}>Ayuda</MenuItem>
      </a>
      {props.userPack.logged?<MenuItem onClick={handleLogout}>Logout</MenuItem>:null}
      </Menu>
      </>
      :    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
            <a href="" style={{color:"inherit"}}>
      <MenuItem onClick={handleLogin}>Login</MenuItem>
      </a>
      <a href="" style={{color:"inherit"}}>
      <MenuItem onClick={handleRegister}>Registrarme</MenuItem>
      </a>
      <a style={{color:"black !important"}}>
      <MenuItem onClick={handleHelp}>Ayuda</MenuItem>
      </a>
      {props.userPack.logged?<MenuItem onClick={handleLogout}>Logout</MenuItem>:null}
      </Menu>
      </>}
    </>
  );

  return (
    <div className={classes.grow}>
        <Toolbar>
          <Grid container>
            <Grid item md={1}>
              </Grid>
            <Grid item xs={6} md={4}>
          <Link to="/" style={{color:"inherit"}}>
          <Typography className={classes.title} variant="h4" noWrap>
          Carly
          </Typography>
          </Link>
          </Grid>
          <Grid item xs={6} md={6} >
            <Grid container alignItems="flex-end">
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                >
              <Typography variant="button" display="block">
                Publica
              </Typography>
              </IconButton>
              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                >
              <Typography variant="button" display="block">
                Ayuda
              </Typography>

              <LoginModal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={open}
                  onClose={handleClose}
                  register={register}
                  switch={handleSwitch}
              />
                
              </IconButton>
              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleRegister}
                >
              <Typography variant="button" display="block">
                Registrate
              </Typography>
              </IconButton>

              <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={handleOpen}
                >
              <Typography variant="button" display="block">
                Login
              </Typography>
              </IconButton>
              
              <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            </div>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                color="inherit"
                onClick={handleClick}>
                  
                {selectedSymbol}
               {/* <AttachMoneyIcon/> */}
               
             </IconButton>
             <Popover
              id={id}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container style={{overflow:"hidden",width:"110px"}}>
            {props.userPack.elements.currency.symbols.map((symbol,index)=>(
              <CurrencySelection label={symbol.value} key={index} symbol={symbol.label}/>
            ))}
            </Grid>
             </Popover>
              <div className={classes.sectionMobile}>

                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item md={1}>
              </Grid>
          </Grid>
          </Grid>
        </Toolbar>
      {renderMobileMenu}
      {renderMenu}
      <Filters userPack={props.userPack}/>
    </div>
    
  );
}