import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const getUrl = window.location;
let neoUrl
if(getUrl.host.includes(":3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
}else{
  neoUrl = getUrl.host
}

const baseUrl = getUrl.protocol+ "//" + neoUrl +":8181/";
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


const urilize =(url)=>{
  return("url('"+ url+"')")
}

const Tarjetita = (props) =>{
  const handleMakeMain = ()=>{
    props.methods.handleMakeMain(props.picture.id)
  }
  const handleDeletePicture = ()=>{
    props.methods.handleDeletePicture(props.picture.id)
  }
  let masa = null
  if(props.width>580&&props.width<850){
    masa=180
  }else if(props.width<581){
    masa=155
  } else{
    masa=270
  }
  const masaPeque = props.width<400?masa/1.5:masa/1.5
  return(
    <>
    {props.picture.url!=="1lo1"?
        <>
    <Grid item xs="auto">
      <div id="myContainer" > 
        <figure className="hoverFade">
        <div style={{backgroundImage:urilize(props.picture.url),
            backgroundSize:"100%",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            //borderRadius:"10px",
            height: "auto",
            maxWidth: "auto",
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)'}}>
                            <CloseIcon id="floatingIcon" onClick={handleDeletePicture}
              style={{
                position:"relative",
                top:"5px",
                left:masa-30+"px",
                opacity:"1 !important",
                zIndex:"9999"}}/>
              <Box style={{height:masaPeque+"px",width:masa+"px"}}>

              </Box>
          </div>
          <div id="toggle">
            <span onClick={handleMakeMain}
              style={{
              position:"relative",
              bottom:"30px",
              left:"12px",
              zIndex:"20",
              backgroundColor:"#f50057"}}>Hacer foto principal
            </span>

          </div>
        </figure>
      </div>

    </Grid>
    </>
    :null}
    </>
  );
}

const MainPicture = (props) =>{
  const handleDeletePicture = ()=>{
    console.log("deletinggg")
    props.methods.handleDeletePicture(props.picture.id)
  }
  let masa = null
  if(props.width>580&&props.width<850){
    masa=180
  }else if(props.width<581){
    masa=155
  } else{
    masa=270
  }
  const masaPeque = props.width<400?masa/1.5:masa/1.5
  return(
    <>
    <Grid item xs="auto">
      <div id="myContainer" > 
        <figure className="hoverFade">
        <div style={{backgroundImage:urilize(props.picture.url),
            backgroundSize:"100%",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            //borderRadius:"10px",
            height: "auto",
            maxWidth: "auto",
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)'}}>
                            <CloseIcon id="floatingIcon" onClick={handleDeletePicture}
              style={{
                position:"relative",
                top:"5px",
                left:masa-30+"px",
                opacity:"1 !important",
                zIndex:"-1"}}/>
              <Box style={{height:masaPeque+"px",width:masa+"px"}}>

              </Box>
          </div>
          <div>
            <span
              style={{
              position:"relative",
              bottom:"30px",
              left:"12px",
              zIndex:"20",
              backgroundColor:"#f50057"}}>Esta es la foto principal
            </span>

          </div>
        </figure>
      </div>

    </Grid>
    </>
  );
}

class Fotos extends Component {
  state={
    fetching:false,
    progressObj:{progress:0,total:100}
  }
  constructor(props){
    super(props);
    this.inputRef = React.createRef()
  }
  handleMakeMain = (id) =>{
    let sujeto = {mainPicture:this.props.userPack.myListing.mainPicture,listingPictures:this.props.userPack.myListing.listingPictures}
    
    let newMain = sujeto.listingPictures.filter(c=>c.id===id)[0]
    let newList = sujeto.listingPictures.filter(c=>c.id!==id)
    newList.push(this.props.userPack.myListing.mainPicture)

    if(newMain){
      sujeto.mainPicture=newMain
    }
    
    sujeto.listingPictures=newList
    this.props.userPack.updateMyListing("fotos",sujeto)
  }
  handleDeletePicture = (id) =>{
    console.log("deleting")
    let sujeto = {mainPicture:this.props.userPack.myListing.mainPicture,listingPictures:this.props.userPack.myListing.listingPictures}
    let linkMatadero=""
    if(sujeto.mainPicture && sujeto.mainPicture.id===id){
      for (var i = 0; i < sujeto.listingPictures.length; i++) {
        const pic=sujeto.listingPictures[i]
        if (pic.url!=="1lo1"){
          linkMatadero= sujeto.mainPicture.url
          sujeto.mainPicture=pic
          const newList = sujeto.listingPictures.filter(c=>c.id!==pic.id)
          sujeto.listingPictures=newList
          break;
        }
      }
    }
    else{
      const elMio = sujeto.listingPictures.filter(c=>c.id===id)[0]
      linkMatadero= elMio.url
  
      const realList=sujeto.listingPictures.filter(c=>c.id!==id)
      sujeto.listingPictures=realList
    }

    axios.delete(linkMatadero).then(res=>{
    });
    this.props.userPack.updateMyListing("fotos",sujeto)
  }
  handleUpload = () =>{
    this.inputRef.current.click();
  }
  handleNewProgress = (progress, total)=>{
    this.setState({progressObj:{progress,total}})
  }
  dataURItoBlob = (dataURI,type='image/jpeg') => {
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    const ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: type});
    return blob;
  }
  
  handleLoadFiles = (e) =>{

    axios.defaults.headers.put['x-amz-meta-author'] = 'Janet';
    axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
    delete axios.defaults.headers.common['Authorization'];
    const files = e.target.files
    let i=0
    let sujeto = {mainPicture:this.props.userPack.myListing.mainPicture,listingPictures:this.props.userPack.myListing.listingPictures}
    const that = this
    const length = files.length
    for(;i<length;i++){
    
      let reader = new FileReader();
      reader.readAsDataURL(files[i])
      reader.onload = function(ev){
        const encodedImage = ev.target.result
        const index = encodedImage.indexOf(";")
        const index2 = encodedImage.indexOf(",")
        
        const dataType = encodedImage.substring(5,index)
        console.log(dataType,"DATATYPE","first 5",)
        if(dataType.substring(0,5)==="image"){
          that.setState({fetching:true})
          const buff = that.dataURItoBlob(encodedImage,dataType);
          const id=Math.round(Math.random()*10000000)
          const newId=that.props.userPack.userId.toString()+id.toString()
          const url = "https://carenmarketplace.s3.amazonaws.com/userImages/"+that.props.userPack.userId+"/"+newId+"."+dataType.substring(6,dataType.length)

          axios.put(url,buff,{
            onUploadProgress:progressEvent => {
              if (progressEvent.lengthComputable) {
                  console.log(progressEvent.loaded + ' ' + progressEvent.total);
                  that.handleNewProgress(progressEvent.loaded,progressEvent.total);
                  console.log("current progress is",(that.state.progressObj.progress/that.state.progressObj.total)*100)
                }
              else{ console.log("current progress is",(that.state.progressObj.progress/that.state.progressObj.total)*100)} 
              }
          }).then(res=>{
            
            if(sujeto.listingPictures[0]){
              sujeto.listingPictures.push({key:"Uploaded by FrontEnd",url:url,id:Math.round(Math.random()*100)})
              
            }else if(sujeto.mainPicture){
              sujeto.listingPictures.push({key:"Uploaded by FrontEnd",url:url,id:Math.round(Math.random()*100)})
            }else{
              sujeto.mainPicture={key:"Uploaded by FrontEnd",url:url,id:Math.round(Math.random()*100)}
            }
            if(i===length){
              delete axios.defaults.headers.put['x-amz-meta-author']
              that.setState({fetching:false})
              that.props.userPack.updateMyListing("fotos",sujeto)
              
            }
            
          })
          .catch(error=>{that.setState({fetching:false})})
          
        }
        

        
      }
    }
  }
  handleDrop = (e)=>{
    console.log("dropped some bomb",e.target)
  }
  handleDrag = (e)=>{
    e.preventDefault()
    console.log("dropped some drag",e.target)
  }
  render() { 
    const loadingPercentage=parseFloat((this.state.progressObj.progress/this.state.progressObj.total)*100).toFixed(1)
    const methods = {handleMakeMain:this.handleMakeMain,handleDeletePicture:this.handleDeletePicture}
    return (
      <>
            <section className="modal-card-body">
              <Grid container justify="center">
                <Grid item xs={12} style={{marginBottom:"20px",marginTop:"20px"}}>
                  <Grid container justify="center">
                    <Grid item xs="auto">
                      <Button variant="contained" color="secondary" onClick={this.handleUpload}>
                          Subir imagenes
                        </Button>
                      <form encType="multipart/form-data">
                            <input onDragOver={this.handleDrag} onDrop={this.handleDrop} onChange={this.handleLoadFiles} ref={this.inputRef} type="file" id="file" name="file" multiple style={{display: "none"}}/>
                        </form>
                      </Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid item xs={12}>
                    {this.state.fetching?
                    <>
                    <Grid container justify="center">
                     <Grid item xs="auto">
                       <h1>{loadingPercentage+"%"}</h1>
                      </Grid>
                      </Grid>
                      <Grid container justify="center">
                      <Grid item xs="auto">
                      <LinearProgress style={{width:"450px"}} variant="determinate" value={loadingPercentage} color="secondary" />
                      </Grid>
                      </Grid>
                    </>
                      :
                      <Grid container justify="center">
                      <Grid item xs="auto">
                      <p>Sugerimos subir al menos una foto frontal, trasera, interior delantero e interior trasero.</p>
                      </Grid>
                      </Grid>
                      }
                    
                  </Grid> 
                </Grid>


              <Grid container justify="center" spacing={1} style={{marginBottom:"20px"}}>
                {this.props.userPack.myListing.mainPicture===null?null:
                  <MainPicture picture={this.props.userPack.myListing.mainPicture} methods={methods} width={this.props.userPack.width}/>
                }
                  {this.props.userPack.myListing.listingPictures.filter(c=>c.id!==null).map((picture,index)=>(
                    
                    <Tarjetita key={index} picture={picture} index={index} methods={methods} width={this.props.userPack.width}/>
                  ))}
                  </Grid>


              </Grid>
            </section>
          </>
     );
  }
}
 
export default Fotos;