import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleLogin from 'react-google-login';
import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GTranslateIcon from '@material-ui/icons/GTranslate';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const getUrl = window.location;
let neoUrl
if(getUrl.host.includes(":3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
}else{
  neoUrl = getUrl.host
}

const baseUrl = getUrl.protocol+ "//" + neoUrl +":8181/";
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
      Carly
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const username = props.userPack.loginElements.username
  const password = props.userPack.loginElements.password
  const [fetching,setFetching] = React.useState(false);
  const [error,setError] = React.useState({status:false});
  const data = props.userPack.loginElements.data
  const componentClicked = (e) => {
    console.log(e);
  }
  const login = () =>{
    setFetching(true);
    setError({status:false});
    axios.post(baseUrl+"token-auth/",{username:username,password:password})
    .then(res=>{
        console.log(res.data,"res.data")
        //const newToken=res.data.token
        //window.localStorage.setItem('token', newToken);
        //that.getUser(newToken)
        setFetching(false);
        props.systemLogin(res.data.user,res.data.token)
        props.handleClose()
        return
    },
    error=>{
      setError({status:true,content:"Usuario ó contraseña invalida"});
      setFetching(false);
    })
  }
  async function register (myUsername,myPassword,myData){
    console.log("email recibido",myUsername)
    const isEmail = await validateEmail(myUsername)
    if(!isEmail){
      setError({status:true,content:"Por favor ingrese un correo válido"});
      setFetching(false);
      return
    }
    if(myPassword.length<8){
      setError({status:true,content:"Su contraseña debe tener 8 o más caracteres"});
      setFetching(false);
      return
    }
    setFetching(true);
    setError({status:false});
    let sendingData = {...myData}
    sendingData.username = myUsername
    sendingData.password = myPassword
    await axios.post(baseUrl+"api/existing/",sendingData)
    .then(res=>{
      if(res.data.message==="existe"){
        axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
          .then(res=>{
            console.log(res.data, "logging you in...")
            setFetching(false);
            props.systemLogin(res.data.user,res.data.token)
            handleClose()
            return
          }
          
      ).catch(err=>{
        console.log(res.data, "logging you in now...")
        axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
        .then(res=>{
            console.log(res.data,"res.data")
            //const newToken=res.data.token
            //window.localStorage.setItem('token', newToken);
            //that.getUser(newToken)
            setFetching(false);
            props.systemLogin(res.data.user,res.data.token)
            props.handleClose()
            return
        },
        error=>{
          console.log("not worked",error)
          setError({status:true,content:"Usuario ó contraseña invalida"});
          setFetching(false);
          return
        })
        setFetching(false);
      })
      }
    })
    return
  }
  const handleEnter = (e)=>{
    if(e.key=="Enter"){
      login()
    }
  }
  const responseGoogle = (e) => {
    const data = e.profileObj
    console.log(data,"esta es la data",data.email)
    props.userPack.loginElements.setUsername(data.email)
    props.userPack.loginElements.setPassword('Default3xsd1Pass')
    props.userPack.loginElements.setData(data)
    console.log(e);
    register(data.email,'Default3xsd1Pass',data)
  }
  const responseFacebook = (e) => {
    const data = e
    console.log(data,"esta es la data",data.email)
    props.userPack.loginElements.setUsername(data.email)
    props.userPack.loginElements.setPassword('Default3xsd1Pass')
    props.userPack.loginElements.setData(data)
    console.log(e);
    register(data.email,'Default3xsd1Pass',data)
  }
  const handlePassword = (e)=>{
    props.userPack.loginElements.setPassword(e.target.value)
  }
  const handleUsername = (e)=>{
    props.userPack.loginElements.setUsername(e.target.value.toLowerCase())
  }
  const handleRememberMe = (e)=>{
    console.log(e.target.value, "rem")
  }
  const handleClose=()=>{
    props.handleClose("noUlterior")
  }
  const handleForgotPassword=(e)=>{
    e.preventDefault()
    axios.post(baseUrl+"changePassword/",{email:username})
    .then(res=>{
        //const newToken=res.data.token
        //window.localStorage.setItem('token', newToken);
        //that.getUser(newToken)
        setError({status:true,content:"Te hemos enviado un correo"});
        setFetching(false);
        return
    },
    error=>{
      setError({status:true,content:"No hemos podido enviar el correo"});
      setFetching(false);
    })
  }
  return (
    
    <Container component="main" maxWidth="xs" style={{zIndex:"9999",paddingLeft:"0px !important"}}>
      <Grid container justify="flex-end">
        <Grid item xs="auto" style={{position:"relative", top:"8px",left:"17px"}}>
        <IconButton
                  edge="end"
                  aria-label="account of current user"
                  color="inherit"
                  onClick={handleClose}
                >
         <CloseIcon fontSize="large"/>
         </IconButton>
        </Grid>
      </Grid>
     
      <CssBaseline />
      <div style={{position:"relative",bottom:"45px"}}>
      <div className={classes.paper}>
      {error.status?<p style={{color:"red",marginBottom:"20px"}}>{error.content}</p>:null}      
      {fetching?<div className="lds-hourglass"></div>:null}
        
        <Avatar className={classes.avatar} >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="loginTitle">
          Inicia Sesion
        </Typography>
        <Grid container>
            <Grid item xs={12}>
              <Grid container justify="center">

                <Grid item xs="auto"  style={{marginBottom:"10px"}}>
                  <FacebookLogin
                    appId="2501770909900950"
                    callback={responseFacebook}
                    fields="name,first_name,last_name,email,picture"
                    render={renderProps => (
                      //<div class="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                      <a class="fb connect" style={{width:"300px", backgroundRepeat: "no-repeat",backgroundPosition: "14px 10px",backgroundImage:"url('/svg/fbicon.png')"}}  onClick={renderProps.onClick}>Iniciar sesión con Facebook</a>
                    )}
                  />
                </Grid>
                <Grid item xs="auto" style={{marginBottom:"18px"}}>
                  <GoogleLogin
                  scope="email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly"
                    clientId="77448197308-q4c6rku5m78pcr5vfkltp0pcn5g1hde5.apps.googleusercontent.com"
                    render={renderProps => (
                      <a class="google connect" style={{width:"300px", backgroundRepeat: "no-repeat",backgroundPosition: "12px 10px",backgroundImage:"url('/svg/googleicon.png')"}} onClick={renderProps.onClick} disabled={renderProps.disabled}>Iniciar sesión con Google</a> 
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                  </Grid>
              </Grid>

            </Grid>


          </Grid>
        <Grid item xs={12} style={{marginBottom:"10px"}}>
          <p>ó</p>
        </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            autoComplete="email"
            onChange={handleUsername}
            value={username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePassword}
            value={password}
            onKeyPress={handleEnter}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={login}
          >
            Inicia sesion
          </Button>
          <Grid container>
            <Grid item xs>
            <a href="#" onClick={handleForgotPassword} style={{color:"#3f51b5",fontSize:"16px"}}>
                Olvidaste tu contraseña?
              </a>
            </Grid>
            <Grid item>
            <a href="#" onClick={props.switch} style={{color:"#3f51b5",fontSize:"16px"}}>
                Todavia no tienes cuenta? Registrate!
              </a>
            </Grid>
          </Grid>
      </div>
      </div>
    </Container>
  );
}