import React from 'react';

import Select from 'react-select';

export default function(props){
    const handleChange=(selection)=>{
      props.updateMyListing("features",selection)
      return
    }
  const values = []
  if(props.value){
    props.value.forEach(element => {
      values.push({value:element.id,label:element.label,name:element.name})
    });
  }else{
    values=[]
  }
    return(
  

  <Select
    defaultValue={values}
    isMulti
    autoFocus
    name="colors"
    options={props.options}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={handleChange}
  />
);}