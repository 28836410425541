import React from 'react';
import Grid from '@material-ui/core/Grid';
 export default function(props){
    
    return(
      <>
        <Grid container style={{marginTop:props.userPack.width<600?"150px":"50px",marginLeft:"25px"}}>
          <Grid item xs={12}>
               <h1 style={{color:"white",width:"500px",position:"abosulte",bottom:"30px"}}>Excelente elección!</h1>
       
          </Grid>
                    <Grid item xs={12}>
                    <p style={{color:"white",position:"abosulte",top:"15px"}}>Para procesar el alquiler de su carro, es necesario que lea los términos y condiciones.</p>
                 
                    </Grid>
               
        </Grid>
      </>
    );
}

