import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Tarjetita from './Tarjetita'
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <p>close</p>
        ) : null}
      </MuiDialogTitle>
    );
  });

export default function(props){
    const handleClone = (listing)=>{
      console.log("before cloning",props.listings)
      props.handleOpen("cloneListing",listing,props.listings)

    }

    return(
        <>
        <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"Seleccione la publicacion a clonar"}</DialogTitle>
            <DialogContent>
            <Grid container justify="center">
                <Grid item xs={"auto"}>
                  {props.listings.filter(c=>c.mainPicture!=null).map((listing,index) => (
                      
                    <Grid item xs={"auto"} md={"auto"} sm={"auto"} key={index}>
                      <Tarjetita
                      key={index}
                      listing={listing}
                      handleClone={handleClone}
                      openCloneDialog={handleClone}
                      handleClose={props.handleClose}/>
                    </Grid>
                    ))}
                  
                </Grid>
            </Grid>
            </DialogContent>
        </Dialog>
        </>
    );
}