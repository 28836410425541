import React from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

const MyAvatar = (props) => {
    return(
      <>
     <Grid container justify="center" >
        <Grid item xs="auto">
          <div style={{width:"125px",height:"125px",backgroundColor:"white",borderRadius:"50%"}}>
          <Grid container justify="center" >
            <Grid item xs="auto">
              <Avatar alt="Remy Sharp" src={props.pic} style={{width:"110px",height:"110px",position:"relative",top:"7px"}}/>
            </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      </>
    );
  };

export default MyAvatar;