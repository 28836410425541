import React from 'react';
import Grid from '@material-ui/core/Grid';
 export default function(props){
    
    return(
      <>
        <Grid container style={{marginTop:"40px",marginLeft:"25px"}}>
          <Grid item xs={12}>
               <h1 style={{color:"white",width:"500px",position:"abosulte",bottom:"30px"}}>Para continuar, debemos completar tu perfil</h1>
       
          </Grid>
          {props.userPack.width<800?null:
                    <Grid item xs={12}>
                    <p style={{color:"white",position:"abosulte",top:"15px"}}>Presione ENTER para continuar</p>
                 
                    </Grid>
                  
          }
        </Grid>
      </>
    );
}

