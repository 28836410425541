import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GoogleMapReact from 'google-map-react';
import TextField from '@material-ui/core/TextField';
import RoomIcon from '@material-ui/icons/Room';

const getUrl = window.location;
let neoUrl
if(getUrl.host.includes(":3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
}else{
  neoUrl = getUrl.host
}

const baseUrl = getUrl.protocol+ "//" + neoUrl +":8181/";
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


//const AnyReactComponent = ({ text }) => <h1 className="secondTitle" style={{color:"black" }}>{text}</h1>;


const AnyReactComponent = ({ text }) => <RoomIcon style={{fontSize:"32px",color:"red",position:"relative",bottom:"28px",right:"15px"}}/>

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <p>close</p>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const DialogContent = withStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

export default function(props){
  const [markerLat,setLat] = React.useState(18.4637770)
  const [markerLng,setLng] = React.useState(-69.9336969)
  const [advance,setAdvance] = React.useState(false)
  const [step,setStep]=React.useState(0)

  const handleClose=()=>{
    props.handleClose()
  }
  const handleMarker=(e)=>{
    setAdvance(true)
    setLat(e.lat)
    setLng(e.lng)
  }
  const choose=()=>{
    props.choose(props.dialogLocation)
    props.handleClose()
  }
  const mapWidth = props.userPack.width<600?"100%":"65vw"
  const xCoordinate=parseFloat(props.dialogLocation.xCoordinate)
  const yCoordinate=parseFloat(props.dialogLocation.yCoordinate)
    return(
        <>
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Elección del lugar de encuentro"}</DialogTitle>
            <DialogContent>
                {step===0?
                    <div style={{ height: '65vh', width: mapWidth }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key:"AIzaSyBKTIq_i9gwUfAIZuVrX2HcW2gk9mb6ugc&v=3.exp&libraries=geometry,drawing,places" }}
                        defaultCenter={{lat:xCoordinate,lng:yCoordinate}}
                        defaultZoom={12}
                        onClick={handleMarker}
                    >
                    <AnyReactComponent
                            lat={xCoordinate}
                            lng={yCoordinate}
                            text="Mi Lugar"
                            />
                        </GoogleMapReact>
                </div>
                    :null}
               
            </DialogContent>
            
            <DialogActions>
                <Grid item container>
                    <Grid item xs={6}>
                        <Grid container justify="flex-start">
                            <Button color="default" onClick={handleClose}>
                                Cancelar
                            </Button>
                                    
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Button onClick={choose} color="secondary">
                                Elegir
                            </Button>
                            

                        </Grid>
                    </Grid>
                </Grid>


            </DialogActions>
        </Dialog>
        </>
    );
}

