import React from 'react';
import 'react-dates/initialize';
//import Fotos from './userdashboardcomponents/EditListingTabsComponents/Fotos';
import Fotos from './maincomponents/dialogcomponents/Fotos';
import NewAddress from './maincomponents/dialogcomponents/NewAddress';
import DetallesDelCarro from './maincomponents/dialogcomponents/DetallesDelCarro';
import DetallesAdicionales from './maincomponents/dialogcomponents/DetallesAdicionales';
import GetVerified from './maincomponents/GetVerified';
import Book from './maincomponents/Book';
import DayPickerRangeControllerWrapper from './maincomponents/dialogcomponents/AvailableDates';
import Entrega from './userdashboardcomponents/EditListingTabsComponents/Entrega';
import NewListing from './maincomponents/NewListing';
import FloatingBox from './maincomponents/FloatingBox';
import Filters from './listingscomponents/Filters';

export default function(props){
    
    return(
        <>
        <Filters userPack={props.userPack}/>
        {/* 
        <Fotos listing={props.userPack.myListing}  userPack={props.userPack}/>
        
        <Book open={true} handleClose={props.userPack.dialog.handleClose} 
            currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
        { label: 'Toyota' },
        { label: 'Honda' },
        { label: 'Maserati' },
        { label: 'Range Rover' },
        { label: 'Jeep' },
        { label: 'Volvo' },
        { label: 'Kia' },
        { label: 'Hyundai' },
        { label: 'Nissan' },
        { label: 'BMW' },
        { label: 'Mercedes' },]}
        userPack={props.userPack}/>
        */}
        {/*
        <Entrega smallScreen={props.userPack.width<600} user={props.userPack.user} updateMyListing={props.userPack.updateMyListing}/>
    */}
  {/*   <NewAddress userPack={props.userPack} open={true}/>*/}
 {/* <DetallesAdicionales userPack={props.userPack}/>  */}
  {/* <DetallesDelCarro userPack={props.userPack}/>  */}
{/* <FloatingBox userPack={props.userPack}/> */}
        </>
    );
}

