import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Name from './dialogcomponents/Name';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import IntroVerified  from './dialogcomponents/IntroVerified';
import Identity  from './dialogcomponents/Identity';
import Business  from './dialogcomponents/Business';
import Phone  from './dialogcomponents/Phone';
import Address  from './dialogcomponents/Address';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Content = (props)=>{
  switch(props.activeStep){
    case 0:
      return(
        <IntroVerified userPack={props.userPack}/>
      );
      case 1:
        return(
          <Name
          userPack={props.userPack}/>
      );
    case 2:
        return(
          <Phone
          userPack={props.userPack}/>
      );
    case 3:
      return(
        <Address
        userPack={props.userPack}/>
    );
    case 4:
      return(
        <Identity
        userPack={props.userPack}/>
    );
    /*
    case 5:
      return(
        <Business
        userPack={props.userPack}/>
    );  
    */
  }
  return null
  }

class NewListing extends Component {
  state = { 
    loading:false,
    activeStep:0,
    completed:[false,false,false,false],
    steps:['','¿Cuál es tu nombre?', 'Verifique su # de celular', 'Verifique su dirección','Verifique su identidad'],
    listing:this.props.userPack.myListing
  }
  handleKeyPress = (e)=>{
    if(e.key==="Enter"){
      if(this.state.activeStep===2){
        this.handleClose()
      }
      this.handleNext()
    }
    if(e.key==="Escape"){
      this.handleClose()
    }
  }
  handleClickAway=()=>{
    this.handleClose()
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  // change code above this line
  handleEnter() {
    this.setState({
      message: this.state.message + 'You pressed the enter key! '
    });
  }
  handleClose = ()=>{
    this.props.handleClose("noUlterior")
    
  }
  handleSave = ()=>{
    let myNewUser = this.props.userPack.user
    myNewUser.verified = true
    this.props.userPack.userAPI(myNewUser)
    this.props.handleClose()
    
  }

  handleStep =(e)=>{
    this.setState({activeStep:e})
  };

  handleBack = () => {
    this.setState({activeStep:this.state.activeStep-1})
  };

  handleNext = () => {
    this.setState({activeStep:this.state.activeStep+1})
  };

  handleComplete = (index) => {
    let completed = [...this.state.completed]
    completed[index]=true
    this.setState({completed:completed})
  };

  handleClickAway=()=>{
    this.handleClose()
  }

  render() { 
    console.log("get verified",this.props.userPack.user.verified)
    const openString = this.props.open?"modal is-active":"modal";
    const that=this;
    const userPack=this.props.userPack
    const offset=userPack.width<500?"-13px":"20px"
    const portadaUrl = userPack.width<600?"url('../formbackgroundmobile.jpg')":"url('../formbackground.jpg')"
    return ( 
      <>
      
      <div className={openString}>
        <div className="modal-background"></div>
        <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className="modal-card" style={{backgroundImage:portadaUrl,backgroundSize:"cover",borderRadius:"13px"}}>
        <div style={{position:"absolute",right:"5px"}} onClick={this.handleClose} >
              <IconButton aria-label="close"  style={{marginRight:"15px"}}>
                <CloseIcon style={{color:"white"}} />
              </IconButton>
              </div>
          <Grid container justify="center" id="getVerified">
              <Stepper margin="none" alternativeLabel nonLinear activeStep={this.state.activeStep} style={{backgroundColor:"inherit",color:"white !important"}}>
                {this.state.steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    const handleStep = ()=>{
                      that.handleStep(index)
                    }
                    return (
                      <Step index={index} key={label} {...stepProps} style={{color:"white !important"}} onClick={handleStep}>
                        <StepButton
                          completed={this.state.completed[this.state.activeStep]}
                          step={index}
                          {...buttonProps}
                        >
                          <div style={{color:"white"}}></div>
                        </StepButton>
                      </Step>
                    );
  
                })}
              </Stepper>
   
          </Grid>
          <div style={{color:"white",marginLeft:"25px",position:"relative",top:"80px"}}>
            <h1>{this.state.steps[this.state.activeStep]}</h1>
          </div>
          
          <div style={{marginTop:"40px"}}>
          <Content listing={this.props.listing} activeStep={this.state.activeStep}
          userPack={userPack} handleClose={this.handleClose}/>
          </div>

            <Grid container style={{position:"absolute",bottom:"0px"}}>
                <Grid item xs={6}>
                  <Grid container justify="flex-start">
                    <Grid item xs="auto">
                    {this.state.activeStep!==0?
                      <Button style={{color:"white"}} onClick={this.handleBack} color="default" variant="outlined">
                        Atras
                      </Button>
                        :
                        <Button style={{color:"white"}} onClick={this.handleClose} color="default" variant="outlined">
                          Cancelar
                        </Button>
                        }
                    
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      {this.state.activeStep+1===this.state.steps.length?
                         <Button style={{color:"white"}} onClick={this.handleSave} color="secondary" variant="outlined">
                         Guardar
                       </Button>
                         :
                         <Button style={{color:"white"}} onClick={this.handleNext} color="secondary" variant="outlined">
                           Continuar
                         </Button>
                         }
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
                </div>
                </ClickAwayListener>
              </div>
              </>
     );
  }
}
 
export default NewListing;