import React from 'react';
import Listings from './Listings';
import SingleListing from './SingleListing';
import MainView from './MainView';
import UserDashBoard from './DashBoard2';
import { Router } from "@reach/router";
import Perfil from './Perfil';
import Cuenta from './Cuenta';
import Loader from './maincomponents/Loader';
import TestingElement from './TestingElement';
import MakeMoney from './MakeMoney';
import User from './User';
import Chat from './maincomponents/Chat';
import MainDialogs from './maincomponents/MainDialogs';
import moment from 'moment';

import * as queryString from 'query-string';
import PrietoPopOver from './maincomponents/PrietoPopOver';

const getUrl = window.location;
let neoUrl
if(getUrl.host.includes(":3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
}else{
  neoUrl = getUrl.host
}

const baseUrl = getUrl.protocol+ "//" + neoUrl +":8181/";
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

const stringifiedParams = queryString.stringify({
  client_id: '2501770909900950',
  redirect_uri: window.location.host,
  scope: ['email'].join(','), // comma seperated string
  response_type: 'code',
  auth_type: 'rerequest',
  display: 'popup',
});

const optionalParams = (code)=>{
  const string = queryString.stringify({
  client_id: '2501770909900950',
  redirect_uri: window.location.host,
  client_secret: 'c5f7bc4d85f81c03425ad699d351f39d',
  code
});
  return string
}

const facebookLoginUrl = `https://www.facebook.com/v5.0/dialog/oauth?${stringifiedParams}`;

var W3CWebSocket = require('websocket').w3cwebsocket;

function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

const emptyListing={
    "id": null,
    "bookingHistory": [],
    "listingPictures": [
  ],
  "bankAccounts":[],
  "mainPicture": null,
    "features": [
    ],
    "vehicle": {
      "id": 666666,
      "brand": "Marca",
      "model": "Modelo",
      "year": 2019,
      "doors": 4,
      "seats": 4,
  },
    "owner": {
        "id": null
    },
    "active": true,
    "insured": true,
    "forSale": false,
    "milage": null,
    "matricula": "A",
    "fidelityRating": null,
    "cleanRating": null,
    "drivingExperienceRating": null,
    "valueForMoneyRating": null,
    "overallRating": null,
    "dailyPrice": "1800.00",
    "weeklyPrice": "10000.00",
    "performance": "30.00",
    "unit": "Km/gal",
    "additionalDetails": "",
    "category": {
      "id": 1,
      "name": "Carro"
  },
}
const emptyBooking={
  "id": null,
  "closed": false,
  "startDate": "2019-12-10T00:15:17.759822Z",
  "days": null,
  "endDate": "2019-12-15T00:15:17.759822Z",
  "comment": "",
  "fidelityRating": null,
  "cleanRating": null,
  "drivingExperienceRating": null,
  "valueForMoneyRating": null,
  "overallRating": null,
  "closed": false,
  "listing": null,
  "customer": null,
  "deliveryAddress": null
}
const currencies = [
    {
      value: 'USD',
      label: 'US$',
    },
    {
      value: 'DOP',
      label: 'RD$',
    },
    {
      value: 'EUR',
      label: '€',
    },
  
  ];

class App extends React.Component {

    login = (username,password) =>{
        axios.post(baseUrl+"token-auth/",{username:username,password:password})
        .then(res=>{
          console.log("success")
            this.systemLogin(res.data.user,res.data.token)
            return
        },
        error=>{
            return false
    })}
    systemLogin=(user,token)=>{
        window.localStorage.setItem('token',token);
        window.localStorage.setItem('userId',user.id);
        let notifications = [].concat(user.activeLeasing).concat(user.activeRenting)
        //console.log("estas iban a ser las notfs", notifications)
        const notificationClient = this.openNotifications(user.id)
        this.setState({notificationClient,notifications,user:user,fetchedUser:true,logged:true});
    }
    selectStep=(step)=>{
      let currentStep = {...this.state.steps}
      currentStep.selectedStep=step
      this.setState({steps:currentStep});
  }
    logout = () =>{
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userId');
        this.setState({fetchedUser:false,logged:false})
        window.location.href='https://carly.do'
    }
    deleteListing=(id)=>{
      this.virtualDeleteListing(id)

      this.state.myListing.listingPictures.forEach( picture => {
        axios.delete(picture.url)
      });
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.delete['Authorization']="JWT "+token
      axios.delete(baseUrl + `api/listings/`+id).then(resp=>{
        //this.reFetchUser()
      })
      
    }
    position = async () => {
      const that = this
      await navigator.geolocation.getCurrentPosition(
        position => {
          console.log(position,"position")
          that.setState({ 
          latitude: position.coords.latitude, 
          longitude: position.coords.longitude
        })}, 
        err => {
          that.setState({ 
            latitude: 18.4637770,
            longitude: -69.9336969
          })
          console.log("error ubicando cliente",err)}
      );
      console.log("lattitude",this.state.latitude)
      
    }
    fetchSupportChats=()=>{
      console.log("fetched")
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.get['Authorization']="JWT "+token
      let supportChats = []
      axios.get(baseUrl+'getSupportChats/').then(resp=>{
        resp.data.forEach(supportChat => {
          console.log("support")
          const newClient = this.openNotifications(supportChat.customer.id)

          //console.log("soy el maldito final segundo intento,client",newClient)
          console.log("bout set cux",newClient)
          supportChats.push({supportChat:supportChat,client:newClient})
        });
        this.setState({supportChats:supportChats})
        //console.log("soy el maldito final primer intento")
      }).catch(err=>console.log("error agarrando supports chats",err))
    }
    getUser=()=>{
        const token = window.localStorage.getItem('token')
        axios.defaults.headers.get['Authorization']="JWT "+token
        const userRequest = axios.get(baseUrl + `current_user/`)
        const featuresRequest = axios.get(baseUrl +"getFeatures/")
        Promise.all([userRequest,featuresRequest,]).then(res => {
          const data = res[0].data;
          const features = res[1].data;
          let newSteps = {...this.state.steps}
          //newSteps.options.features=features
          //Notifications and Chat set up

          

          let notifications =data.activeLeasing.concat(data.activeRenting)

          //
          //console.log("done!!",data)
          let newListings = []
          data.listings.forEach(listing => {
            // const rate = this.state.currency.rates[this.state.currency.selected]
            // listing.dailyPrice = listing.dailyPrice*rate
            // listing.weeklyPrice = listing.weeklyPrice*rate
            newListings.push(listing)
          }); 
          data.listings = newListings
          if(data.is_superuser){this.fetchSupportChats()}
          

          this.setState({user:data},()=>{
            this.setState({notifications,features:features,fetchedUser:true,logged:true,dialogFetching:false,steps:newSteps})
            
            /*
            const rates = res[1].data.rates
            let currency = {...this.state.currency}
            currency.rate = rates[currency.preferred]
            currency.rates = {"DOP":rates["DOP"],"EUR":rates["EUR"]}
            this.setState({currency:currency})
            */
          })
        }).catch(error=>{console.log("error getting user",error)})
    }
    fetchElements=()=>{
      delete axios.defaults.headers.get['Authorization'];
      axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
      const featuresRequest = axios.get(baseUrl +"getFeatures/")
      const statesRequest = axios.get(baseUrl +"getStates/")
      const countriesRequest = axios.get(baseUrl +"getCountries/")
      const myBooking =this.state.myBooking
      let endDate
      let startDate
      if(myBooking.endDate===null || myBooking.length<9){
        endDate=moment().add(7,'d')
      }else{
        endDate=moment(myBooking.endDate)
      }
      if(myBooking.startDate===null || myBooking.length<9){
        startDate=moment()
      }else{
        startDate=moment(myBooking.startDate)
      }
      let counter=0
      const results = axios.post(baseUrl+"getResults/",{"startDate":startDate,"endDate":endDate,"location":""})
      Promise.all([statesRequest,countriesRequest,results,featuresRequest]).then(res => {
        const features = res[3].data;
        const states = res[0].data;
        const countries = res[1].data;
        const results = res[2].data;
        let prices = []
        results.forEach(item => {
          prices.push(item.dailyPrice)
        });
        const max = Math.max.apply(Math, prices);
        const min = Math.min.apply(Math, prices);
        //const currency = res[3].data;
        let newFilters = {...this.state.filters}
        newFilters.priceMaximum = [min,max+80]
        console.log("New RANGEE ",newFilters.priceMaximum)
        //console.log("fetched data", states, countries,"resuts",results)
        this.setState({filters:newFilters,elements:{features:features,states:states,countries:countries}})
        this.setState({rawResults:results})
        counter=counter+1
        if(counter>1){
          counter=-1
          this.setState({fetched:true})
        }
      })
      axios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
      axios.get("https://api.exchangerate-api.com/v4/latest/USD").then(
        resp=>{
          let currency={selected:"DOP",rates:resp.data.rates,symbols:currencies}
          //console.log("respuesta covnersion monedas",currency)
          this.setState({currency:currency})
          counter=counter+1
          if(counter>1){
            counter=-1
            this.setState({fetched:true})
          }
          axios.defaults.proxy = null
        }
      ).catch(err=>{
        console.log("error con api monedas",err)
        console.log("using mock up data")
        let currency={selected:"DOP",symbols:currencies,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}}
        this.setState({currency:currency})
        counter=counter+1
        if(counter>1){
          counter=-1
          this.setState({fetched:true})
        }
        axios.defaults.proxy = null
      })
    }
    setUsername=(username)=>{
      this.setState({username})
    }
    setRedirectUri=(redirectUri)=>{
      this.setState({redirectUri})
    }
    setPassword=(password)=>{
      this.setState({password})
    }
    setData=(data)=>{
      this.setState({data})
    }
    setSocialLogin=(socialLogin)=>{
      this.setState({socialLogin})
    }
    socialRegister=(code)=>{
      const that = this
      console.log("redirect uri",that.state.redirectUri)
      async function fetchData(code){
      await axios.get('https://graph.facebook.com/v5.0/oauth/access_token?',{ params: {
        client_id: '2501770909900950',
        redirect_uri: window.location.host,
        client_secret: 'c5f7bc4d85f81c03425ad699d351f39d',
        code
      } }
      ).then(resp=>{
        return resp.data
      })
      
      
    }
      let data=fetchData(code)
      console.log(data,"tamo aquiii"); // { access_token, token_type, expires_in }
    }

    handleRegister = ()=>{
      const that = this
      async function register (){
        
        let myUsername = that.state.username
        let myPassword = that.state.password
        let myData = that.state.data
        let social = that.state.socialLogin
        console.log("email recibido",myUsername)
        const isEmail = await validateEmail(myUsername)
        if(!isEmail){
          return
        }
        if(myPassword.length<8){
          return
        }
        let sendingData = {...myData}
        sendingData.username = myUsername
        sendingData.password = myPassword
        await axios.post(baseUrl+"api/existing/",sendingData)
        .then(res=>{
          if(res.data.message==="existe"){
            axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
              .then(res=>{
                console.log(res.data, "logging you in...")
                that.systemLogin(res.data.user,res.data.token)
                return
              }
              
          ).catch(err=>{
            if(myData.email){
              console.log(res.data, "logging you in now...")
              axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
              .then(res=>{
                  console.log(res.data,"res.data")
                  //const newToken=res.data.token
                  //window.localStorage.setItem('token', newToken);
                  //that.getUser(newToken)
                  that.systemLogin(res.data.user,res.data.token)
                  return
              },
              error=>{
                console.log("not worked",error)
                return
              })
            }else{
            return false}
          })
          }
          if(social){
          axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
              .then(res=>{
                  console.log(res.data,"res.data")
                  //const newToken=res.data.token
                  //window.localStorage.setItem('token', newToken);
                  //that.getUser(newToken)
                  that.systemLogin(res.data.user,res.data.token)
                  return
              },
              error=>{
                console.log("not worked",error)
                return
              })
            }
    
        })
        //window.location.href = ("https://carly.do")
      }
      register()
    }
    constructor(props){
        super(props);
        
        this.position()
        this.alertRef = React.createRef();
        window.addEventListener("resize", this.updateDimensions);
        let startDateRaw = localStorage.getItem('startDate')
        let endDateRaw = localStorage.getItem('endDate')
        let location = localStorage.getItem('location')
        let startDate
        let endDate
        if(startDateRaw===null || moment(startDateRaw).isAfter(moment())){startDate=moment()}
        else{startDate=moment(startDateRaw)}
        if(endDateRaw===null){endDate=moment().add(7, 'd')}
        else{endDate=moment(endDateRaw)}
        let newBooking = emptyBooking
        newBooking.startDate=startDate
        newBooking.endDate=endDate
        let carCategory = window.localStorage.getItem('carCategory')
        carCategory?carCategory=parseInt(carCategory):carCategory=null
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        let notificationClient = null
        if(userId){
          notificationClient = this.openNotifications(userId)
        }
        
        this.state={
          filteredResults:null,
          supportChats:[],username:'',password:'',data:{},socialLogin:false,redirectUri:window.location.host,
          //searchFiltters:{priceMin:0,priceMax:1000,},
          notificationClient:notificationClient,
          chatDialog:{active:false,chatId:null},
          scrollValue:0,
          //user:baseUser,//,fetchedUser:true,
          latitude:18.4637770,longitude:-69.9336969,
          filters:{priceRange:[30,200],priceMaximum:[0,300],sortBy:null,deliveryFee:30,map:true},
          popOver:{open:false,anchorElement:null,content:null},
          elements:{states:[]},
            fetchedUser:false,
            fetched:false,
            rawResults:[],
            currency:{selected: "DOP", symbols:currencies,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}},
            vehicles:[{ label: 'Ford' },
                        { label: 'Toyota' },
                        { label: 'Honda' },
                        { label: 'Maserati' },
                        { label: 'Range Rover' },
                        { label: 'Jeep' },
                        { label: 'Volvo' },
                        { label: 'Kia' },
                        { label: 'Hyundai' },
                        { label: 'Nissan' },
                        { label: 'BMW' },
                        { label: 'Mercedes' },
                    ],
            logged:token!=null?true:false,
            dialogData:{action:"",value:null},
            dialogFetching:false,
            open:false,
            button:false,
            location:location,
            carCategory:carCategory,
            steps:{selectedStep:false,options:{
              years:["2019","2018","2017","2016","2015","2014","2013","2012","2011","2010","2009","2008"]
              ,models:[],carTypes:[],units:[],currencies:[],features:[
                {
                    "id": 1,
                    "name": "Eléctrico/Híbrido"
                },
                {
                    "id": 2,
                    "name": "Rack para bicicletas"
                },
                {
                    "id": 3,
                    "name": "Asiento para niños"
                },
                {
                    "id": 4,
                    "name": "Tracción 4x4"
                },
                {
                    "id": 5,
                    "name": "GPS"
                },
                {
                    "id": 7,
                    "name": "Bluetooth"
                },
                {
                    "id": 9,
                    "name": "Entrada USB"
                },
                {
                    "id": 10,
                    "name": "Calefacción en los asientos"
                },
                {
                    "id": 11,
                    "name": "Sunroof"
                },
                {
                    "id": 12,
                    "name": "Convertible"
                },
                {
                    "id": 13,
                    "name": "Pase para peajes"
                },
                {
                    "id": 14,
                    "name": "Amigable para perros"
                },
                {
                    "id": 15,
                    "name": "Entrada de Audio"
                }
            ]},
              selectStep:this.selectStep
            },
            payment:null,
            myListing:emptyListing,
            myBooking:newBooking
        }
        this.fetchElements()
        if(token!==null&&this.state.fetchedUser===false){
          this.getUser()
        }
        
    }
    handlePayment=(method,data)=>{
      this.setState({payment:{method:method,data:data}})
    }
    fetchModels=(brand)=>{
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.post['Authorization']="JWT "+token
      axios.post(baseUrl + `getModels/`,{"brand":brand}).then(resp=>{
        const newModels = resp.data
        let newSteps = {...this.state.steps}
        newSteps.options.models = newModels
        this.setState({steps:newSteps})
      })
    }
    handleSwitch = (action)=>{
        action==="login"?
        this.setState({dialogData:{action:"register",data:{}}})
        :
        this.setState({dialogData:{action:"login",data:{}}})
        this.setState({open:true})
    }
    setUlteriorMotive=(value)=>{
      window.localStorage.setItem('ulteriorMotive',value)
      console.log("just set ulterior: ",value)
      this.setState({ulteriorMotive:value})
    }
    removeUlteriorMotive=()=>{
      window.localStorage.removeItem('ulteriorMotive')
      console.log("just removed ulterior")
      this.setState({ulteriorMotive:null})
    }
    handleClose = (ulterior=null)=>{
      if(ulterior==="noUlterior"){
        this.removeUlteriorMotive()
        this.setState({open:false,dialogData:{action:null,value:null}})
        return
      }
      if (this.state.ulteriorMotive){
        switch(this.state.ulteriorMotive){
          case "list":
            if(this.state.logged && this.state.user.verified){
              this.removeUlteriorMotive()
              this.handleOpen("newListing",this.state.myListing,this.state.user.listings)
              return
            }
            this.handleOpen("needPowers")
            return
          case "book":
              if(this.state.logged && this.state.user.verified){
                this.removeUlteriorMotive()
                this.handleOpen("book",this.state.listingForRent)
                return
              }
              this.handleOpen("needPowers")
              return
        }
      }
      this.setState({open:false,dialogData:{action:null,value:null}})
    }
    setListingForRent = (listing)=>{
      this.setState({listingForRent:listing})
    }
    createListing = (type,action,data=null,listings)=>{
      const ownerId = parseInt(window.localStorage.getItem('userId'))
      let myListings = listings
      
      let newListing=emptyListing
      data.owner.id=ownerId
      newListing.owner.id=ownerId
      if(type==="new"){
      }
      else if(type==="clone"){
        
        newListing = data
        newListing.id=null
        newListing.listingPictures=[]
        newListing.mainPicture=null
      }else{
        newListing = data
        this.setState({myListing:newListing,dialogData:{action:action,value:data}})
        return
      }
      
      //this.setState({open:true,myListing:emptyListing,dialogData:{action:action,value:data}})
      this.setState({open:true,dialogFetching:true,myListing:newListing,dialogData:{action:action,value:newListing}},()=>{

      })
      
      const myUser = {...this.state.user}
      myListings.filter(c=>c.id!==1)
      console.log("CREATE MY LISTINGS",myListings,myUser)
      axios.post(baseUrl +"api/listings/",newListing).then(resp=>{
        //this.listingAPI();
        const myNewListings =myListings

        myNewListings.push(resp.data)
        myUser.listings=myNewListings
        const rate = this.state.currency.rates[this.state.currency.selected]
        // resp.data.dailyPrice = parseFloat(resp.data.dailyPrice*rate).toFixed(2);
        // resp.data.weeklyPrice=parseFloat(resp.data.weeklyPrice*rate).toFixed(2);
        this.setState({ulteriorMotive:null,dialogFetching:false,myListing:resp.data,user:myUser,open:true,dialogData:{action:action,value:newListing}})
        
      })
      
    }
    handleOpen = (action,data=null,next=null)=>{
      if(data!==null){
      }
      
      if(next){
      }
      const listings = next

      if(action==="cloneListing"){
        this.removeUlteriorMotive()
        //this.createListing("clone",action,data,next)
        
        let myUser={...this.state.user}
        let newListing=data
        newListing.id=null
        newListing.mainPicture=null
        newListing.listingPictures=[]
        this.setState({dialogFetching:true,dialogData:{action:action,value:newListing}})
        axios.post(baseUrl +"api/listings/",newListing).then(resp=>{
          
          let newListings = listings
          newListings.push(resp.data)
          myUser.listings=newListings
          //this.virtualUpdateListing(resp.data)
          this.setState({myListing:resp.data,user:myUser,open:true,dialogFetching:false,dialogData:{action:action,value:resp.data}})
          this.handleReFetchUser()
        })
        
        return  
      }else if(action==="newListing"){
        this.removeUlteriorMotive()
        let idRaw= localStorage.getItem("userId")
        data.owner={id:parseInt(idRaw)}
        this.createListing("new",action,data,next)
        
        return
      } else if(action=="finishListing"){
        this.createListing("finish",action,data,listings)
      }  else if(action=="book"){
        console.log("set up ulterior book",data)
        this.setState({listingForRent:data})
      }
      this.setState({dialogData:{action:action,value:data}},()=>{this.setState({open:true})})
      return
    }
    
    handleReFetchUser = () =>{
      this.getUser()
      return
    }

    switchListing=(listing=null,oldC)=>{
      if(listing===null){
        this.setState({myListing:emptyListing})
      }
      this.setState({myListing:listing},()=>{
        this.updateMyListing(["currency"],this.state.currency.selected)
      })
      
    }
    handleEmptyAddress=()=>{
      let newUser = this.state.user
      if(newUser.homeAddress){
        return
      }else{
        newUser.homeAddress={id:666666,landmark:"",line1:"",line2:"",city:"",state:"",zipcode:"",country:""}
      }
      this.setState({user:newUser})
    }
    updateMyBooking=(newBooking)=>{
      console.log("updated my BOOKING ???????")
      this.setState({myBooking:newBooking})
    }
    updateMyUser=(attribute,newValue)=>{
      let newUser = this.state.user
      if(attribute[0]==="first_name"){
        newUser.first_name = newValue
        this.setState({user:newUser})
    }
      else if(attribute[0]==="last_name"){
          newUser.last_name = newValue
          this.setState({user:newUser})
        }
        else if(attribute==="bankAccounts"){
          console.log("Bank accounts",newValue)
          newUser.bankAccounts = newValue
          this.setState({user:newUser})
          this.userAPI(newUser)
        }
        else if(attribute[0]==="countryCode"){
          newUser.countryCode = newValue
          this.setState({user:newUser})
        }
        else if(attribute[0]==="phoneNumber"){
          newValue = newValue.replace(/\D/g,'');
          newUser.phoneNumber=newValue
          this.setState({user:newUser})
        }
        else if(attribute[0]==="business"){
          newUser.business = !newUser.business
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeLandMark"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.landmark = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeLine1"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.line1 = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeLine2"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.line2 = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeCity"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.city = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeState"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.state = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeZipcode"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.zipcode = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
        else if(attribute[0]==="homeCountry"){
          this.handleEmptyAddress()
          let newHomeAddress = newUser.homeAddress
          newHomeAddress.country = newValue
          newUser.homeAddress = newHomeAddress
          this.setState({user:newUser})
        }
  }
    userAPI=(newUser)=>{

      const token = localStorage.getItem('token')
      axios.defaults.headers.common['Authorization']="JWT "+token
      console.log(" new user",newUser)
      axios.put(baseUrl +"api/customer/"+newUser.id+"/",newUser).then(resp=>{
        console.log("respuesta del servidor recibida",resp)
        this.setState({user:resp.data})
        return
      })
    }
    virtualUpdateListing=(myListing)=>{
      
      let newUser={...this.state.user}
      //let myListing = this.state.myListing
      
      const rate = this.state.currency.rates[this.state.currency.selected]

      // myListing.dailyPrice = parseFloat(myListing.dailyPrice*rate).toFixed(2);
      // //console.log("about to update price",myListing.dailyPrice)
      // myListing.weeklyPrice=parseFloat(myListing.weeklyPrice*rate).toFixed(2);
      
      this.setState({myListing})
      newUser.listings.forEach((listing,index) => {
        if(listing.id===myListing.id){
          newUser.listings[index]=myListing
          this.setState({user:newUser})
          return
        }
      });
      /*
      if(!fetched){
        newListings.push(myListing)
        newUser.listings=newListings
        console.log(newUser,"ne USER")
      }
      this.setState({user:newUser})
      */
      
    }
    virtualDeleteListing=(id)=>{
      
      let newUser={...this.state.user}

      let listings=newUser.listings.filter(c=>c.id!==id)
      newUser.listings=listings
      this.setState({user:newUser})
    }
    listingAPI = (priority="low",newListing=null)=>{
      const rate = this.state.currency.rates[this.state.currency.selected]
      
      let myListing
      if(newListing){
        myListing={...newListing}
        this.virtualUpdateListing(newListing)
      }else{myListing=this.state.myListing}

      myListing.dailyPrice = parseFloat(myListing.dailyPrice).toFixed(2);
      // //console.log("about to update price",myListing.dailyPrice)
      myListing.weeklyPrice=parseFloat(myListing.weeklyPrice).toFixed(2);
      console.log("hay bobo")
      axios.post(baseUrl +"api/listings/",myListing).then(resp=>{
        //console.log("respuesta del servidor recibida")
        //console.log("updated price",resp.data)
        let neoListing = resp.data
        // neoListing.dailyPrice = parseFloat(neoListing.dailyPrice*rate).toFixed(2);
        // neoListing.weeklyPrice = parseFloat(neoListing.weeklyPrice*rate).toFixed(2);
        this.virtualUpdateListing(neoListing)
        return
      })

    }
    updateMyListing = (name,newValue)=>{
      
      const newListing = {...this.state.myListing}

      if(name==="brand"){
        //console.log("newListing",newListing)
        newListing.vehicle.brand = newValue
        this.setState({myListing:newListing})

      }else if(name==="model"){
        //console.log("value",newValue)
        newListing.vehicle.model = newValue.label
        this.setState({myListing:newListing})
        return
      }
    else if(name==="bankAccounts"){
      //console.log("Bank Accounts",newValue)
      newListing.bankAccounts = newValue
      this.setState({myListing:newListing})
      return
    }
      else if(name==="fotos"){
        console.log("tamo aqui")
        newListing.mainPicture = newValue.mainPicture
        newListing.listingPictures = newValue.listingPictures
        const token = window.localStorage.getItem('token')
        axios.defaults.headers.post['Authorization']="JWT "+token
        console.log(newListing.mainPicture.id,"el maldito id",this.state.myListing.id,newValue.listingPictures)
        axios.post(baseUrl+'updatePictures/',{action:null,listing:this.state.myListing.id,id:this.state.user.id,mainPicture:newListing.mainPicture,pictures:newListing.listingPictures}).then(resp=>{
          console.log("recibido",resp.data.listings)
          const currentListing=this.state.myListing.id
          let neoListing = resp.data.listings.filter(c=>c.id==currentListing)[0]
          this.setState({user:resp.data,myListing:neoListing})
        }).catch(err=>{console.log("error getting help",err)})
        this.setState({myListing:newListing})
        
      return
    }
    else if(name==="features"){
      const newFeatures = []
      //console.log("features",newValue)
      if(newValue){
        newValue.forEach(feature => {
          newFeatures.push({id:feature.value,name:feature.name,label:feature.label})
        });
      }
      
      newListing.features = newFeatures
      this.setState({myListing:newListing})
      return
    }

      switch(name[0]){
            case "year":
              
              newListing.vehicle.year = newValue
              this.setState({myListing:newListing})
              return
              case "dailyPrice":
                newListing.dailyPrice = newValue
                //newListing.dailyPrice=newListing.dailyPrice*rate/oldRate
                this.setState({myListing:newListing})
                return
                case "weeklyPrice":
                  //console.log("here yet again")
                  newListing.weeklyPrice = newValue
                  this.setState({myListing:newListing})
                  return
                  case "carType":
                    newListing.category = newValue
                    this.setState({myListing:newListing})
                    return
                    case "currency":
                      
                      let newCurrency = {...this.state.currency}
                      const oldRate = newCurrency.rates[newCurrency.selected]
                      newCurrency.selected=newValue
                      const rate = parseFloat(newCurrency.rates[newCurrency.selected]).toFixed(2)
                      
                      //console.log(oldRate,rate,"tarifasss")
                      //console.log("currency selected",this.state.currency)
                      newListing.weeklyPrice=newListing.weeklyPrice*rate/oldRate
                      newListing.dailyPrice=newListing.dailyPrice*rate/oldRate
                      //console.log("newListing.dailyPrice",newListing.dailyPrice)
                      this.setState({myListing:newListing,currency:newCurrency})
                      return
                      case "units":
                        newListing.units = newValue
                        this.setState({myListing:newListing})
                        return
                        case "matricula":
                          newListing.matricula = newValue
                          this.setState({myListing:newListing})
                          return
                          case "listingPictures":
                            newListing.listingPictures = newValue
                            this.setState({myListing:newListing})
                            return
                            case "additionalDetails":
                              newListing.additionalDetails = newValue
                              this.setState({myListing:newListing})
                              return
                              case "features":
                                newListing.features = newValue
                                this.setState({myListing:newListing})
                                return
                                case "performance":
                                  newListing.performance = newValue
                                  //console.log(newValue)
                                  this.setState({myListing:newListing})
                                  return
                                  
      }
    }
    setLocation=(location)=>{
      this.setState({location:location})
    }
    getHelp =()=>{
      if(!this.state.user.is_superuser){
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.get['Authorization']="JWT "+token
      axios.get(baseUrl+'getHelp/').then(resp=>{
        console.log("got help",resp.data)
        this.setState({user:resp.data,chatDialog:{active:true,dialogId:resp.data.supportChats[0].id,type:"support"}})
      }).catch(err=>{console.log("error getting help",err)})
    }
    }
    bookingAPI = (id,message,support=false)=>{
      const that = this
      let chatss = this.state.chats
      //console.log("prime chats",chatss,support)
      function waitForSocketConnection(socket, callback){
        //console.log("socket",socket)
        socket = socket.client?socket.client:socket
        setTimeout(
            function () {
                if (socket.readyState === 1) {
                    //console.log("Connection is active")
                    if (callback != null){
                        callback();
                    }
                } else {
                    //console.log("wait for connection...")
                    waitForSocketConnection(socket, callback);
                }
    
            }, 5); // wait 5 milisecond for the connection...
    }
      //console.log(this.state.chats, "chatttts")
      
      async function Run(chatss){
        console.log("just set cliente 1",chatss)
        await that.checkConnections()
        let client
        let rawClient = null
        if(support){
          if(that.state.user.is_superuser){
            //console.log("looking for clients",id,that.state.supportChats, that.state.supportChats.filter(c=>c.supportChat.id===id))
            rawClient = await that.state.supportChats.filter(c=>c.supportChat.id===id)[0]
            console.log("just set clients 2",rawClient)
            const callback = function(chatss){
              rawClient.client?rawClient.client.send(JSON.stringify(message)):rawClient.send(JSON.stringify(message))
              //rawClient.client.send(JSON.stringify(message))
              //client.send(JSON.stringify(message))
            }
            waitForSocketConnection(rawClient,callback)
            return
          }else{
            client = that.state.notificationClient
          }
        }else{
          client = chatss.filter(c=>c.id===id)[0].client
        }
        const callback = function(chatss){
          console.log("finally client",client)
          client.client?client.client.send(JSON.stringify(message)):client.send(JSON.stringify(message))
          //client.send(JSON.stringify(message))
        }
        waitForSocketConnection(client,callback)
        
      }
      Run(chatss)

    }
    openNotifications=(id)=>{
      let protocol
      let port
      if(getUrl.protocol==="http:"){
        protocol="ws://"
        port=":8181"
      } else{
        protocol="wss://"
        port=":8282"
      }
      const that = this
      if(id!=null){
        var client = new W3CWebSocket(protocol+ neoUrl +port+"/ws/notifications/"+id+"/");

        client.onerror = (e) => {

            console.log('Connection Error',e);
        };
        
        client.onopen = (e) =>{
          console.log('WebSocket Client Connected TO MAIN NOTIFICATIONS '+id);
      
        };
        
        client.onclose = (e) => {
            console.log('echo-protocol Client Closed');
        };
        
        client.onmessage = (e) =>  {
          let data = JSON.parse(e.data)
          let newChats = [...that.state.supportChats]

            if (typeof e.data === 'string') {
              
              if(data.action){
                console.log("Received FROM NOTIFICATIONS:",data.message);
                if(data.message.id===parseInt(window.localStorage.getItem("userId"))){
                  async function process(){
                    let newSupportChat = data.message
                    if(data.action==="message"){
                      newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                      newChats.push({supportChat:newSupportChat,client:client})//that.state.supportChats.filter(c=>c.supportChat.id===newSupportChat.id)[0]})
                      that.setState({supportChats:newChats})
                      return
                    }else if(data.action==="newSupport"){
                      console.log("new support chat")
                      const newClient = await that.openNotifications(data.message.customer.id)
                      newChats.push({supportChat:data.message,client:newClient})
                      return
                    }else{
                      let notifications = data.message.activeLeasing.concat(data.message.activeRenting)
                      if(that.state.currency){
                      let neoListings = [];
                      const rate = that.state.currency.rates[that.state.currency.selected]
                      data.message.listings.forEach(listing => {
                        // listing.dailyPrice=listing.dailyPrice*rate
                        // listing.weeklyPrice=listing.weeklyPrice*rate
                        neoListings.push(listing)
                      });
                      data.message.listings = neoListings
                    }
                      that.setState({user:data.message,notifications})
                      return
                    }
                  }
                  process()


                }
                else{
                  if(that.state.user.is_superuser){
                    console.log("new support chat failure",data.action)
                      if(data.action==="newSupport"){
                        
                        const newClient = that.openNotifications(data.message.customer.id)
                        newChats = newChats.filter(c=>c.supportChat.id!=data.message.id)
                        newChats.push({supportChat:data.message,client:newClient})
                        console.log("pusssssshing")
                        that.setState({supportChats:newChats})
                        return
                      }else if(data.action==="initData"){
                        return
                      }
                      else if(data.action==="message"){
                        let newSupportChat = data.message
                        const oldChat = newChats.filter(c=>c.supportChat.id==newSupportChat.id)[0]
                        if(oldChat&&newSupportChat.closed!=oldChat.closed){
                          
                          newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                          newChats.push({supportChat:newSupportChat,client:client})//that.state.supportChats.filter(c=>c.supportChat.id===newSupportChat.id)[0]})
                          that.setState({supportChats:newChats})
                        }
                      return
                    }
                      
                      else{
                        let newSupportChat = data.message.supportChats?data.message.supportChats[0]:data.message
                        if(!newSupportChat.closed){
                          newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                          console.log(newSupportChat,data.message.id,that.state.supportChats,that.state.supportChats.filter(c=>c.supportChat.customer.id==data.message.id)[0])
                          newChats.push({supportChat:newSupportChat,client:that.state.supportChats.filter(c=>(c.supportChat.customer.id==data.message.id)||(c.supportChat.id==data.message.id))[0].client})
                          that.setState({supportChats:newChats})
                        }

                      }
                  }else{
                    let newSupportChat = data.message.supportChats?data.message.supportChats[0]:data.message
                    let newUser = {...that.state.user}
                    // if(newSupportChat.closed){
                    //   newUser.supportChats= []
                    // }else{newUser.supportChats[0]=newSupportChat}
                    newUser.supportChats[0]=newSupportChat

                    that.setState({user:newUser})
                  }

                }

              }
                
            }
        }
    return client
      }
      
    }
    openChat=(id)=>{
      let protocol
      let port
      if(getUrl.protocol==="http:"){
        protocol="ws://"
        port=":8181"
      } else{
        protocol="wss://"
        port=":8282"
      }
      const that = this
      var client = new W3CWebSocket(protocol+ neoUrl +port+"/ws/chat/"+id+"/");

          client.onerror = (e) => {

              console.log('Connection Error',e);
          };
          
          client.onopen = (e) =>{
            console.log('WebSocket Client Connected ' + id);
        
          };
          
          client.onclose = (e) => {
              console.log('echo-protocol Client Closed');
          };
          
          client.onmessage = (e) =>  {
            let data = JSON.parse(e.data)

              if (typeof e.data === 'string') {
                
                
                if(data.action){

                  //console.log("Received:",data.message);
                  if(data.message.messages){
                    if(data.message.messages[data.message.messages.length-1]&&data.message.messages[data.message.messages.length-1].sender!=this.state.user.id){
                      const audioPromise = this.alertRef.current.play()
                      if (audioPromise) {
                        audioPromise.catch((e) => {
                            if (e.name === 'NotAllowedError' ||
                                e.name === 'NotSupportedError') {
                                //console.log(e.name);
                            }
                        });
                    }
                      
                    }
                  }

                  
                  let oldBookings = that.state.notifications
                  let index
                  oldBookings.map((c,y)=>{
                    if(c.id===data.message.id){
                      index=y
                    }}
                    )
                    let current = oldBookings[index]
                  current=data.message
                  if(current.customer.id===current.listing.owner.id){
                    current.self=true
                  }
                  oldBookings[index] = current
                  that.setState({notifications:oldBookings})
                }else{
                  
                }
                  
              }
          };
      return client
    }
    checkConnections=()=>{
      const that = this
      async function Run(){
      let changed = false
      let newNotifications=false
      let notificationClient
      const userId = window.localStorage.getItem('userId')
      if(that.state.notificationClient!=null && that.state.notificationClient.readyState===WebSocket.CLOSED){
        notificationClient = that.openNotifications(userId)
        newNotifications=true
      }
      let chats = []
      if(that.state.chats){
        chats= that.state.chats
      }
      
      if(that.state.notifications){
        const myNotifications = that.state.notifications
        
        myNotifications.forEach(booking => {
          let existingBooks = chats.filter(c=>c.id==booking.id)
          if(existingBooks.length===0){
            //console.log(chats.filter(c=>c.id==booking.id).length,"chatssss")
            let client = that.openChat(booking.id)
            chats.push({id:booking.id,client})
            changed=true
            //console.log("updating chats",chats.length,client)
        }else{
          if(existingBooks[0].client.readyState === WebSocket.CLOSED){
            chats = chats.filter(c=>c.id!=booking.id)
            let client = that.openChat(booking.id)
            chats.push({id:booking.id,client})
            changed=true
          }
        }
        });
        if(changed){
          await that.setState({chats})
        }
        if(newNotifications){
          await that.setState({notificationClient:notificationClient})
        }
        }
      
      }
      Run()
    }
    handleScroll=(event)=>{
      let itemTranslate =window.scrollY
      if (this.state.scrollValue!==itemTranslate){
        //console.log(itemTranslate)
        this.setState({
          scrollValue: itemTranslate
        });
      }

  }
  
  // sendMessage=(message)=>{
  //   const token = window.localStorage.getItem('token')
  //   axios.defaults.headers.get['Authorization']="JWT "+token
  //     const that = this
  //     function waitForSocketConnection(socket, callback){
  //       setTimeout(
  //           function () {
  //               if (socket.readyState === 1) {
  //                   //console.log("Connection is active")
  //                   if (callback != null){
  //                       callback();
  //                   }
  //               } else {
  //                   //console.log("wait for connection...")
  //                   waitForSocketConnection(socket, callback);
  //               }
    
  //           }, 5); // wait 5 milisecond for the connection...
  //   }
  //     //console.log(this.state.chats, "chatttts")
  //     async function Run(){
  //       await that.checkConnections()
  //       const chat = that.state.chats.filter(c=>c.id===id)[0]
  //       const callback = function(){
  //         chat.client.send(JSON.stringify(message))
  //       }
  //       waitForSocketConnection(chat.client,callback)
        
  //     }
  //     Run()

  //   }
  // }
  switchChat=(newVal)=>{
    this.setState({chatDialog:newVal})
  }
  handleBooking=()=>{
    let startDate = this.state.myBooking.startDate
    let endDate = this.state.myBooking.endDate
    let changed=false
    if (typeof startDate === 'string') {
      startDate=moment(startDate)
      changed=true
    }else if (startDate===null){
      startDate=moment()
      changed=true
    }
    if (typeof endDate === 'string') {
      endDate=moment(endDate)
      changed=true
    }else if(endDate===null){
      endDate = moment().add(7, 'd')
      changed=true
    }
    let newBooking = this.state.myBooking
    newBooking.startDate=startDate
    newBooking.endDate=endDate
    if(changed){
      console.log("changeed?")
      this.setState({myBooking:newBooking})
    }
    
  }
  FBLogin=()=>{
    console.log("in here")
    // FB.login(function(response) {
    //   console.log("respuesta de fb",response)
    //   // handle the response
    // }, {
    //   scope: 'email', 
    //   return_scopes: true
    // });
  }
  mainScrollDown = ()=>{
    const elmnt = document.getElementById("variedad");
    elmnt.scrollIntoView(
      {behavior: "smooth", block: "center", inline: "nearest"}
    )
  }
    fDidMount(){
      const token=window.localStorage.getItem('token')
      if(token!==null&&this.state.fetchedUser===false){
        this.getUser()
        this.checkConnections()
      }
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
    }
    componentDidUpdate() {
      this.checkConnections()
      this.handleBooking()
      this.updateDimensions();
      this.handleScroll();
    };
    componentWillUnmount(){
      window.removeEventListener("resize", this.updateDimensions);
      window.removeEventListener('scroll', this.handleScroll);
    };
    updateDimensions = () => {
      
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName('body')[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
        if(height-this.state.height<60 &&height-this.state.height>0 || width-this.state.width===0){
          return
        }
        if (width < 960){
          this.setState({width:width, height:height, isMobile:true});
          return
        }
        this.setState({width:width, height:height, isMobile:false})
    };
    setCarCategory=(e)=>{
      window.localStorage.setItem("carCategory",e)
      this.setState({carCategory:parseInt(e)})
    }
    setCurrency=(value)=>{
      let newCurrency = {...this.state.currency}
      newCurrency.selected=value
      this.setState({currency:newCurrency},()=>{this.updateMyListing(["currency"],value)})
      
    }
    setFilteredResults =(filteredResults)=>{
      this.setState({filteredResults})
    }
    handleFilters =(filters)=>{
      this.setState({filters})
    }
    closeSupport=(id)=>{
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.common['Authorization']="JWT "+token
      axios.post(baseUrl + `closeSupport/`,{customerId:id}).then(resp=>{
        if("SUCCESS"===resp.data){
          console.log(resp.data,"respuesta close sup")
          let newUser = {...this.state.user}
          newUser.supportChats=[]
         
          if(newUser.is_superuser){
            let allChats=[...this.state.supportChats]
            allChats=allChats.filter(c=>c.supportChat.id!=id)
            console.log("reduccionxx",allChats)
            this.setState({supportChats:allChats,chatDialog:{active:false,dialogId:null}})
          }else{
            this.setState({user:newUser,chatDialog:{active:false,dialogId:null}})
            
          }
          
        }
        
      }).catch(err=>{
        console.log(err)
        this.setState({chatDialog:{active:false,dialogId:null}})
      })
    }
  setPopOver=(popOver)=>{
    this.setState({popOver})
  }
    
  render() {
    
    const filteredResults = this.state.filteredResults
    let results= [...this.state.rawResults]
    if(filteredResults){
      results=[...this.state.filteredResults]
    }
    let newFilters = {...this.state.filters}
    newFilters.carCategory = this.state.carCategory
    const userPack = {dialog:{fetched:this.state.dialogFetching,data:this.state.dialogData,
      open:this.state.open,button:this.state.button, handleClose:this.handleClose,
      
        handleOpen:this.handleOpen,handleSwitch:this.handleSwitch},
        filters:newFilters,
        filteredResults:this.state.filteredResults,
        filterMethods:{setFilteredResults:this.setFilteredResults,setCarCategory:this.setCarCategory,
          setLocation:this.setLocation,handleFilters:this.handleFilters
        },
        rawResults:this.state.rawResults,
        elements:{...this.state.elements, latitude:this.state.latitude,longitude:this.state.longitude,currency:this.state.currency},
        setCurrency:this.setCurrency,
        bookingAPI:this.bookingAPI,popOver:this.state.popOver,setPopOver:this.setPopOver,
        loginElements:{handleRegister:this.handleRegister,socialRegister:this.socialRegister,redirectUri:this.state.redirectUri,
          data:this.state.data,setSocialLogin:this.setSocialLogin,setRedirectUri:this.setRedirectUri,
          setData:this.setData,username:this.state.username,password:this.state.password,socialLogin:this.state.socialLogin,
          setUsername:this.setUsername,setPassword:this.setPassword},
        supportChats:this.state.supportChats,
        notifications:this.state.notifications,
        results:results,
        login:this.login,
        closeSupport:this.closeSupport,
        getHelp:this.getHelp,
        logout:this.logout,
        fetchedUser:this.state.fetchedUser,
        logged:this.state.logged,
        switchChat:this.switchChat,
        systemLogin:this.systemLogin, height:this.state.height,
        verified:this.state.user?this.state.user.verified:null,
        userId:this.state.user?this.state.user.id:null,
        listings:this.state.user?this.state.user.listings:[""],
        allListings:this.state.user?this.state.user.listings:[""],
        width:this.state.width,
        units:{all:[{
            value: 'Km/gal',
            label: 'Km/gal',
          },
          {
            value: 'Mi/gal',
            label: 'Mi/gal',
          }]},
        mainScrollDown:this.mainScrollDown,
        reFetchUser:this.handleReFetchUser,
        fetchModels:this.fetchModels,
        isMobile:this.state.width<960,
        steps:this.state.steps,
        profilePicture:this.state.user?this.state.user.profilePicture:null,
        myListing:this.state.myListing,
        user:this.state.user?this.state.user:{string:"eta mierda ni existe",user:this.state.user},
        updateMyListing:this.updateMyListing,
        updateMyUser:this.updateMyUser,
        userAPI:this.userAPI,
        FBLogin:this.FBLogin,
        listingAPI:this.listingAPI,
        deleteListing:this.deleteListing,
        switchListing:this.switchListing,
        chatDialog:this.state.chatDialog,
        scrollValue:this.state.scrollValue,
        ulteriorMethods:{removeUlteriorMotive:this.removeUlteriorMotive,setUlteriorMotive:this.setUlteriorMotive},
        book:{myBooking:this.state.myBooking,updateMyBooking:this.updateMyBooking,
          location:this.state.location,payment:this.state.payment,handlePayment:this.handlePayment,
          bookingAPI:this.bookingAPI,listingForRent:this.state.listingForRent,setLocation:this.setLocation,
          latitude:this.state.latitude,longitude:this.state.longitude,setListingForRent:this.setListingForRent
        }
      }
    return(
        <>
        <audio ref={this.alertRef}>
          <source src="mp3/alert.mp3" type="audio/mpeg"/>
        </audio>
        {userPack.chatDialog.active?
        <Chat userPack={userPack}/>
        :null}
        <MainDialogs userPack={userPack}/>
        <Router>

            <MainView userPack={userPack} path="/"/>
            <MakeMoney userPack={userPack} path="/makemoney"/>
            {this.state.fetchedUser
            ?
            <UserDashBoard path="dashboard" userPack={userPack} user={this.state.user} vehicles={this.state.vehicles}/>
            :<Loader userPack={userPack} path="dashboard"/>}
            <Listings userPack={userPack} path="/listings" fetched={this.state.fetched} results={results} />
            <Perfil userPack={userPack} path="/profile" />
            <User userPack={userPack} path="/user/:listingId" />
            <Cuenta userPack={userPack} path="/account" />
            <SingleListing userPack={userPack} path="/listings/:listingId" />

            {this.state.fetchedUser
            ?
            <TestingElement userPack={userPack} listing={userPack.listings} path="testing" />
            :<Loader userPack={userPack} path="testing"/>}
        </Router>
        </>
    );
  }
}

export default App;