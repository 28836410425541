import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


const Tarjeta = (props) => {
  console.log("TARJETA",props.listing.mainPicture)
    const url = "url('"+ props.listing.mainPicture.url+"')"

    const handleClone = () => {
      console.log("inside tarjetita",props.listing)
      props.handleClone(props.listing)
    }

    return(
        <>
        <a href="#" onClick={handleClone}>
          <div style={{backgroundImage:url,
                     backgroundSize:"100%",
                     backgroundPosition:"center",
                     backgroundRepeat:"no-repeat",
            //borderRadius:"10px",
            height: "auto",
            width:"auto",
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)'}}>
              <Box style={{height:"200px",width:"330px"}}>
              </Box>
          </div>
          <Grid container justify="center" alignItems="center" alignContent="center">
            <Grid item xs={"auto"}>
              <Typography variant="h6">
                {props.listing.vehicle.brand+" "+props.listing.vehicle.model+" "+props.listing.vehicle.year}
              </Typography>
            </Grid>
            </Grid>
            </a>
    </>
    );
  };

export default Tarjeta;

//onClick={props.actions.openEditListingModal}