import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import ModalTabs from './ModalTabs';
import Detalles from '../maincomponents/dialogcomponents/DetallesDelCarro';
import Fotos from '../maincomponents/dialogcomponents/Fotos';
import DetallesAdicionales from '../maincomponents/dialogcomponents/DetallesAdicionales';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'static',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Panel = (props) => {
  const userPack = props.userPack
  const padding = props.userPack.width<960?"0px":"50vh"
  const listing = props.listing
  switch(props.selectedTab){
    case 3:
      return(
        <div style={{marginTop:"15px",paddingLeft:padding,paddingRight:padding}}>
        <h1>Coming Soon</h1>
        </div>
        /* 
        <Calendar
        height={height}
        isMobile={props.isMobile}
        listed={listed}
        listing={listing}/>
        */
       
      );
          case 0:
              return(
                <div style={{marginTop:"15px",paddingLeft:padding,paddingRight:padding}}>
                <Detalles listing={listing} userPack={userPack}/>
                </div>
              );
              case 2:
                  return(
                    <div style={{marginTop:"15px",paddingLeft:padding,paddingRight:padding}}>
                    <DetallesAdicionales userPack={userPack}/>
                    </div>
                  );
                  case 1:
                    return(
                      <Fotos userPack={userPack}/>
                    );
  }
}

export default function EditFinal(props) {
  const classes = useStyles();
  const height = props.height+"px";
  let [tab, setTab] = React.useState(0);
  const listed = props.listing.listed
  const listing = props.listing
  const handleTab = (tab) =>{
      setTab(tab);
      return
  }
  const userPack = props.userPack
  const isMobile = props.userPack.width<960
  

  const handleClose = ()=>{
    const rate = props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]
    let myListing = props.userPack.myListing;
    // myListing.dailyPrice=myListing.dailyPrice/rate
    // myListing.weeklyPrice=myListing.weeklyPrice/rate
    // props.userPack.listingAPI()
    props.handleClose()
  }
  const handleSave = ()=>{
    props.handleClose()


    props.userPack.listingAPI()
  }
  

  return (

    <div>
      <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <ModalTabs
              selectedTab={tab}
              selectTab={handleTab}
            /> 
          </Grid>
            <Grid item xs={12}>
              <div style={{backgroundColor:"#ffffff"}}>
              <Panel selectedTab={tab} userPack={userPack} isMobile={props.isMobile} listing={listing}/>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{position:"fixed",bottom:"20px"}}>
            <Grid item xs={6}>
              <Grid container justify="center">
                <Grid item xs="auto">
                      <Button variant="outlined" color="default" onClick={handleClose}>
                      cancelar
                    </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center">
                <Grid item xs="auto" style={{position:"relative",right:"20px"}}>
                <Typography variant="h6" className={classes.title}>
                    <Button variant="outlined" color="secondary" onClick={handleSave}>
                    guardar
                  </Button>
                </Typography>

                </Grid>
              </Grid>
            </Grid>
              </Grid>





      </Dialog>

    </div>
  );
}