import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const AirbnbSlider = withStyles({
  root: {
    color: 'rgb(245, 0, 87)',
    height: 3,
    padding: '13px 0',
    marginTop: 12,
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0px 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 7,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}
const PrettoSlider = withStyles({
  root: {
    color: 'rgb(245, 0, 87)',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function lowPriceComparator(a,b) {
  return a.dailyPrice - b.dailyPrice
}

function highPriceComparator(a,b) {
  return  b.dailyPrice- a.dailyPrice
}

//homes.sort(mycomparator);

export default function(props){
  const rate = props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [priceRange, setPriceRange] = React.useState([props.userPack.filters.priceRange[0]*(rate/rate),props.userPack.filters.priceRange[1]*(rate/rate)])
  const [priceMaximum, setPriceMaximum] = React.useState([0,props.userPack.filters.priceMaximum[1]]);
  const [deliveryFee, setDeliveryFee] = React.useState(20);
  const handleClose =()=>{
      let popOver = props.userPack.popOver
      popOver.open = false
      props.userPack.setPopOver(popOver)

      let newFilters = props.userPack.filters
      newFilters.active=false
      props.userPack.filterMethods.handleFilters(newFilters)
    }
    const classes = useStyles();
    const toggleDrawer = (open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      let newFilters = props.userPack.filters
      newFilters.active = open
      props.userPack.filterMethods.handleFilters(newFilters)
    };
    const handleClear =()=>{
      let newFilters = {priceRange:[30,200],priceMaximum:[0,300],sortBy:null,deliveryFee:30,map:true}
      props.userPack.filterMethods.handleFilters(newFilters)
      props.userPack.filterMethods.setFilteredResults(null)
    }
    const filterHighPrice =()=>{
      let newFilters = props.userPack.filters
      newFilters.sortBy = 'high'
      props.userPack.filterMethods.handleFilters(newFilters)
      let filteredResults = props.userPack.results.sort(highPriceComparator)
      props.userPack.filterMethods.setFilteredResults(filteredResults)
    }
    const filterLowPrice =()=>{
      let newFilters = props.userPack.filters
      newFilters.sortBy = 'low'
      props.userPack.filterMethods.handleFilters(newFilters)
      let filteredResults = props.userPack.results.sort(lowPriceComparator)
      props.userPack.filterMethods.setFilteredResults(filteredResults)
    }
    const filterRelevancia =()=>{
      let newFilters = props.userPack.filters
      newFilters.sortBy = null
      props.userPack.filterMethods.handleFilters(newFilters)
      props.userPack.filterMethods.setFilteredResults(null)
    }
    const handlePrice =(e,priceRange)=>{
      // let newFilters = {...props.userPack.filters}
      // newFilters.priceRange = [parseInt(priceRange[0]/rate),parseInt(priceRange[1]/rate)]
      // props.userPack.filterMethods.handleFilters(newFilters)
      
      async function run(props){
        await setPriceRange([priceRange[0]/rate,priceRange[1]/rate])
        let filteredResults
        if (false){
          filteredResults = props.userPack.filteredResults
        }else{filteredResults = [...props.userPack.rawResults]}
        if(props.userPack.filters.sortBy){
          if(props.userPack.filters.sortBy==='high'){
            filteredResults = filteredResults.sort(highPriceComparator)
          }else{filteredResults = filteredResults.sort(lowPriceComparator)}
        }
        let newFilteredResults = []
        filteredResults.forEach(listing => {
          if(listing.dailyPrice*rate>priceRange[0]&&listing.dailyPrice*rate<priceRange[1]){
            newFilteredResults.push(listing)
          }
        });
        props.userPack.filterMethods.setFilteredResults(newFilteredResults)
      }
      run(props)
      // let filteredResults = props.userPack.results.sort(lowPriceComparator)
      // props.userPack.filterMethods.setFilteredResults(filteredResults)
    }
    const handleDeliveryFee=(e,deliveryFee)=>{
      setDeliveryFee(deliveryFee)
    }
    const open = props.userPack.filters.active?true:false
    const id = open ? 'simple-popover' : undefined;
    const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;

    const list = anchor => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
          <Grid container style={{height:props.userPack.height*2/3+"px"}}>
            <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
            ))}
            </List>
            <Divider />
            <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
            ))}
            </List>
          </Grid>

      </div>
    );
    return(
        <>
        {props.userPack.width>960?
        <>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={12} style={{height:"52px",overflow:"hidden"}}>
            <Grid container>

            <Grid item xs="auto" id="sortByFilter" className="popOverTrigger" style={{paddingRight:"10px",paddingTop:"10px",paddingBottom:"10px",paddingLeft:"15px"}}>
                <div id="customPopOver" style={{top:"115px",left:"15px"}}>
                  <Grid container>
                    <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox checked={props.userPack.filters.sortBy===null} onClick={filterRelevancia} name="checkedA" />}
                    label="Relevancia"
                  />
                    </Grid>
                    <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox checked={props.userPack.filters.sortBy==='low'} onClick={filterLowPrice} name="checkedA" />}
                    label="Precio: menor a mayor"
                  />                    
                      </Grid>
                    <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox checked={props.userPack.filters.sortBy==='high'} onClick={filterHighPrice} name="checkedA" />}
                    label="Precio: mayor a menor"
                  />                     
                    </Grid>
                  </Grid>



                </div>
                {props.userPack.filters.sortBy?
                  <Chip
                  deleteIcon={<DoneIcon style={{color:"green"}}/>}
                  clickable
                  id="sortBy"
                  label="ORDENAR POR"
                  />
                  :
                  <Chip
                  clickable
                  id="sortBy"
                  label="ORDENAR POR"
                  />
                  }
                      
            </Grid>

            <Grid item xs="auto" className="popOverTrigger" style={{padding:"10px"}}>
            <div id="customPopOver" style={{top:"115px",left:"150px"}}>
              <p style={{fontWeight:"bold",paddigBottom:"12px"}}>{selectedSymbol+parseInt(priceRange[0]*rate)+" - "+selectedSymbol+parseInt(priceRange[1]*rate)}</p>
                <AirbnbSlider
                  max={priceMaximum[1]*rate}
                  min={priceMaximum[0]*rate}
                  ThumbComponent={AirbnbThumbComponent}
                  getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                  defaultValue={[parseInt(priceRange[0]*rate),parseInt(priceRange[1]*rate)]}
                  onChange={handlePrice}
                />
                    </div>
                  {props.userPack.filters.priceRange?
                    <Chip
                    deleteIcon={<DoneIcon style={{color:"green"}}/>}
                    label="PRECIO"
                    id="priceRange"
                    />
                    :
                  <Chip
                  clickable
                  id="priceRange"
                  label="PRECIO"
                  />
                  }


            </Grid>
            <Grid item xs="auto" className="popOverTrigger" style={{padding:"10px"}}>
            <div id="customPopOver" style={{top:"115px",left:"238px"}}>
            <p style={{fontWeight:"bold"}}>{"Gratis - "+selectedSymbol+deliveryFee}</p>
              <PrettoSlider max={100*rate} onChange={handleDeliveryFee} valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={20*rate} />
                    </div>
                              {props.userPack.filters.deliveryFee?
                <Chip
                deleteIcon={<DoneIcon style={{color:"green"}}/>}
                label="ENTREGA"
                id="deliveryFee"/>
                :
                  <Chip
                  clickable
                  label="ENTREGA"
                  id="deliveryFee"
                  />
                  }

            </Grid>
            <Grid item xs="auto" className="popOverTrigger" style={{padding:"10px"}}>
            <div id="customPopOver" style={{top:"115px",right:props.userPack.width<1140?props.userPack.width/3+"px":"",left:props.userPack.width<1140?"":"338px"}}>
                    <h1>Test</h1>
                    </div>
                              {props.userPack.filters.sortBy?
                              <Chip
                              id="otherFilters"
                              deleteIcon={<DoneIcon style={{color:"green"}}/>}
                              label="OTROS FILTROS"/>
                      
                  :
                  <Chip
                  clickable
                  id="otherFilters"
                  label="OTROS FILTROS"
                  />
                  }
                </Grid>
            <Grid item xs="auto" style={{padding:"10px"}}>
            <Chip
                    label="BORRAR"
                    onClick={handleClear}
                    clickable
                    />

            </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        </>:
    <>
    {['bottom'].map(anchor => (
       <React.Fragment key={anchor}>
       <SwipeableDrawer
         anchor={anchor}
         open={props.userPack.filters.active?props.userPack.filters.active:false}
         onClose={toggleDrawer(false)}
         onOpen={toggleDrawer(true)}
       >
         {list(anchor)}
       </SwipeableDrawer>
     </React.Fragment>
    ))}
    </>
    }
        </>
    )
}