import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Checkbox = props => <input type="checkbox" {...props} />;

var StatesField = createClass({
	displayName: 'StatesField',
	propTypes: {
		label: PropTypes.string,
		searchable: PropTypes.bool,
	},
	getDefaultProps () {
		return {
			label: 'States:',
			searchable: true,
		};
	},
	getInitialState () {
		return {
			country: 'AU',
			disabled: false,
			searchable: this.props.searchable,
			selectValue: 'new-south-wales',
			clearable: true,
			rtl: false,
		};
	},
	clearValue (e) {
        console.log("cleadrered")
        this.select.setInputValue('');
        this.props.findListing("clear")
	},
	switchCountry (e) {
		var newCountry = e.target.value;
		this.setState({
			country: newCountry,
			selectValue: null,
		});
	},
	updateValue (newValue) {
        this.props.findListing(newValue)

	},
	focusStateSelect () {
		this.select.focus();
	},
	toggleCheckbox (e) {
		let newState = {};
		newState[e.target.name] = e.target.checked;
		this.setState(newState);
	},
	render () {
		return (
			<>
			<div className="selectionBox" style={{width:"100%",paddingTop:"10px"}}>
				<Select
					id="state-select"
					ref={(ref) => { this.select = ref; }}
					onBlurResetsInput={false}
					onSelectResetsInput={false}
					options={this.props.values}
					simpleValue
					isClearable={this.state.clearable}
					name="selected-state"
					disabled={this.state.disabled}
					value={this.props.value}
					onChange={this.updateValue}
					rtl={this.state.rtl}
					searchable={this.state.searchable}
					
				/>
			</div>
			</>
		);
	}
});


export default StatesField;