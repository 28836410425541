import React from 'react';

function PrietoPopOver(props){
    if(props.popOver.open){
        const element = props.popOver.anchorElement
        const position=element.getBoundingClientRect()
        console.log("qloq ", position.top+element.clientHeight)
    }
    // let popOverElement = document.createElement('h1');
    // popOverElement.id = 'customPopOver'
    // popOver.anchorElement.appendChild(popOverElement)
    return(
        <>
        {props.popOver.open?
            null
            :null}

        </>
    )
}

export default PrietoPopOver;