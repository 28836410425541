import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  
  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

 export default function(props){
    const [values, setValues] = React.useState({
        textmask: '',
        countryCode: '',
    });
      const handleChange = name => event => {

        props.userPack.updateMyUser([name],event.target.value)
      };

    const margin=props.userPack.width<700?"0px":"70px"
    return(
      <>
      <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
      <Grid container style={{marginTop:"5px"}}>
          {/* 
          <Grid item xs={5}>
              
          <InputLabel id="standard-required" htmlFor="formatted-text-mask-input">Codigo de su pais</InputLabel>
            
          <FormControl>
            <Input
            autoFocus
            value={props.userPack.user.countryCode}
            onChange={handleChange('countryCode')}
            id="standard-required"
            />
            </FormControl>
            
          </Grid>
          */}

          <Grid item xs={12} style={{paddingLeft:"50px",paddingRight:"50px"}}>
          <FormControl style={{width:"100%"}}>
            <InputLabel id="standard-required" htmlFor="formatted-text-mask-input">Número de celular</InputLabel>
              <Input style={{width:"100%"}}
              autoFocus
              required
              value={props.userPack.user.phoneNumber}
              onChange={handleChange('phoneNumber')}
              id="standard-required"
              inputComponent={TextMaskCustom}
              />
          </FormControl>
              </Grid>
 
          
          </Grid>

</div>
{props.userPack.width>350?
      <Grid container justify="center">
        <Grid item xs="auto">
        <p style={{position:"absolute",bottom:"30px",right:props.userPack.width<760?props.userPack.width/4+"px":props.userPack.width/5+"px",color:"white"}} className="helpText">Los campos con * son obligatorios</p>
        </Grid>
      </Grid>

        :null}
      </>
    );
}

