
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tarjeta from './Tarjeta';
import SelectListing from './SelectListing';

class AdministrarListings extends Component {

    state={
        findListing:{active:false,id:null},
        value:null
    }
    //Do handling for deleting listing
    handleAddListing = () => {
        this.props.handleOpen("list")
    }
    findListing = (newValue)=>{
        newValue===null?this.setState({findListing:{active:false,id:null},value:null}):this.setState({findListing:{active:true,id:newValue.id},value:newValue})
    }

    render() {
        const value = this.state.value
        let myCars = []
        this.props.listings.forEach(listing => {
            if(listing.vehicle!==null){
                const carName = listing.vehicle.brand+" " + listing.vehicle.model+" " + listing.vehicle.year
                myCars.push({value:carName,label:carName,id:listing.id})
            }
        });
        const listings = this.state.findListing.active?this.props.listings.filter(c=>c.id===this.state.findListing.id):this.props.listings
        const TitleMobile = () =>{
            return(
                <>
                <Grid container spacing={4} alignItems="flex-end" justify="center" style={{marginTop:"2px"}}>
                    <Grid item xs="auto" >
                        <Typography variant="h4" >
                            Mis Vehículos
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Grid container alignContent="flex-end" alignItems="flex-end">
                            <Fab onClick={this.handleAddListing} size="medium" color="secondary" aria-label="add">
                                <AddIcon />              
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginLeft:"17vw",marginRight:"17vw",marginBottom:"15px"}}>
                    <SelectListing value={value} values={myCars} findListing={this.findListing}/>
                </Grid>
                

                </>
            );
        }
        const TitleDesktop = () =>{
            return(
                <>
                <Grid container spacing={4} alignItems="flex-end" style={{marginTop:"0px"}}>
                        <Grid item xs={1} sm={1} md={2}>
                        </Grid>
                        <Grid item xs="auto" >
                            <Typography variant="h4" >
                                Mis Vehículos
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                        <SelectListing value={value} values={myCars} findListing={this.findListing}/>
                        </Grid>
                        <Grid item xs="auto">
                            <Grid container alignContent="flex-end" alignItems="flex-end">
                            <Fab onClick={this.handleAddListing} size="medium" color="secondary" aria-label="add">
                                <AddIcon />              
                            </Fab>
                            </Grid>
                        </Grid>
                        <Grid item xs={false} md={3} sm={2}>
                            </Grid>
                    </Grid>
                </>
            );
        }
        return (
        <>
        <Grid container alignItems="center" justify="center" style={{marginBottom:"20px"}}>
            <Grid item xs={false} md={2}>
            </Grid>
            <Grid item xs={12} sm={12}>
                {this.props.width>700?
                <TitleDesktop/>
                :
                <TitleMobile/>
            }
                    <Grid container spacing={4} alignItems="center" justify="center">
                        {listings.map((listing) => (
                            <Grid item xs={"auto"} md={"auto"} sm={"auto"} key={listing.id}>
                                <Tarjeta yes={this.props.yes}
                                user={this.props.user}
                                listingAPI={this.props.listingAPI}
                                switchListing={this.props.switchListing}
                                handleOpen={this.props.handleOpen}
                                dialog={this.props.openDialog}
                                actions={this.props.actions} 
                                listing={listing}
                                key={listing.id}/>
                            </Grid>
                        ))}
                    </Grid>
            </Grid>
            <Grid item xs={false} md={2}>
            </Grid>
        </Grid>
        
    </>
    );}}

export default AdministrarListings;