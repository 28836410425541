import Modal from '@material-ui/core/Modal';
import Login from './Login';
import Register from './Register';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex:"9999 !important",
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function LoginModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={props.open}
    onClose={props.onClose}
  >
    <div style={modalStyle} className={classes.paper}>
      {props.register?
        <Register login={props.login} state={props.register} switch={props.switch} userPack={props.userPack} handleClose={props.onClose} systemLogin={props.systemLogin}/>
        :
        <Register login={props.login} state={props.register} userPack={props.userPack} switch={props.switch} handleClose={props.onClose} systemLogin={props.systemLogin}/>
        }
      
    </div>
  </Modal>
  );
}

