import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

export default function(props){
    const handleSelect=(e)=>{
        e.preventDefault()
        props.handleCurrency(props.label)
    }
    return(
        <>
        <Grid item xs={12} >
            <Grid container style={{width:"110px"}}>
            <Grid item xs="auto" style={{width:"110px",fontSize:"15px !important",color:"black !important"}} className="currencyItem">

    
                <a onClick={handleSelect} href="" style={{color:"black"}}>
                <p style={{fontSize:"15px",borderRadius:"10px",paddingBottom:"5px",paddingTop:"5px",paddingLeft:"5px",paddingRight:"5px"}}label={props.label}  symbol={props.symbol}>{props.label+" ("+props.symbol+")"}</p>
                <Divider />
                </a>
                </Grid>
            </Grid>
        </Grid>

    </>
    )
}