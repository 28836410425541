import React from 'react';
import Grid from '@material-ui/core/Grid';
import NavBar from './maincomponents/NavBar';
import AppBar from '@material-ui/core/AppBar';

const Cuenta = (props) => {
    return(
      <>
      <AppBar elevation={0} style={{backgroundColor:"white"}}>
        <NavBar userPack={props.userPack}/>
      </AppBar>
      <Grid style={{backgroundColor:"white",paddingTop:"75px",paddingLeft:"20px",paddingRight:"20px",}}
       container spacing={3}>
  
          <Grid item xs={12}>
            <h1>Coming Soon</h1>
          </Grid> 
     
      </Grid>
      </>
    );
  };

export default Cuenta;