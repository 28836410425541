import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ImageIcon from '@material-ui/icons/Image';
import DetailsIcon from '@material-ui/icons/Details';
import Grid from '@material-ui/core/Grid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {/* <Box p={3}>{children}</Box> */}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}



export default function ModalTabs(props) {
  function handleChange(event, newValue) {
    props.selectTab(newValue);
  };

  return (
      <>
    <AppBar position="static" color="inherit" elevation={0}>
    <Grid container alignItems="center" alignContent="center"justify="center">
    <Tabs
      value={props.selectedTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="scrollable force tabs example"
    >
      <Tab label="Detalles Generales" icon={<DetailsIcon />} {...a11yProps(0)} />
      <Tab label="Fotos" icon={<ImageIcon />} {...a11yProps(1)} />
      <Tab label="Detalles Adicionales" icon={<DetailsIcon />} {...a11yProps(2)} />
      <Tab label="Calendario" icon={<DateRangeIcon />} {...a11yProps(3)} />
      
      
      
    </Tabs>
    </Grid>
  </AppBar>
  </>
  );
}