import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function(props){
    const width = props.width
    const maxWidth = props.isMobile?width-120+"px":width/3-70+"px"
    const Comment = ()=>{
        return(
            <div>
            <Grid container>
                            <Typography style={{fontWeight:"bold",color:"black"}}>
                                Excelente experiencia!
                            </Typography>
                <Grid item xs={12}>
                            <Box component="fieldset" borderColor="transparent">
                                <Rating value={4} readOnly size="small"/>
                            </Box>
                </Grid>
                <Grid item xs={12}>
                     <Typography style={{color:"black"}}>
                        Joan me respondia rapido, me entregó el vehiculo a tiempo y accedio a mis peticiones
                      </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption" style={{color:"black"}}>
                        <a href="#">Jerson A. Mendez</a> - 29 de Septiembre 2019
                    </Typography>
                </Grid>
            </Grid>
            </div>
        )
    }
    let commentPercentage = ""
    if(props.width<380){
        commentPercentage="70%"
    } else if(props.width<600){
        commentPercentage="75%"
    }else{
        commentPercentage="80%"
    }
    return(
        <>
            <Grid container>
                <Grid item xs="auto">
                    <Avatar alt="Remy Sharp" src="/img/Koala.jpg" style={{width:"80px",height:"80px"}}/>
                </Grid>
                <Grid item xs="auto" style={{width:commentPercentage,paddingLeft:"12px"}}>
                    <Comment/>
                </Grid>
            </Grid>
        </>
    );
}