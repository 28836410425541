import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import DetallesDelCarro from './dialogcomponents/DetallesDelCarro';
import Fotos  from './dialogcomponents/Fotos';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';

class Review extends Component {

  state = { 
    activeStep:0,
    completed:[false,false,false,false],
    steps:['Detalles básicos del carro', 'Fotos', 'Detalles adicionales'],
    listing:{brand:null,model:null,year:null,mainPicture:null,listingPictures:null}
  }

  handleClose = ()=>{
    this.props.handleClose()
    console.log("achieved")
  }

  handleStep =(e)=>{
    this.setState({activeStep:e})
  };

  handleBack = () => {
    this.setState({activeStep:this.state.activeStep-1})
  };

  handleNext = () => {
    this.setState({activeStep:this.state.activeStep+1})
  };

  handleComplete = (index) => {
    let completed = [...this.state.completed]
    completed[index]=true
    this.setState({completed:completed})
  };

  handleClickAway=()=>{
    this.handleClose()
  }
  selectAction =(content,action)=>{
    let listing = {...this.state.listing}
    switch(action){
      case"brand":
        listing.brand=content
        break
      case"year":
        listing.year=content
        break
      case"model":
        listing.model=content
        break
    }
    this.setState({listing:listing})
  }

  render() { 
    const openString = this.props.open?"modal is-active":"modal";
    const that=this;
    const Content = ()=>{
      switch(that.state.activeStep){
        case 0:
            return(
              <DetallesDelCarro handleClose={that.handleClose} 
              vehicles={this.props.vehicles} 
              selectAction={this.selectAction}
              listing={this.state.listing}/>
          );
        case 1:
          return(
            <Fotos handleClose={that.handleClose}
            listing={this.state.listing}/>
        );
        case 2:
          return(
            <DetallesDelCarro handleClose={that.handleClose}/>
        );
        case 3:
          return(
            <DetallesDelCarro handleClose={that.handleClose}/>);
          }}
    return ( 
      <>
      
      <div className={openString}>
        <div className="modal-background"></div>
        <Grid container justify="center">
            <Grid item xs="auto">
            <div>
              <Stepper alternativeLabel nonLinear activeStep={this.state.activeStep} style={{backgroundColor:"inherit",color:"white !important"}}>
                {this.state.steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    const handleStep = ()=>{
                      that.handleStep(index)
                    }
                    return (
                      <Step index={index} key={label} {...stepProps} style={{color:"white !important"}} onClick={handleStep}>
                        <StepButton
                          completed={this.state.completed[this.state.activeStep]}
                          step={index}
                          {...buttonProps}
                        >
                          <div style={{color:"white"}}>{label}</div>
                        </StepButton>
                      </Step>
                    );
  
                })}
              </Stepper>
              <div>
                    </div>
                  </div>
                )}
            </Grid>
          </Grid>
        <div className="modal-card">
          
          <Content onClickAway={this.handleClickAway}/>
          <footer className="modal-card-foot">
                  <Grid container>
                        <Grid item xs={6}>
                          <Grid container justify="flex-start">
                            <Grid item xs="auto">
                            <button onClick={this.handleClose} className="button">Cancelar</button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container justify="flex-end">
                            <Grid item xs="auto">
                            <Button variant="contained" color="secondary">
                              Continuar
                            </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                    </Grid>
                  </footer>
                </div>
              </div>
              </>
     );
  }
}
 
export default Review;