import React from 'react';
import MySelect from '../../MySelect';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import {Link} from '@reach/router';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import DateDownShift from '../../userdashboardcomponents/DateDownShift';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DateRangePicker} from 'react-dates'
import moment from 'moment';
const dateFormat = require('dateformat');

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Bloque=(props)=>{
  return(<>
<Grid item xs={12} style={{marginTop:"-30px"}}>
            <p className="mainVerifyText">
            Lugar de encuentro:
            </p>
          </Grid>
          <Grid container style={{marginTop:"-7px"}}>
          <Grid item xs={12} style={{marginBottom:"16px"}}>
          <p className="secondaryVerifyText">
            <span style={{position:"relative",left:"0px"}}>
              {props.userPack.book.myBooking.deliveryAddress.line1+", "+props.userPack.book.myBooking.deliveryAddress.city+", "}
              </span>
              </p>
          </Grid>
          <Grid item xs={12} style={{paddingBottom:"25px"}}>
          <p className="secondaryVerifyText" >{props.userPack.book.myBooking.deliveryAddress.state.name+", "+props.userPack.book.myBooking.deliveryAddress.state.country.abr}</p>
          </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs="auto">
               <p className="mainVerifyText">
                  Duración:
                </p>
                </Grid>
                <Grid item xs="auto" style={{marginTop:"0px"}}>
                <p className="secondaryVerifyText">{props.duracion>1?props.duracion+" días":props.duracion+" día"}</p>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs="auto">
               <p className="mainVerifyText">
                  Fechas:
                </p>
                </Grid>
                <Grid item xs="auto" style={{marginTop:"0px"}}>
                <p className="secondaryVerifyText">{dateFormat(props.userPack.book.myBooking.startDate, "dd/mmm")+" hasta "+dateFormat(props.userPack.book.myBooking.endDate, "dd/mmm")}</p>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs="auto">
               <p className="mainVerifyText">
                  Costo:
                </p>
                </Grid>
                <Grid item xs="auto">
                <p className="secondaryVerifyText">{props.selectedSymbol}{formatNumber(Math.ceil(props.listing.dailyPrice*props.rate))}</p>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs="auto">
               <p className="mainVerifyText">
                  Total: 
                </p>
                </Grid>
                <Grid item xs="auto">
                <p className="secondaryVerifyText" style={{color:"green !important"}}>{props.selectedSymbol}{formatNumber(props.total)}</p>
                </Grid>
              </Grid>
          </Grid>
  </>)
}

const Datos = (props)=>{
  let nameRaw=""
  if(props.listing){
    nameRaw = props.listing.vehicle.brand+" "+props.listing.vehicle.model
  } else{
    nameRaw = props.userPack.book.listingForRent.vehicle.brand +" "+props.userPack.book.listingForRent.vehicle.model
  }
  let listingName
  if (nameRaw.length<18){
    listingName=nameRaw
  }else{
    listingName=nameRaw.substring(0,17)+"..."
  }
  const handleClose=()=>{
    props.handleClose();
  }

  const handleLocation=(location)=>{
    let newBooking=props.myBooking
    newBooking.location = location
    props.update(newBooking);
  }
  const [focus,setFocus] = React.useState(null)
  const handleDatesChange=(startDate, endDate)=>{
    window.localStorage.setItem('startDate',startDate)
    window.localStorage.setItem('endDate',endDate)
    let newBooking = props.myBooking
    newBooking.startDate=moment(startDate)
    newBooking.endDate=moment(endDate)
    console.log("changing agent?")
    props.userPack.book.updateMyBooking(newBooking)
  }
  const listing = props.listing
  const duracion = props.userPack.book.myBooking.endDate.diff(props.userPack.book.myBooking.startDate, 'days')+1
  const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
  const rate = props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]
  
  const total = Math.ceil(listing.dailyPrice*rate)*duracion
  return(
    <>
    <Grid container spacing={props.userPack.width>600?6:3}>
      <Grid item xs={12} sm={5}>
        <Grid container spacing={2}>
        <Grid item xs={6} sm={12}>
        <div style={{backgroundImage:'url('+listing.mainPicture.url+')',
            backgroundSize:"100%",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            //borderRadius:"10px",
            height: "auto",
            maxWidth: "auto"}}>
                            <Box style={{height:props.userPack.width>600?"140px":"115px",width:props.userPack.width>600?"180px":"140px"}}>
              </Box>
          </div>
            {/* <img src={listing.mainPicture.url} style={{width:"140px",height:"auto",backgroundSize:"100% 100%"}}/>
            */}
            </Grid>
            <Grid item xs={6} sm={12}>
          <Grid item xs={12} style={{marginTop:"10px"}}>
            
            <Typography variant="body" style={{fontWeight:"bold", color:"white"}}>
              {listingName+" "+props.userPack.book.listingForRent.vehicle.year}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs="auto">
              <Box component="fieldset" borderColor="white" style={{marginLeft:"-1px"}}>
                <Rating value={4} size="small" readOnly/>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop:"5px"}}>
              <Grid container>
              <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs="auto">
                        <Avatar alt="Remy Sharp" src={listing.owner.profilePicture?listing.owner.profilePicture.url:"/missingprofile.png"} style={{width:"28px",height:"28px"}}/>
                        </Grid>
                        <Grid item xs="auto" style={{maxWidth:props.width<960?"600px":"250px",marginLeft:"8px",marginTop:"6px"}}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Link to={'/user/'+listing.owner.id}>
                                <Typography variant="body" style={{fontWeight:"bold", color:"white" }}>
                                {listing.owner.first_name+" "+listing.owner.last_name}
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid>

                        </Grid>

                        </Grid>
                      </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12} style={{marginTop:"0px",color:"white"}}>
            <Grid container justify="center">
              <Grid item xs="auto">
                <Link to="/listings" onClick={handleClose} style={{color:"white"}}>Buscar otro vehículo</Link>
              </Grid>
            </Grid>
             
            </Grid> 

        </Grid>
      </Grid>
      <Grid item xs={12} sm={7} id="whiteDates">
        <Grid container>
          <Grid item xs={12}>
            

          </Grid>
          {props.userPack.width>600||true?
            <Bloque userPack={props.userPack} duracion={duracion} selectedSymbol={selectedSymbol} listing={listing} rate={rate} total={total}></Bloque>:null}

              {/* REMOVAL */}



      </Grid>
    </Grid>
    </Grid>
    </>
  )
}
 export default function(props){
    
      const handleChange = name => event => {

        props.userPack.updateMyUser([name],event.target.value)
      };

    const margin=props.userPack.width<700?"0px":"40px"
    return(
      <>
        <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
          <Grid container style={{marginTop:"5px"}}>
              <Grid item xs={12}>
                <Datos update={props.userPack.book.updateMyBooking} myBooking={props.userPack.book.myBooking}
                width={props.userPack.width} currency={props.userPack.currency}  userPack={props.userPack}
                listing={props.listing} handleClose={props.userPack.dialog.handleClose}/>
              </Grid>
          </Grid>
        </div>
      </>
    );
}

