import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const banks = [{name:"Banco Popular Dominicano",abr:"BPD",url:"img/popular.jpg"},
{name:"Banco BHD León",abr:"BHD",url:"img/banreservas.png"},
{name:"Banreservas",abr:"Banreservas",url:"img/bhd.png"}
]

const MyBank = (props)=>{
  const url = banks.filter(c=>c.abr===props.banco.bankAbr)[0].url
  let selectedTemp = false
  if(props.selected && props.selected.id===props.banco.id){
    selectedTemp=true
  }
  
  const handleClick=()=>{
      if (props.selected){
        props.handleSelect(null)
        props.handleSelect(props.banco)
        return
      }
      props.handleSelect(props.banco)
  }

  return(<>
  
      <Grid item xs="auto" onClick={handleClick} id={props.banco.id} style={{width:"150px",maxHeight:"190px",marginRight:"0px"}}>
          <Grid container >
              <Grid item xs="auto">
                      <img src={"../"+url} style={{width:"75px",height:"75px"}}>
                      </img>
              </Grid>
              <Grid container justify="flex-end">
                  
                  <Grid item xs={1}>
                      <Grid container style={{position:"relative",right:"4px",bottom:"30px"}}>
                      <Checkbox style={{position:"relative",bottom:"50px",right:"40px"}}
                          checked={selectedTemp}
                          value="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      </Grid>


                      
                  </Grid> 
              </Grid>

              <Grid container style={{position:"relative",bottom:"50px"}}>
                  <Grid item xs={12} style={{fontWeight:"700",color:"white",marginTop:"7px"}}>
                      {props.banco.ownerName}
                  </Grid>
                  <Grid item xs={12} style={{color:"green"}}>
                      {props.banco.accountNumber}
                  </Grid>
              </Grid>
          </Grid>


      </Grid>
  </>)
}

 export default function(props){

    const [selected,setSelected]= React.useState(props.userPack.book.listingForRent.bankAccounts.length===1?props.userPack.book.listingForRent.bankAccounts[0]:null)

    const handleSelect=(bank)=>{
      if(bank){
        setSelected(bank)
        console.log(bank," banck id???")
        props.userPack.book.handlePayment('CASH',bank.id)
      }else{
        setSelected(bank)
      }

    }

    const margin=props.userPack.width<700?"0px":"70px"
    return(
      <>
      <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
      <Grid container style={{marginTop:"5px"}}>
      <Grid item xs={12}>
            <Grid container>
              <Grid item xs="auto" >
                <Typography variant="h6" style={{fontWeight:"bold", color:"white",marginBottom:"40px"}}>
                  Seleccione una cuenta de banco para efectuar su pago mediante depósito
                </Typography>
                </Grid>
                <Grid item xs="auto">
                </Grid>
              </Grid>
          </Grid>
          <Grid container style={{overflowY:"auto"}}>
            {props.userPack.book.listingForRent.bankAccounts.map((banco,index)=>(
                <MyBank banco={banco} banks={banks} selected={selected} allAccounts={props.userPack.myListing.owner.bankAccounts}
                  key={index} updateMyUser={props.userPack.updateMyUser} updateMyListing={props.userPack.updateMyListing}
                  handleSelect={handleSelect}
                  />
            ))}
            </Grid>
      </Grid>


      </div>
      </>
    );
}

