import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    topMargin:{
        marginTop:"25px"
    },
    unDoneListing:{
        backgroundColor: "hsla(0, 0%, 15%, 0.10)",
        '&:hover':{
            backgroundColor: "hsla(61, 88%, 91%, 0.57)"
        }
    }
}))

const UnDoneListing = (props) =>{
    console.log(props.listing)
    const classes = useStyles()
    const title = (props.brand && props.model && props.year)?props.brand +" "+ props.model +" "+ props.year:"Undefined"

    return(
        <>
        <Grid item xs={4} md={2} sm={3} key={props.listing.id} >
            <Tooltip title="Click para continuar" aria-label="Editar" placement="bottom-start">
            <div>
            <a href="#" onClick={props.addListing} id={props.listing.id}>
                <img src="img/missingpicture.png" className={classes.unDoneListing}/>
            </a>
            </div>
            </Tooltip>
        </Grid>
        </>
    );
}

export default function Consejos(props){
    const classes = useStyles(props);
    //Do handling for deleting listing
    const margin = props.smallScreen?"10px":"0px"
    return(
      <>
      <Grid container alignItems="center" justify="center" style={{marginBottom:"20px"}}>
            <Grid item xs={false} md={2}>
            </Grid>
            <Grid item xs={12} sm={12}>
                    <Grid container spacing={0} alignItems="flex-end" style={{marginBottom:"20px"}}>
                        <Grid item xs={false} sm={1} md={2}>
                        </Grid>
                        <Grid item xs={12} sm={10} md={8} style={{marginLeft:margin,marginRight:margin}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.topMargin}>
                                        Recomendaciones, tips y consejos para aumentar tus ganacias!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom className={classes.topMargin}>
                                        Esta seccion esta destinada para promover nuevas tecnologías y mejoras ideadas para crecer tu negocio
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom className={classes.topMargin}>
                                        Coming soon...
                                    </Typography>
                                </Grid>
      
                            
                        </Grid>

                        </Grid>
                        <Grid item xs={false} sm={1} md={2}>
                        </Grid>
                        <Grid item xs={false} md={3} sm={2}>
                            </Grid>
                    </Grid>

            </Grid>
            <Grid item xs={false} md={2}>
            </Grid>
        </Grid>
      </>
    );
}