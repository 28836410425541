import React from 'react';
import MySelect from '../../MySelect';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix={selectedSymbol}
      prefix={props.name}
    />
  );
}

 export default function(props){
   const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
   

    const handleChange = name => event => {
        props.userPack.updateMyListing([name],event.target.value)
      };
  const rate = props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]
  const dailyValue = parseFloat(props.userPack.myListing.dailyPrice).toFixed(2)
  const weeklyValue = parseFloat(props.userPack.myListing.weeklyPrice).toFixed(2)
  const handleWeeklyPrice=(e)=>{
    
    if(weeklyValue!=e.target.value){
      props.userPack.updateMyListing(["weeklyPrice"],e.target.value)
    }
    
  }
  const handleDailyPrice=(e)=>{
    
    if(dailyValue!=e.target.value){
    props.userPack.updateMyListing(["dailyPrice"],e.target.value)
    }
  }

  

    let brand = "Marca"
    let model = "Modelo"
    let year = "2019"
    let carType = "SEDAN"
    if(props.userPack.myListing.vehicle!==null){
      model=props.userPack.myListing.vehicle.model
      brand=props.userPack.myListing.vehicle.brand
      year=props.userPack.myListing.vehicle.year
      carType=props.userPack.myListing.vehicle.carType
    }
    return(
      <>
      <div className="modalBox" style={{paddingLeft:"10px",paddingRight:"10px",backgroundColor:"white"}}>
      <Grid container justify="center" style={{zIndex:"10"}} spacing={2}>
          <Grid item xs={12} md={6}>
          <MySelect name="brand" fetchModels={props.userPack.fetchModels} updateMyListing={props.userPack.updateMyListing} value={{label:brand,value:brand}}/>
          </Grid>
          <Grid item xs={12} md={6}>
          <MySelect name="model" updateMyListing={props.userPack.updateMyListing} value={{label:model,value:model}} options={props.userPack.steps.options.models}/>
          </Grid>
      </Grid> 
      <Grid container spacing={2} style={{marginTop:"17px"}}>
          <Grid item xs={4}>
          <TextField
              id="outlined-select-currency-native"
              select
              label="Año"
              value={year}
              onChange={handleChange('year')}
              SelectProps={{
              native: true,
              MenuProps: {
                  className: "",
              },
              }}
              margin="normal"
              variant="outlined"
              style={{width:"100%"}}
          >
              {["2020","2019","2018","2017","2016","2015","2014","2013","2012","2011","2010","2009","2008"].map(option => (
              <option key={option} value={option}>
                  {option}
              </option>
              ))}
          </TextField>
          
          </Grid>
          <Grid item xs={8}>
          <TextField
              id="outlined-select-currency-native"
              select
              label="Tipo de Vehículo"
              value={carType}
              onChange={handleChange('carType')}
              SelectProps={{
              native: true,
              MenuProps: {
                  className: "",
              },
              }}
              margin="normal"
              variant="outlined"
              style={{width:"100%"}}
          >
              {["Sedan","SUV","Coupe","Sport","Luxury","Jeep"].map(option => (
              <option key={option} value={option}>
                  {option}
              </option>
              ))}
          </TextField>
          </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:"5px"}}>
          <Grid item xs={4}>
          <TextField
          style={{width:"100%"}}
                        id="outlined-select-currency-native"
                        select
                        label="Moneda"
                        value={props.userPack.elements.currency.selected}
                        onChange={handleChange('currency')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: "",
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                        style={{width:"100%"}}
                      >
                        {props.userPack.elements.currency.symbols.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
          </Grid>
          <Grid item xs={4}>
          <TextField
          style={{width:"100%"}}
              label="Precio Diario"
              name={selectedSymbol}
              InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              currency={props.userPack.currency}
              value={dailyValue}
              margin="normal"
              variant="outlined"
              onChange={handleDailyPrice}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: "",
                },
              }}
              id="formatted-numberformat-input"
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
          style={{width:"100%"}}
              label="Precio Semanal"
              name={selectedSymbol}
              InputProps={{
                inputComponent: NumberFormatCustom,
                }}
              onChange={handleWeeklyPrice}
              mask={props.userPack.currency}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: "",
                },
              }}
              value={weeklyValue}
              margin="normal"
              variant="outlined"
              id="formatted-numberformat-input-weekly"
          />
          </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:"5px"}}>
          <Grid item xs={4}>
              <TextField
              style={{width:"100%"}}
                  id="standard-number"
                  label="Rendimiento *"
                  type="number"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  value={props.userPack.myListing.performance}
                  onChange={handleChange('performance')}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: "",
                    },
                  }}
                  margin="normal"
                  variant="outlined"
              />
               </Grid>
          

         
          <Grid item xs={4}>
              <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Unidades"
                  value={props.userPack.myListing.units}
                  onChange={handleChange('units')}
                  SelectProps={{
                  native: true,
                  MenuProps: {
                      className: "",
                  },
                  }}
                  margin="normal"
                  variant="outlined"
                  style={{width:"100%"}}
              >
                  {props.userPack.units.all.map(option => (
                  <option key={option.value} value={option.value}>
                      {option.label}
                  </option>
                  ))}
              </TextField>
          </Grid>
          <Grid item xs={4}>
          <TextField
          style={{width:"100%"}}
              required
              id="standard-require"
              label="Número de Placa"
              margin="normal"
              value={props.userPack.myListing.matricula}
              onChange={handleChange('matricula')}

              variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
              />
          </Grid>
      </Grid>
      {props.userPack.width>350?
          <p style={{position:"relative",bottom:"6px",fontSize:"12px"}} className="helpText">Rendimiento = Resultado de dividir distancia recorrida en kilometros/combustible consumido en galones.</p>
    
        :null}

      </div>
      </>
    );
}

