import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import NewListing from './NewListing';
import Grid from '@material-ui/core/Grid';
import Tarjetita from './Tarjetita'
import CloneDialog from './CloneDialog'


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const NewListingApp = (props) =>{
  let [ step, setStep ] = React.useState(0);
  const listing=props.userPack.myListing

  const handleNew = () =>{
    setStep(1);
  }
  const handleClone = () =>{
    //props.clone(props.parameter)
    setStep(6);
  }
  switch(step){
    case 0:
        return(
          <>
          <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Creación de Publicación"}</DialogTitle>
            <DialogContent>
              <Grid container justify="center">
                <Grid item xs={"auto"}>
                  <Button onClick={handleClone} color="secondary" value={"clonar"}>
                    Clonar
                  </Button>
                  <Button onClick={handleNew} color="secondary" value={"nueva"}>
                    Nueva
                  </Button>

                </Grid>
              </Grid>

            </DialogContent>
          </Dialog>
          </>
        );
        case 1:
          return(<NewListing userPack={props.userPack} open={props.open} handleClose={props.handleClose} currency={props.currency} vehicles={props.vehicles}/>);
        case 7:
          return(<CloneDialog open={props.open} handleClose={props.handleClose} listing={listing}/>);
        case 6:
          return(
            <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"Seleccione la publicacion a clonar"}</DialogTitle>
            <DialogContent>
            <Grid container justify="center">
                <Grid item xs={"auto"}>
                  {props.user.listings.filter(c=>c.mainPicture!=null).map((listing) => (
                    <Grid item xs={"auto"} md={"auto"} sm={"auto"} key={listing.id}>
                      <Tarjetita
                      key={listing.id}
                      listing={listing}
                      openCloneDialog={props.handleClone}
                      handleClose={props.handleClose}/>
                    </Grid>
                    ))}
                  
                </Grid>
            </Grid>
            </DialogContent>
        </Dialog>
          );

  }

}

const SelectedConfirmation = (props) =>{

    let [open,setOpen] = React.useState(true);

    const handleClose = () =>{
        setOpen(false)
        props.close()
    }
    const handleYes = () =>{
        setOpen(false)
        props.yes(props.selected,props.parameter)
    }
    const userPack = props.userPack
    const listing=userPack.myListing
    switch(props.selected){
        case "discard":
            return(
                <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Estás seguro que desea abandonar la edición?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Estás a punto de eliminar esta publicacion incluyendo, fotos, historia, clientes y todo lo relacionado a la misma
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    No
                </Button>
                <Button onClick={handleYes} color="secondary" autoFocus>
                    Si
                </Button>
                </DialogActions>
            </Dialog>
            );
                case "new":
                    return(
                      <NewListingApp open={open}
                      handleClose={handleClose}
                      user={props.user}
                      handleClone={props.clone}
                      vehicles={props.vehicles}
                      userPack={userPack}/>
                    );
                    case "clone":
                      return(
                        <CloneDialog open={open} userPack={userPack} handleClose={handleClose} listing={listing}/>
                      );
                    case "delete":
                        return(
                            <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Estás seguro que quiere eliminar esta publicacion?"}</DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Estás a punto de eliminar esta publicacion incluyendo: fotos, historia, clientes y todo lo relacionado a la misma
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleClose} color="secondary">
                                        No
                                    </Button>
                                    <Button onClick={handleYes} color="secondary" autoFocus>
                                        Si
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                            );
    }
}

export default function ConfirmationDialog(props) {

  return (
    <div>
      <SelectedConfirmation 
      user={props.user} 
      selected={props.selected} 
      close={props.close}
      yes={props.yes} 
      parameter={props.parameter} 
      listing={props.listing}
      clone={props.clone}
      currency={props.currency}
      vehicles={props.vehicles}
      userPack={props.userPack}
       />
    </div>
  );
}