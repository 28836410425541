import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const handleStartDate=(date)=>{
    window.localStorage.setItem('startDate',date)
    let newBooking = props.book.myBooking
    newBooking.startDate=date//.format('YYYY-MM-DD')
    props.book.updateMyBooking(newBooking)
    props.getResults()
    
  }
  const handleEndDate=(date)=>{
    window.localStorage.setItem('endDate',date)
    let newBooking = props.book.myBooking
    newBooking.endDate=date//.format('YYYY-MM-DD')
    props.book.updateMyBooking(newBooking)
    props.getResults()
  }
  function handleDateChange(date) {
    if(props.current==="start"){
      handleStartDate(date)
    }else{
      handleEndDate(date)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id={props.label}
          label={props.label}
          value={props.value}
          onChange={date=>handleDateChange(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}