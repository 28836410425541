import React, { forwardRef } from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';

const STATES = require('../data/states');

var StatesField = createClass({
	displayName: 'StatesField',
	propTypes: {
		label: PropTypes.string,
		searchable: PropTypes.bool,
	},
	getDefaultProps () {
		return {
			label: 'States:',
			searchable: true,
		};
	},
	getInitialState () {
		return {
			country: 'AU',
			disabled: false,
			searchable: this.props.searchable,
			selectValue: 'new-south-wales',
			clearable: true,
			rtl: false,
		};
	},
	clearValue (e) {
		this.select.setInputValue('');
	},
	switchCountry (e) {
		var newCountry = e.target.value;
		this.setState({
			country: newCountry,
			selectValue: null,
		});
	},
	updateValue (newValue) {
        window.localStorage.setItem('location',newValue.label)
		this.props.setLocation(newValue.label)
	},
	focusStateSelect () {
		this.select.focus();
	},
	toggleCheckbox (e) {
		let newState = {};
		newState[e.target.name] = e.target.checked;
		this.setState(newState);
	},
	render () {
		var options = []
		this.props.elements.states.forEach(state => {
			options.push({label:state.name+", "+state.country.name,value:state.name})
		});
		return (
			<>
			<div className="selectionBox" style={{width:"100%",paddingTop:"10px"}}>
				<Select
					id="state-select"
					placeholder="Seleccione..."
					ref={(ref) => { this.select = ref; }}
					onBlurResetsInput={false}
					onSelectResetsInput={false}
					options={options}
					simpleValue
					clearable={this.state.clearable}
					name="selected-state"
					disabled={this.state.disabled}
					value={this.props.value}
					onChange={this.updateValue}
					rtl={this.state.rtl}
					searchable={this.state.searchable}
					
				/>
			</div>
			</>
		);
	}
});


export default StatesField;