import React, { Component } from 'react';
import NavBar from './maincomponents/NavBar';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Signature from './maincomponents/Signature';
import { Link } from "@reach/router";
import MySelect from './maincomponents/LocationSelect';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {SingleDatePicker,DateRangePicker} from 'react-dates';
import ScrollContainer from 'react-indiana-drag-scroll'
import Chat from './maincomponents/Chat';

import moment from 'moment';
////////////////////

const getUrl = window.location;

const JumbotronDesktop = (props) => {
  const [focus,setFocus] = React.useState(null)
  const handleDatesChange=(startDate, endDate)=>{
    window.localStorage.setItem('startDate',startDate)
    window.localStorage.setItem('endDate',endDate)
    let newBooking = props.userPack.book.myBooking
    newBooking.startDate=moment(startDate)
    newBooking.endDate=moment(endDate)
    props.userPack.book.updateMyBooking(newBooking)
  }
  const mainScrollDown = (e)=>{
    e.preventDefault()
    props.userPack.mainScrollDown()
  }
  return(
    <>
     <div id="background" style={{backgroundImage:"url('../media/carenportada.webp')",backgroundRepeat:"no-repeat",backgroundSize:"cover", backgroundPosition:"center", width:"100%",minHeight:"600px",height:props.height}}>
      <Grid container justify="center" style={{position:"absolute",bottom:"2px"}}>
        <Grid item xs="auto">
          <a className="arrowDownward" onClick={mainScrollDown} href="/">
          <ArrowDownwardIcon/>
          </a>
        </Grid>
      </Grid>
     <AppBar elevation={0} position="static"  style={{ background: 'transparent', boxShadow: 'none',color:"white"}}>
          <NavBar userPack={props.userPack} width={props.width} white={true}/>
        </AppBar>
        <div style={{top:"50%",postion:"relative"}}>
        <Grid container>
          <Grid item xs="auto">
        <div className="searchBox-Desktop mainBox" style={{maxWidth:props.width}}>
        <Paper elevation={7} style={{padding:"23px"}}>
            <Typography variant="h4" style={{fontWeight:"bold"}}>
              Revoluciona tu forma de alquilar un carro
            </Typography>
          <div className="divider"></div>
          <p className="semiTitle">Dónde</p>
          <MySelect elements={props.userPack.elements} value={{key:props.userPack.book.location,label:props.userPack.book.location}} setLocation={props.userPack.book.setLocation}/>
          <Grid container style={{marginTop:"11px"}}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                <p className="semiTitle">Desde</p>
                </Grid>
                <Grid item xs={6}>
                <p className="semiTitle">Hasta</p>
                  </Grid>
              </Grid>
            </Grid>
            
          <div className="divider"></div>
          <div className="App" style={{width:"100%"}}>
            <DateRangePicker 
              startDateId="Desde"
              endDateId="Hasta"
              startDatePlaceholderText= "Desde"
              endDatePlaceholderText="Hasta"
              startDate={props.userPack.book.myBooking.startDate}
              endDate={props.userPack.book.myBooking.endDate}
              onDatesChange={({ startDate, endDate }) => { handleDatesChange(startDate, endDate)}}
              focusedInput={focus}
              onFocusChange={(focusedInput) => { setFocus( focusedInput )}}
            />
          </div>
          </Grid>
          <div className="divider"></div>
          <Grid item xs={12}>
            <Link to="/listings">
            <Button variant="contained" color="secondary" style={{width:"100%"}}>
              Mostrar Resultados!
            </Button>
            </Link>
          </Grid>
          </Paper>
      </div>
      </Grid>
      </Grid>
      
      </div>
        </div>
    </>
  );
};
const JumbotronMobile = (props) => {
  const [focusStart,setFocusStart] = React.useState(false)
  const [focusEnd,setFocusEnd] = React.useState(false)

  const handleStartDate=(date)=>{
    window.localStorage.setItem('startDate',date)
    let newBooking = props.userPack.book.myBooking
    newBooking.startDate=date//.format('YYYY-MM-DD')
    props.userPack.book.updateMyBooking(newBooking)
  }
  const handleEndDate=(date)=>{
    window.localStorage.setItem('endDate',date)
    let newBooking = props.userPack.book.myBooking
    newBooking.endDate=date//.format('YYYY-MM-DD')
    props.userPack.book.updateMyBooking(newBooking)
  }
  const mainScrollDown = (e)=>{
    e.preventDefault()
    props.userPack.mainScrollDown()
  }
  return(
    <>
        <div id="background" style={{backgroundImage:"url('../media/portadamobile.jpg')",backgroundRepeat:"no-repeat",backgroundSize:"cover", backgroundPosition:"center", width:"100%",height:props.height}}>
        <Grid container justify="center" style={{position:"absolute",bottom:"2px",fontSize:"30px"}}>
        <Grid item xs="auto">
          <a className="arrowDownward" onClick={mainScrollDown} href="/">
          <ArrowDownwardIcon/>
          </a>
        </Grid>
      </Grid>
        <AppBar elevation={0} position="static"  style={{ background: 'transparent', boxShadow: 'none',color:"white"}}>
          <NavBar userPack={props.userPack} width={props.width} white={true}/>
        </AppBar>
        <Grid container justify="center" className="mainBox">
          <Grid item xs="auto" style={{width:"370px",marginTop:"28px",marginBottom:"28px"}}>
          <Typography variant="h4" style={{fontWeight:"bold",color:"white"}}>
              Revoluciona tu forma de alquilar un carro
            </Typography>
          </Grid>
          <Grid item xs="auto" className="searchBox-Mobile">
            <Grid item xs="auto">

          <div className="divider"></div>
            <Paper elevation={4} style={{padding:"23px"}}>
            <p className="semiTitle">Dónde</p>

            <MySelect elements={props.userPack.elements} value={{key:props.userPack.book.location,label:props.userPack.book.location}} setLocation={props.userPack.book.setLocation}/>
          <Grid container id="singleDates" style={{marginTop:"10px"}}>
            <Grid item xs={6}>
            <p className="semiTitle">Desde</p>
            <div className="divider"></div>
               <SingleDatePicker
                numberOfMonths={1}
                placeholder="Fecha"
                 date={props.userPack.book.myBooking.startDate} // momentPropTypes.momentObj or null
                 onDateChange={date => handleStartDate(date)} // PropTypes.func.isRequired
                 focused={focusStart} // PropTypes.bool
                 onFocusChange={({ focused }) => setFocusStart(focused)} // PropTypes.func.isRequired
                 id="your_unique_id" // PropTypes.string.isRequired
                 />
            </Grid>
            
            <Grid item xs={6}>
            <p className="semiTitle">Hasta</p>
            <div className="divider"></div>
              <SingleDatePicker
                numberOfMonths={1}
                placeholder="Fecha"
                 date={props.userPack.book.myBooking.endDate} // momentPropTypes.momentObj or null
                 onDateChange={date => handleEndDate(date)} // PropTypes.func.isRequired
                 focused={focusEnd} // PropTypes.bool
                 onFocusChange={({ focused }) => setFocusEnd(focused)} // PropTypes.func.isRequired
                 id="your_unique_id" // PropTypes.string.isRequired
                 /> 
            </Grid>
          </Grid>
          <div className="divider"></div>
          <Grid item xs={12}>
            <Link to="/listings">
            <Button variant="contained" color="secondary" style={{width:"100%"}}>
              Mostrar Resultados!
            </Button>
            </Link>
          </Grid>
          </Paper>
            </Grid>
          </Grid>
        
      </Grid>
      
        </div>
    </>
  );
};

const Body =()=>{
  return(
    <>
<div className="rentalCategoryCarousel rentalCategoryCarousel--homePage">
    <h2 className="rentalCategoryCarousel-title">Browse by category</h2>
    <div className="rentalCategoryCarousel-carousel js-seoCarousel">
        {/* <button className="js-carouselLeftArrow rentalCategoryCarousel-carouselLeftArrow">
        </button> */}
        <div className="js-carouselMask rentalCategoryCarousel-carouselMask">
            <div className="js-carouselSlides rentalCategoryCarousel-carouselSlides"style={{transition: "transform 250ms ease 0s", transform: "translateX(-228px)"}}>

                <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/minivan-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Minivan rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_minivan.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Minivans</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/sports-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Sports car rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_sport.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Sport</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/suv-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="SUV rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_suv.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">SUVs</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/truck-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Truck rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_truck.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Trucks</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/van-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Van rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_van.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Vans</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/car-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Car rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_car.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Cars</div>
                    </a><a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/classNameic-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="ClassNameic car rental alternatives - TURO" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_classNameic.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">ClassNameics</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/convertible-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Convertible rental alternatives - TURO" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_convertible.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Convertibles</div>
                    </a>
                    <a className="rentalCategoryCarousel-carouselItem rentalCategoryCarousel-slide staticFlexCarousel-slide" href="/us/en-us/exotic-luxury-rental">
                        <div className="rentalCategoryCarousel-carouselItemImageContainer">
                            <img alt="Exotic luxury car rental alternatives" className="rentalCategoryCarousel-carouselItemImage js-lazyLoad" src="https://resources.turo.com/resources/img/seo/rental_type/category-carousel_luxury.jpg"/>
                        </div>
                        <div className="rentalCategoryCarousel-carouselItemText">Exotic luxury</div>
                    </a>
                    </div>
        </div>
        {/* <button className="js-carouselRightArrow rentalCategoryCarousel-carouselRightArrow"></button> */}
    </div>
    </div>
    </>
  )
}

const Body2=(props)=>{
  const handleCategory=(e)=>{
    //console.log(e.target.id)
    props.userPack.filterMethods.setCarCategory(e.target.id)
  }
  const carCategory = props.userPack.filters.carCategory
  return(
    <>
    <Grid item xs={12}>
      <span className="adSecondaryBold" style={{position:"relative",top:"18px",left:"18px"}}>
        Búsqueda por categoría
      </span>
    </Grid>
    <div className="contain" style={{position:"relative",left:"15px",marginRight:"30px",maxWidth:props.width-47+"px"}}>
    <ScrollContainer className="scroll-container">
<div className="row">
  <div className="row__inner">
    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===0?" selectedCategory":"")} src="carros.jpg" alt=""  />
      </div>
      <div className="tile__details tile__first__detail" id={0}>
        <div className="tile__title">
          Carros
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===1?" selectedCategory":"")} src="suv.jpg" alt=""  />
      </div>
      <div className="tile__details" id={1}>
        <div className="tile__title">
          SUVs
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===2?" selectedCategory":"")} src="sports.jpg" alt=""  />
      </div>
      <div className="tile__details" id={2}>
        <div className="tile__title">
          Deportivos
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===3?" selectedCategory":"")} src="minivan.jpg" alt=""  />
      </div>
      <div className="tile__details" id={3}>
        <div className="tile__title">
          Minivans
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===4?" selectedCategory":"")} src="trucks.jpg" alt=""  />
      </div>
      <div className="tile__details" id={4}>
        <div className="tile__title">
          Camionetas
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===5?" selectedCategory":"")} src="van.jpg" alt=""  />
      </div>
      <div className="tile__details" id={5}>
        <div className="tile__title">
          Vans
        </div>
      </div>
    </div>

    <div className="tile" onClick={handleCategory}>
      <div className="tile__media">
        <img className={"tile__img"+ (carCategory===6?" selectedCategory":"")} src="luxury.jpg" alt=""  />
      </div>
      <div className="tile__details" id={6}>
        <div className="tile__title">
          Lujo
        </div>
      </div>
    </div>

  </div>
</div>
</ScrollContainer>
{carCategory!=null?
  <Grid container justify="center">
    <Link to="/listings">
  <Grid item xs="auto" style={{position:"relative",bottom:props.userPack.width<601?"30px":"0px"}}>
  
  <Button variant="outlined" color="secondary">
    Mostrar resultados
  </Button>
  
</Grid>
</Link>
</Grid>
:
null
}
</div>

    </>
  )
}

const Publicidad=(props)=>{
  let fontSize ="0px"
  const width = props.userPack.width 
  width<730?fontSize="36px":fontSize="52px"
  const mobile = width<600
  let mobileMargin = "0px"
  let marginTop = "0px"
  let marginBottom = "0px"
  mobile?mobileMargin="15px":mobileMargin="0px"
  mobile?marginTop="50px":marginTop="100px"
  mobile?marginBottom="35px":marginBottom="50px"
  const handleBook = ()=>{
    window.location.href = "/listings";
  }
  return(
    <>
      <div style={{marginBottom:marginBottom,paddingTop:marginTop}}>
        <Grid container justify="center">
          <Grid item xs="auto" >
            <Typography variant="h5">
              {/* Ya no tienes que pasar por caja */  }
            </Typography>
          </Grid>
        </Grid>
        {mobile?
        <>
        <Grid container justify="center">
          <Grid item xs="auto">
         
                      <span className="adHeader" style={{fontSize:fontSize}}>
                      Tenemos la mejor
                    </span>
                    </Grid>
        </Grid>
                <Grid container justify="center">
                <Grid item xs="auto">
               
                            <span className="adHeader" style={{fontSize:fontSize}}>
                            variedad.
                          </span>
                          </Grid>
              </Grid>
              </>
            :
            <>
            <Grid container justify="center">
          <Grid item xs="auto">
            <span className="adHeader" style={{fontSize:fontSize}}>
            Tenemos la mejor variedad.
          </span>
          </Grid>            

        </Grid>
        </>
            }


        <Grid container justify="center" style={{marginBottom:"50px"}}>
          <Grid item xs="auto">
            <span className="adHeader" style={{fontSize:fontSize}}>
              Al mejor precio.
            </span>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs="auto">
            <span className="adSecondary" style={{marginLeft:mobileMargin,marginRight:mobileMargin}}>
            Carly es el primer mercado digital de alquiler de carros en America Latina.
          </span>
          </Grid>
          </Grid>
          <Grid container justify="center" style={{marginTop:"40px"}}>
          <Grid item xs="auto">
            <Link to="/listings">
            <Button variant="outlined" color="secondary" id="variedad">
              Reservar un carro
            </Button>
            </Link>
          </Grid>
          </Grid>
      </div>
    </>
  )
}

const MakeMoney=(props)=>{
  let fontSize ="0px"
  const width = props.userPack.width 
  width<730?fontSize="36px":fontSize="52px"
  const mobile = width<600
  let mobileMargin = "0px"
  let marginTop = "0px"
  let marginBottom = "0px"
  mobile?mobileMargin="15px":mobileMargin="0px"
  mobile?marginTop="0px":marginTop="50px"
  mobile?marginBottom="60px":marginBottom="100px"

  return(
    <>
<div className="" style={{marginBottom:marginBottom,marginTop:marginTop}}>
        <Grid container justify="center">
          <Grid item xs="auto" >
            <Typography variant="h5">
              {/* Ya no tienes que pasar por caja */  }
            </Typography>
          </Grid>
        </Grid>
        {mobile?
        <>
        <Grid container justify="center">
          <Grid item xs="auto">
         
                      <span className="adHeader" style={{fontSize:fontSize}}>
                      Únete hoy
                    </span>
                    </Grid>
        </Grid>
                <Grid container justify="center">
                <Grid item xs="auto">
               
                            <span className="adHeader" style={{fontSize:fontSize}}>
                            y gana dinero.
                          </span>
                          </Grid>
              </Grid>
              <Grid container justify="center">
              <Grid item xs="auto">
                <span className="adHeader" style={{fontSize:fontSize}}>
                Haz que tu carro
                </span>
              </Grid>
        </Grid>
        <Grid container justify="center" style={{marginBottom:"50px"}}>
          <Grid item xs="auto">
            <span className="adHeader" style={{fontSize:fontSize}}>
            trabaje por ti.
            </span>
          </Grid>
        </Grid>
        
              </>
            :
            <>
            <Grid container justify="center">
          <Grid item xs="auto">
            <span className="adHeader" style={{fontSize:fontSize}}>
            Únete hoy y gana dinero.
          </span>
          </Grid>            
          <Grid container justify="center" style={{marginBottom:"50px"}}>
          <Grid item xs="auto">
            <span className="adHeader" style={{fontSize:fontSize}}>
            Haz que tu carro trabaje por ti.
            </span>
          </Grid>
        </Grid>
        </Grid>
        </>
            }



        <Grid container justify="center">
          <Grid item xs="auto">
            <span className="adSecondary" style={{marginLeft:mobileMargin,marginRight:mobileMargin}}>
            Descubre una forma revolucionaria de ganar dinero siendo tu propio jefe.
          </span>
          </Grid>
          </Grid>
          <Grid container justify="center" style={{marginTop:"40px"}}>
          <Grid item xs="auto">
            <Button onClick={props.handleList} variant="outlined" color="secondary">
              Publica tu carro
            </Button>
          </Grid>
          </Grid>
      </div>
    </>
  )
}

class MainView extends Component {
    constructor(props){
      super(props)
      if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
        console.log("positivo dotol")
        props.userPack.dialog.handleOpen("register")
        //let index = getUrl.href.indexOf("?code")+6
        //let code = getUrl.href.substring(index,getUrl.href.length)
        
        //this.props.userPack.loginElements.socialRegister(code)
      }
      const w = window;
      const d = document;
      const documentElement = d.documentElement;
      const body = d.getElementsByTagName('body')[0];
      const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
      const height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

      this.state={dialogData:{},openDialog:false,width,height}
      
      //this.props.userPack.loginElements.handleRegister()
    }
    componentDidUpdate(){
      this.updateDimensions();  
      // if(getUrl.href.includes("?code")){
      //   this.props.userPack.loginElements.handleRegister()
      // }
     
    }
    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
    };
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateDimensions);
    };

    updateDimensions = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName('body')[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        const height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
        if(width==this.state.width){
          return
        }
        this.setState({width: width, height: height});
    };

    newListing = ()=>{
      this.setState({dialogData:{action:"newListing"}})
      this.setState({openDialog:true})
    }
    handleCloseDialog = ()=>{
      this.setState({openDialog:false})
    }
    handleList = ()=>{
      this.props.userPack.dialog.handleOpen("list")
    }
    render() {
      const height = this.props.userPack.height+"px"
      const width = (this.props.userPack.width>600)?(this.props.userPack.width-0.4*this.props.userPack.width)+ "px":(this.props.userPack.width-0.05*this.props.userPack.width)+ "px";
        return (
      <React.Fragment>


      <Grid item xs={12}>

          {this.props.userPack.width<600
          ?
          <JumbotronMobile userPack={this.props.userPack} width={width} height={height}/>
          :
          <JumbotronDesktop userPack={this.props.userPack} width={width} height={height}/>
        }
      </Grid>
      <Publicidad userPack={this.props.userPack}/>
      <Body2 width={this.props.userPack.width} userPack={this.props.userPack}/>
      <MakeMoney handleList={this.handleList} userPack={this.props.userPack}/>
      <Signature userPack={this.props.userPack}/>
      {/*<Grid item xs={12} style={{position:"absolute",bottom:"0"}}fontSize="large" color="inherit">
    <ArrowDownwardIcon/></Grid>*/}
        
      
    </React.Fragment>
  );}}
 
export default MainView;